import { frGeoApi } from "../constant/Axios";

const getPostalCode =
    (value) => frGeoApi.get('communes?codePostal=' + value)
        .then(resp => resp.data);
const getCities = (value) => frGeoApi.get('communes?nom=' + value)
    .then(resp => resp.data);

export const fetchCities = (value) =>
    getCities(value)
        .then(result => {
            if (result && result.length > 0) {
                const cities = result.reduce((list, city) => {
                    if (city.codesPostaux.length === 1) {
                        list.push({ value: city.codesPostaux[0] + '@' + city.nom, text: city.nom })
                    } else {
                        city.codesPostaux.forEach(i => list.push({ value: i + '@' + city.nom, text: `${city.nom} (${i})` }));
                    }
                    return list;
                }, []);
                return cities
            } else {
                return []
            }
        });
export const fetchCityByCP = (value) =>
    getPostalCode(value)
        .then(result => {
            if (result && result.length > 0) {
                return result[0].nom;
            } else {
                return null
            }
        });

