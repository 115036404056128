import { SET_USER, SET_GLOBALSPIN, SET_SERVICE_USER, SET_GLOBALCONFIRM } from './action';

const reducer = (state = {}, action) => {
    switch (action.type) {
        case SET_USER:
            return { ...state, boUser: action.value };
        case SET_SERVICE_USER:
            return { ...state, serviceUser: action.value };
        case SET_GLOBALSPIN:
            return { ...state, globalSpin: action.value };
        case SET_GLOBALCONFIRM:
            return { ...state, globalConfirm: action.value };
        default:
            return state;
    }
};

export default reducer;