import {connect} from 'react-redux';
import {addBasicValue, addSpecialValue, addCommonValue} from '../../../store/actions/formValuesActions';
import {fetchCompany} from '../../../store/actions/companyAction';

const mapStateToProps = (state) => {
    let {translation} = state;
    let {quote, commonData} = state.formValues;
    const specialValues = state.formValues.specialInfo;
    const label = translation.label;
    return {
        label: {
            email: label.email,
            contractType: label.contractType,
            oldContractNumber: label.oldContractNumber,
            oldInsuranceCompany: label.oldInsuranceCompany,
            leaseNature: label.specialLeaseNature,
            companyOwnedHouse: label.companyOwnedHouse,
            insuranceCategory: label.insuranceCategory,
            personBorrowWith: label.personBorrowWith,
            addPerson2InContract: label.addPerson2InContract,
            allCompanyDrivers: label.allCompanyDrivers,
            secondDriverInsurance: label.secondDriverInsurance,
            oldContractDueDate: label.oldContractDueDate,
            cancellationLetter: label.cancellationLetter,
            expectedStartDate: label.expectedStartDate,
            acceptedPaymentMethods: label.acceptedPaymentMethods,
            contractTypeQuestion: label.contractTypeQuestion,
            oldContractNumberQuestion: label.oldContractNumberQuestion,
            oldInsuranceCompanyQuestion: label.oldInsuranceCompanyQuestion,
            expectedStartDateQuestion: label.expectedStartDateQuestion,
            acceptedPaymentMethodsQuestion: label.acceptedPaymentMethodsQuestion,
            insuranceCategoryQuestion: label.insuranceCategoryQuestion,
        },
        options: {
            contractType: translation.options.contractType,
            leaseNature: translation.options.specialLeaseNature,
            insuranceCategory: translation.options.insuranceCategory,
            personBorrowWith: translation.options.personBorrowWith,
            localProLeaseNature: translation.options.localProLeaseNature,
            paymentMethod: translation.options.paymentMethod
        },
        values: {
            email: quote.email || null,
            contractType: quote.contractType || null,
            insuranceType: quote.insuranceType,
            personBorrowWith: specialValues && specialValues.personBorrowWith ? specialValues.personBorrowWith : null,
            addPerson2InContract: quote.addPerson2InContract,
            insuranceCategory: quote.insuranceCategory,
            oldContractNumber: quote.oldContractNumber || null,
            oldContractDueDate: quote.oldContractDueDate,
            cancellationLetter: quote.cancellationLetter,
            expectedStartDate: quote.expectedStartDate,
            oldInsuranceCompany: quote.oldInsuranceCompany || null,
            paymentMethods: commonData ? commonData.paymentMethods : null,
            ownerIsCompany: commonData ? commonData.ownerIsCompany : null,
            leaseNature: specialValues && specialValues.leaseNature ? specialValues.leaseNature : null,
            allCompanyDrivers: specialValues && specialValues.allCompanyDrivers ? specialValues.allCompanyDrivers : null,
        },
        companies: state.companies,
    };
};

const mapDispatchToProps = (dispatch) => {
    return { 
        addBasicValue: (key, value) => { dispatch(addBasicValue(key, value)) },
        addCommonValue: (key, value) => { dispatch(addCommonValue(key, value)) },
        addSpecialValue: (key, value) => { dispatch(addSpecialValue(key, value)) },
        getCompanies: () => { dispatch(fetchCompany()) },
    };
};

const ContractTypeController =
    (ContractTypeComponenet) => {return connect(mapStateToProps, mapDispatchToProps)(ContractTypeComponenet)};

export default ContractTypeController;
