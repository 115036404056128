import React, { useEffect, useState } from 'react';
import { FormControl, Select, MenuItem, InputLabel, makeStyles } from '@material-ui/core';
import { isValid } from 'date-fns';

const useStyles = makeStyles(theme => ({
  select25: {
    width: '25%',
  },
  select50: {
    width: '50%',
  },
}));
const dates = new Array(31).fill(null).map((item, index) => index + 1);
const months = new Array(12).fill(null).map((item, index) => index + 1);
const labelClassName = 'MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled';
const labelErrorClassName = 'MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled Mui-error';
const selectGroupClassName = 'MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl';
const selectGroupErrorClassName = 'MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl Mui-error';
export default function DateDdlInput({ register, unregister, errors, model, name, validator, label, setValue, dateProps }) {
  const classes = useStyles();
  const err = errors && errors[name] && true;
  const defaultValue = (model && model[name]);
  dateProps = dateProps || { disablePast: false, disableFuture: false };
  let minYear = 1900;
  let maxYear = new Date().getFullYear() + 5;
  if (dateProps.disablePast) {
    minYear = new Date().getFullYear();
  }
  if (dateProps.disableFuture) {
    maxYear = new Date().getFullYear();
  }
  const years = new Array(maxYear - minYear + 1).fill(null).map((item, index) => maxYear - index);
  let date = defaultValue && isValid(new Date(defaultValue)) ? new Date(defaultValue) : null;
  useEffect(() => {
    register({ name }, validator);
    if (date) {
      setValue(name, date);
    }
    return () => {
      unregister(name);
    };
  }, [register, unregister, name, validator, setValue]);

  const [dateObj, setDateObj] = useState({ year: date ? date.getFullYear() : 0, month: date ? date.getMonth() + 1 : 0, date: date ? date.getDate() : 0 });
  const onDateChange = (y, m, d) => {
    let { date, month, year } = dateObj;
    if (y !== undefined) {
      if (y <= 0) {
        setValue(name, undefined);
      } else if (date && month) {
        setValue(name, new Date(`${y}-${month.toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}T00:00:00Z`));
      }
      setDateObj(Object.assign(dateObj, { year: y }));
    }
    if (m !== undefined) {
      if (m === 0) {
        setValue(name, undefined);
      } else if (date && year) {
        setValue(name, new Date(`${year}-${m.toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}T00:00:00Z`));
      }
      setDateObj(Object.assign(dateObj, { month: m }));
    }
    if (d !== undefined) {
      if (d === 0) {
        setValue(name, undefined);
      } else if (year && month) {
        setValue(name, new Date(`${year}-${month.toString().padStart(2, '0')}-${d.toString().padStart(2, '0')}T00:00:00Z`));
      }
      setDateObj(Object.assign(dateObj, { date: d }));
    }
  }
  return <div className="MuiFormControl-root MuiFormControl-fullWidth">
    <label className={err ? labelErrorClassName : labelClassName}>{label}</label>
    <div className={err ? selectGroupErrorClassName : selectGroupClassName}>
      <Select className={classes.select25}
        defaultValue={dateObj.date}
        onChange={e => {
          onDateChange(undefined, undefined, e.target.value);
        }}
      >
        <MenuItem className="py-0" value={0}>&nbsp;</MenuItem>
        {dates.map((item, index) => <MenuItem className="py-0" key={item} value={item}>{item.toString().padStart(2, '0')}</MenuItem>)}
      </Select>
      <Select className={classes.select25}
        defaultValue={dateObj.month}
        onChange={e => {
          onDateChange(undefined, e.target.value, undefined);
        }}
      >
        <MenuItem value={0} className="py-0">&nbsp;</MenuItem>
        {months.map((item, index) => <MenuItem className="py-0" key={item} value={item}>{item.toString().padStart(2, '0')}</MenuItem>)}
      </Select>
      <Select className={classes.select50}
        defaultValue={dateObj.year}
        onChange={e => {
          onDateChange(e.target.value, undefined, undefined)
        }}
      >
        <MenuItem value={0} className="py-0">&nbsp;</MenuItem>
        {years.map((item, index) => <MenuItem key={item} value={item} className="py-0">{item}</MenuItem>)}
      </Select>
    </div>
  </div>
}
