
export const numToString = (num) => {
    if (num === undefined || num === null) {
        return '';
    }
    let s = num.toString();
    return s.replace('.', ',');
}

export const stringToNum = (s) => {
    if (!s) {
        return null;
    }
    let ns = s.replace(',', '.').replace(/\s/g, '');
    let n =  Number(ns);
    return isNaN(n) ? null : n;
}