import React from 'react';
import FormAction from '../../form/FieldTemplate/FormActionComponent';
import {Form} from 'antd';
import {connect} from 'react-redux';
import PersonalInfoContent from './Content/PersonalInfo';
import { getMainAreaY } from '../../../utils/window';

class FormComponent extends React.Component {
    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.onSubmit(values);
            }
        });
    }

    componentDidMount() {
        window.scrollTo(0, getMainAreaY());
    }
    
    render() {
        return (
            <section>
                <h1>{this.props.title}</h1>
                <Form className="label-left" onSubmit={(e)=>this.handleSubmit(e)}>
                    <PersonalInfoContent
                        form={this.props.form}
                    />
                    <FormAction
                        prev
                        onClick={() => this.props.onClick()}
                    />
                </Form>
            </section>
        );
    };
}

const mapStateToProps = (state) => {
    return {
        title: state.translation.title.personal,
    };
};

const PersonalInfoForm = Form.create()(connect(mapStateToProps)(FormComponent));
export default PersonalInfoForm;
