import React from 'react';
import { connect } from 'react-redux';
import Logo from './LogoComponent';
import LangSelector from './LangSelectorComponent';
const MainHeaderComponent = (props) => {
    return <header className="fo-header">
        <Logo />
        <LangSelector />
    </header>
};

const mapStateToProps = (state) => {
    return {
        labels: {
            logout: state.translation.user.logout,
            profile: state.translation.user.profile,
        }
    };
};
const MainHeaderController = connect(mapStateToProps)(MainHeaderComponent);
export default MainHeaderController;