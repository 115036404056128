import {connect} from 'react-redux';
import {addSpecialValue} from '../../../store/actions/formValuesActions';
import {fetchCars} from '../../../store/actions/carActions';
import {fetchPostalCode} from '../../../store/actions/postalCodeAction';

const mapStateToProps = (state) => {
    const specialValues = state.formValues.specialInfo || {};
    return {
        label: {
            carBrand: state.translation.label.specialCarBrand,
            carModel: state.translation.label.specialCarModel,
            carRef: state.translation.label.specialCarRef,
            carPower: state.translation.label.specialCarPower,
            carKm: state.translation.label.specialCarKm,
            carKmAnnual: state.translation.label.specialCarKmAnnual,
            grayCardDate: state.translation.label.specialGrayCardDate,
            carImmaDate: state.translation.label.specialCarImmaDate,
            drivingLicenseDate: state.translation.label.specialDrivingLicenseDate,
            drivingLicenseValidDate: state.translation.label.specialDrivingLicenseValidDate,
            carInsuranceType: state.translation.label.specialCarInsuranceType,
            carUsing: state.translation.label.specialCarUsing,
            workAddress: state.translation.label.specialWorkAddress,
            workPostalCode: state.translation.label.specialWorkPostalCode,
            workCity: state.translation.label.specialWorkCity,
            bmPoint: state.translation.label.specialBMPoint,
            garageType: state.translation.label.specialGarageType,
            carlicenseNumber: state.translation.label.specialCarlicenseNumber,
            carRegistrationNumber: state.translation.label.carRegistrationNumber,
            carNumber: state.translation.label.specialCarNumber,
            secondDriver: state.translation.label.specialSecondDriver,
            secondDriverLicenseDate: state.translation.label.specialDrivingLicenseDate,
            secondDriverLicenseValidDate: state.translation.label.specialDrivingLicenseValidDate,
            mainDriver: state.translation.label.mainDriver,
            lossNumIn3Years: state.translation.label.lossNumIn3Years,
            driverLicenceCancelled: state.translation.label.driverLicenceCancelled,
            energy: state.translation.label.energy,
            assistant0: state.translation.label.assistant0,
            juridicProtection: state.translation.label.juridicProtection,
        },
        options: {
            carInsuranceType: state.translation.options.specialCarInsuranceType,
            carUsing: state.translation.options.specialCarUsing,
            garageType: state.translation.options.specialGarageType,
            lossNumIn3Years: {0: '0', 1: '1', 2: '2', 3: '3', 4: '4', 5: '5', 6: '>=6'},
            energyType: state.translation.options.energyType,
        },
        values: {
            carBrand: specialValues.carBrand,
            carModel: specialValues.carModel,
            carRef: specialValues.carRef,
            carPower: specialValues.carPower,
            carKm: specialValues.carKm,
            carKmAnnual: specialValues.carKmAnnual,
            grayCardDate: specialValues.grayCardDate,
            carImmaDate: specialValues.carImmaDate,  
            carInsuranceType: specialValues.carInsuranceType,
            carUsing: specialValues.carUsing,
            workAddress: specialValues.workAddress,
            workPostalCode: specialValues.workPostalCode,
            workCity: specialValues.workCity,
            bmPoint: specialValues.bmPoint,
            garageType: specialValues.garageType,
            carlicenseNumber: specialValues.carlicenseNumber,
            carNumber: specialValues.carNumber,
            drivingLicenseDate: specialValues.drivingLicenseDate,
            drivingLicenseValidDate: specialValues.drivingLicenseValidDate,
            secondDriverLicenseDate: specialValues.secondDriverLicenseDate,
            secondDriverLicenseValidDate: specialValues.secondDriverLicenseValidDate,
            secondDriver:  specialValues.secondDriver,
            lossNumIn3Years:  specialValues.lossNumIn3Years,
            driverLicenceCancelled: specialValues.driverLicenceCancelled,
            registrationNumber: specialValues.registrationNumber,
            energyType: specialValues.energyType,
            assistant0Km: specialValues.assistant0Km,
            juridicProtection: specialValues.juridicProtection,

        },
        cars: state.cars,
        postalCode: state.postalCode,
        city: state.city,
    };
};

const mapDispatchToProps = (dispatch) => {
    return { 
        addValue: (key, value) => { dispatch(addSpecialValue(key, value)); },
        getCars: () => { dispatch(fetchCars()); },
        getPostalCode: (value) => { dispatch(fetchPostalCode(value)) },
    };
};

const VehicleController =
    (VehicleComponent) => {return connect(mapStateToProps, mapDispatchToProps)(VehicleComponent)};

export default VehicleController;
