import axios from 'axios';
import { baseURL } from './http-constant';


export const api = axios.create({
    baseURL: baseURL
});

export const frGeoApi = axios.create({
    baseURL: 'https://geo.api.gouv.fr'
});
const appAxios = axios.create();

export const getStaticFile = (path) => {
    return appAxios.get(path);
}