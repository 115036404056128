import React from 'react';
import {getFormItemStyle} from '../../../constant/DevisContant';
import {Form, Input} from 'antd';
import {connect} from 'react-redux';
import FormItemLabel from './FormItemLabelComponent';
import {capitalize} from 'lodash-es/string';
const FormItem = Form.Item;

function Text(props) {
    const {getFieldDecorator} = props.form;
    const formItemLayout = getFormItemStyle(props);
    let rules = [];
    if (props.required) {
        rules.push({required: true, message: props.emptyMessage});
    }
    if (props.mail) {
        rules.push({type: 'email', message: props.formatMessage});
    }
    let suffix = props.price ? '€' : '';
    suffix = props.power ? 'CV' : suffix;
    suffix = props.surface ? 'M²' : suffix;
    suffix = props.percent ? '%' : suffix;
    suffix = props.km ? 'KM' : suffix;
    suffix = props.suffix ? props.suffix : suffix;
    const type = props.number ? 'number' : 'text';
    const step = props.step;
    const disabled = props.disabled ? true : false;
    const {tooltip, label, maxLength = 50} = props; 
    return (
        <FormItem 
            {...formItemLayout}
            label={
                <FormItemLabel label={label} tooltip={tooltip}/>
            }
            colon={props.colon}
        >
            {getFieldDecorator(props.name, {
                initialValue: props.value,
                rules: rules,
            })(
                <Input 
                    onChange={(e) => {
                        if (props.onChange) {
                            props.onChange(e.target.value);
                        }
                    }} 
                    suffix={suffix}
                    type={type}
                    step={step}
                    disabled={disabled}
                    onBlur={ (e) => {
                        let v = e.target.value || '';
                        if (props.upperCase) {
                            v = v.toUpperCase();
                        }
                        if (props.lowerCase) {
                            v = v.toLowerCase();
                        }
                        if (props.capitalize) {
                            v = capitalize(v);
                        }
                        if (props.onBlur) {
                            props.onBlur(v)
                        }
                        if (props.upperCase || props.lowerCase || props.capitalize) {
                            props.form.setFieldsValue({ [props.name]: v });
                        }
                    } 
                    }
                    maxLength={maxLength}
                />
            )}
        </FormItem>
    );
}

const mapStateToProps = (state) => {
    return {
        emptyMessage: state.translation.messageError.empty, 
        formatMessage: state.translation.messageError.format
    };
};

const TextController = connect(mapStateToProps)(Text);
export default TextController;
