import React, {Fragment} from 'react';
import {Input} from 'antd';
import generateController from '../../controller/Content/CommentController';

class Comment extends React.Component {
    render() {
        return (
            <Fragment>
                <Input.TextArea
                    style={{maxWidth: '80%'}}
                    rows={10}
                    placeholder={this.props.title}
                    defaultValue={this.props.values.comment}
                    onBlur={(e) => this.props.addBasicValue('comment', e.target.value)}
                    disabled={this.props.disabled}
                    maxLength={1000}
                />
            </Fragment>
        );
    }
}

export default generateController(Comment);
