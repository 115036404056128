import React from 'react';
import { connect } from 'react-redux';
import logo_fr from '../../../images/Logo_fr.jpg';
import logo_cn from '../../../images/Logo_cn.jpg';
import logo from '../../../images/logo1.png';
import { Link } from 'react-router-dom';
const mapStateToProps = (state) => {
    return {
        language: state.language,
    };
};

const LogoComponent = (props) => {
    let home = '/';
    return <div className="logo">
        <Link to={home}>
            <img className="svg-white"
                src={props.logoNoText ? logo : (props.language === 'zh' ? logo_cn : logo_fr)}
                alt={props.language === 'zh' ? '德林保险有限公司' : 'Delin Assurances'}
            />
        </Link>
    </div>
};

const LogoController = connect(mapStateToProps)(LogoComponent);
export default LogoController;