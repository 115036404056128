import {connect} from 'react-redux';
import {addSpecialValue, setUploadFileModel} from '../../store/actions/formValuesActions';
import {submitValues, setQuote} from '../../store/actions/quoteAction';
import {setLoading} from '../../store/actions/loadingAction';

const mapStateToProps = (state) => {
    return {
        title: state.translation.title.comment,
        values: {
            insuranceType: state.formValues.quote.insuranceType ? state.formValues.quote.insuranceType : null,
        },
        quote: state.quote,
        error: {
            message: state.translation.error.message,
            description: state.translation.error.description,
        },
        success: {
            message: state.translation.success.message,
            description: state.translation.success.description,
        },
        button: {
            goBackHome: state.translation.button.goBackHome,
            createNewQuotation: state.translation.button.createNewQuotation
        }
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addSpecialValue: (key, value) => { dispatch(addSpecialValue(key, value)) },
        postFormValues: () => { dispatch(submitValues()) },
        setLoading: (value) => { dispatch(setLoading(value)) },
        setQuote: (value) => {dispatch((setQuote(value)))},
        setFileModel: (value) => {dispatch((setUploadFileModel(value)))},
    };
};

const CommentController =
    (CommentComponenet) => {return connect(mapStateToProps, mapDispatchToProps)(CommentComponenet)};

export default CommentController;
