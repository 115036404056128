import React, { Fragment } from 'react';
import Radio from '../../../form/FieldTemplate/RadioComponent';
import Text from '../../../form/FieldTemplate/TextComponent';
import CustomSwitch from '../../../form/FieldTemplate/SwitchComponent';
import generateController from '../../controller/SpecialFields/LocalProController';
import InsuranceAddress from '../Content/InsuranceAddress';
import Amount from '../../../form/FieldTemplate/AmountComponent';
import Select from '../../../form/FieldTemplate/SelectComponent';
import { Row, Col, Divider } from 'antd';
import { colXL6, colXL8, colXL16, colXL20, colXL18 } from '../../../../constant/DevisContant';
import CustomDate from '../../../form/FieldTemplate/DateDropdownListComponent';
import CheckboxGroup from '../../../form/FieldTemplate/CheckboxGroupComponent';

class LocalPro extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            insuranceAddressSame: this.props.values.insuranceAddressSame
        };
    }
    componentDidMount() {
    }
    render() {
        return (
            <Fragment>
                <Row>
                    <Col span={24}>
                        <Radio
                            form={this.props.form}
                            name="specialInsuranceAddressSame"
                            label={this.props.label.insuranceAddressSame}
                            options={this.props.options.insuranceAddressSame}
                            value={this.state.insuranceAddressSame ? 'true' : 'false'}
                            onChange={(value) => {
                                this.props.addValue('insuranceAddressSame', value === 'true');
                                this.setState({
                                    insuranceAddressSame: value === 'true',
                                });
                            }}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    {!this.state.insuranceAddressSame &&
                        <InsuranceAddress
                            form={this.props.form}
                            disabled={this.props.disabled}
                        />
                    }
                    <Divider></Divider>
                    <Col {...colXL6}>
                        <Select
                            form={this.props.form}
                            name="specialLocalType"
                            label={this.props.label.localType}
                            options={this.props.options.localType}
                            value={this.props.values.localType}
                            onChange={(value) => this.props.addValue('localType', value)}
                            left={10}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    {this.props.values.localType === 'other' && <Col {...colXL6}>
                        <Text
                            form={this.props.form}
                            name="specialLocalTypeOther"
                            label={''}
                            value={this.props.label.localTypeOther}
                            onBlur={(value) => this.props.addValue('localTypeOther', value)}
                            left={0}
                            disabled={this.props.disabled}
                            colon={false}
                        />
                    </Col>}
                    <Col {...colXL6}>
                        <Text
                            form={this.props.form}
                            name="specialInsuredSurface"
                            label={this.props.label.insuredSurface}
                            value={this.props.values.insuredSurface}
                            onBlur={(value) => this.props.addValue('insuredSurface', value)}
                            required
                            number
                            surface
                            left={16}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL6}>
                        <Text
                            form={this.props.form}
                            name="specialInsuredFloor"
                            label={this.props.label.insuredFloor}
                            value={this.props.values.insuredFloor}
                            onBlur={(value) => this.props.addValue('insuredFloor', value)}
                            left={14}
                            number
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL6}>
                        <Text
                            form={this.props.form}
                            name="RCSNum"
                            label={this.props.label.specialRCSNum}
                            value={this.props.values.rcsNum}
                            onBlur={(value) => this.props.addValue('rcsNum', value)}
                            disabled={this.props.disabled}
                            left={10}
                        />
                    </Col>
                    <Col {...colXL6}>
                        <Text
                            form={this.props.form}
                            name="specialActivityCode"
                            label={this.props.label.specialActivityCode}
                            value={this.props.values.activity}
                            onBlur={(value) => this.props.addValue('activity', value)}
                            disabled={this.props.disabled}
                            left={10}
                        />
                    </Col>
                    <Col {...colXL6}>
                        <Amount
                            form={this.props.form}
                            name="specialCAn1"
                            label={this.props.label.cAn1}
                            value={this.props.values.cAn1}
                            onBlur={(value) => this.props.addValue('cAn1', value)}
                            disabled={this.props.disabled}
                            left={10}
                        />
                    </Col>
                    <Col {...colXL6}>
                        <Amount
                            form={this.props.form}
                            name="specialCAN"
                            label={this.props.label.specialCAN}
                            value={this.props.values.cAn}
                            onBlur={(value) => this.props.addValue('cAn', value)}
                            disabled={this.props.disabled}
                            left={14}
                        />
                    </Col>
                    <Col md={24} lg={24} xl={8}>
                        <Row>
                            <Col span={20} className="d-flex align-items-left">
                                <b>{this.props.label.localContentAmount}</b>
                            </Col>
                            <Col {...colXL20}>
                                <Amount
                                    form={this.props.form}
                                    name="specialContentAmount"
                                    label={this.props.label.specialContentAmount}
                                    value={this.props.values.contentAmount}
                                    onBlur={(value) => this.props.addValue('contentAmount', value)}
                                    left={8}
                                    disabled={this.props.disabled}
                                />
                            </Col>
                            <Col {...colXL20}>
                                <Amount
                                    form={this.props.form}
                                    name="specialEquipmentAmount"
                                    label={this.props.label.equipmentAmount}
                                    value={this.props.values.equipmentAmount}
                                    onBlur={(value) => this.props.addValue('equipmentAmount', value)}
                                    left={8}
                                    disabled={this.props.disabled}
                                />
                            </Col>
                            <Col {...colXL20}>
                                <Amount
                                    form={this.props.form}
                                    name="specialGlassAmount"
                                    label={this.props.label.glassAmount}
                                    value={this.props.values.glassAmount}
                                    onBlur={(value) => this.props.addValue('glassAmount', value)}
                                    left={8}
                                    disabled={this.props.disabled}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={24} lg={24} xl={14}>
                        <Col span={24} className="d-flex align-items-left">
                            <b>{this.props.label.insurance}</b>
                        </Col>
                        <Col {...colXL8}>
                            <CustomSwitch
                                form={this.props.form}
                                name="specialInsuranceResponsible"
                                label={this.props.label.insuranceResponsible}
                                value={this.props.values.insuranceResponsible}
                                onChange={(value) => {
                                    this.props.addValue('insuranceResponsible', value);
                                }}
                                disabled={this.props.disabled}
                            />
                        </Col>
                        <Col {...colXL16}>
                            <Text
                                form={this.props.form}
                                name="specialEmployeeNumber"
                                label={this.props.label.employeeNumber}
                                value={this.props.values.employeeNumber}
                                onBlur={(value) => this.props.addValue('employeeNumber', value)}
                                number
                                disabled={this.props.disabled}
                                left={10}
                            />
                        </Col>
                        <Col {...colXL8}>
                            <CustomSwitch
                                form={this.props.form}
                                name="specialInsuranceVol"
                                label={this.props.label.vol}
                                value={this.props.values.insuranceVol}
                                onChange={(value) => {
                                    this.props.addValue('insuranceVol', value);
                                    this.setState({
                                        insuranceVol: value,
                                    });
                                }}
                                disabled={this.props.disabled}
                            />
                        </Col>
                        <Col {...colXL16}>
                            <Amount
                                form={this.props.form}
                                name="specialInsuranceVolMontant"
                                label={this.props.label.insuranceVolMontant}
                                value={this.props.values.insuranceVolMontant}
                                onBlur={(value) => this.props.addValue('insuranceVolMontant', value)}
                                disabled={this.props.disabled}
                                left={10}
                            />
                        </Col>
                        <Col {...colXL8}>
                            <CustomSwitch
                                form={this.props.form}
                                name="specialInsuranceLoss"
                                label={this.props.label.insuranceLoss}
                                value={this.props.values.insuranceLoss}
                                onChange={(value) => {
                                    this.props.addValue('insuranceLoss', value);
                                    this.setState({
                                        insuranceLoss: value,
                                    });
                                }}
                                disabled={this.props.disabled}
                            />
                        </Col>
                        <Col {...colXL16}>
                            <Text
                                form={this.props.form}
                                name="grossMargin"
                                label={this.props.label.grossMargin}
                                value={this.props.values.grossMargin}
                                onBlur={(value) => this.props.addValue('grossMargin', value)}
                                number
                                percent
                                disabled={this.props.disabled}
                                left={10}
                            />
                        </Col>
                    </Col>
                    <Col span={24}>
                        <CheckboxGroup
                            form={this.props.form}
                            name="specialInsuranceSecurityEquipment"
                            label={this.props.label.insuranceSecurityEquipment}
                            value={this.props.values.insuranceSecurityEquipment ? this.props.values.insuranceSecurityEquipment : []}
                            options={this.props.options.insuranceSecurityEquipment}
                            onChange={(value) => {
                                this.props.addValue('insuranceSecurityEquipment', value);
                            }}
                            center={false}
                            left={6}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col span={24}>
                        {/* <Select
                            form={this.props.form}
                            name="specialInsuranceFireSafetyEquipment"
                            label={this.props.label.insuranceFireSafetyEquipment}
                            value={this.props.values.insuranceFireSafetyEquipment ? this.props.values.insuranceFireSafetyEquipment : []}
                            options={this.props.options.insuranceFireSafetyEquipment}
                            onChange={(value) => this.props.addValue('insuranceFireSafetyEquipment', value)}
                            multiple
                            left={9}
                            disabled={this.props.disabled}
                        /> */}
                        <CheckboxGroup
                            form={this.props.form}
                            name="specialInsuranceFireSafetyEquipment"
                            label={this.props.label.insuranceFireSafetyEquipment}
                            value={this.props.values.insuranceFireSafetyEquipment ? this.props.values.insuranceFireSafetyEquipment : []}
                            options={this.props.options.insuranceFireSafetyEquipment}
                            onChange={(value) => {
                                this.props.addValue('insuranceFireSafetyEquipment', value);
                            }}
                            center={false}
                            left={6}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL18}>
                        <CheckboxGroup
                            form={this.props.form}
                            name="specialInsuranceAlertEquipment"
                            label={this.props.label.insuranceAlertEquipment}
                            value={this.props.values.insuranceAlertEquipment ? this.props.values.insuranceAlertEquipment : []}
                            options={this.props.options.insuranceAlertEquipment}
                            onChange={(value) => {
                                this.props.addValue('insuranceAlertEquipment', value);
                            }}
                            center={false}
                            left={8}
                            disabled={this.props.disabled}
                        />


                    </Col>
                    {this.props.values.insuranceAlertEquipment && this.props.values.insuranceAlertEquipment.indexOf('agency') > -1 &&
                        <Col {...colXL6}>
                            <Text
                                form={this.props.form}
                                name="alertAgencyName"
                                label={''}
                                value={this.props.values.alertAgencyName}
                                onBlur={(value) => this.props.addValue('alertAgencyName', value)}
                                disabled={this.props.disabled}
                                left={0}
                            />
                        </Col>
                    }
                    <Col md={24} lg={24} xl={12}>
                        <Row>
                            <Col span={24}>
                                <CustomSwitch
                                    form={this.props.form}
                                    name="specialFireSafetyEquipmentCheck"
                                    label={this.props.label.fireSafetyEquipmentCheck}
                                    value={this.props.values.fireSafetyEquipmentCheck}
                                    onChange={(value) => {
                                        this.props.addValue('fireSafetyEquipmentCheck', value);
                                        this.setState({
                                            fireSafetyEquipmentCheck: value,
                                        });
                                    }}
                                    colon={false}
                                    disabled={this.props.disabled}
                                />
                            </Col>
                            <Col span={24}>
                                <CustomDate
                                    form={this.props.form}
                                    name="specialFireSafetyEquipmentCheckDate"
                                    label={this.props.label.latestVerificationDate}
                                    value={this.props.values.fireSafetyEquipmentCheckDate}
                                    onChange={(value) => this.props.addValue('fireSafetyEquipmentCheckDate', value)}
                                    left={8}
                                    disabled={this.props.disabled}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={24} lg={24} xl={12}>
                        <Row>
                            <Col span={24}>
                                <CustomSwitch
                                    form={this.props.form}
                                    name="specialElectricEquipmentCheck"
                                    label={this.props.label.electricEquipmentCheck}
                                    value={this.props.values.electricEquipmentCheck}
                                    onChange={(value) => {
                                        this.props.addValue('electricEquipmentCheck', value);
                                        this.setState({
                                            electricEquipmentCheck: value,
                                        });
                                    }}
                                    colon={false}
                                    disabled={this.props.disabled}
                                />
                            </Col>
                            <Col span={24}>
                                <CustomDate
                                    form={this.props.form}
                                    name="specialElectricEquipmentCheckDate"
                                    label={this.props.label.latestVerificationDate}
                                    value={this.props.values.electricEquipmentCheckDate}
                                    onChange={(value) => this.props.addValue('electricEquipmentCheckDate', value)}
                                    left={8}
                                    disabled={this.props.disabled}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

const LocalProController = generateController(LocalPro);
export default LocalProController;
