import {store} from '../../../index';
import {api} from '../../../constant/Axios';
import {setLoading} from './loadingAction';
import {setValues} from './formValuesActions';

export const SET_QUOTE = 'SET_QUOTE';

export const setQuote = (quote) => {
    return {type: SET_QUOTE, quote};
};

const postQuote = () => {
    const formValues = store.getState().formValues;
    const model = JSON.parse(JSON.stringify(formValues));
    const insuType = model.quote.insuranceType;
    //change name of special info
    if (model.specialInfo) {
        let specialInfo = model.specialInfo;
        model[insuType] = specialInfo;
        model.specialInfo = null;
    }
    //add lang
    model['lang'] = store.getState().language;
    const insuCate = model.quote.insuranceCategory;
    if (insuCate === 'per') {
        model.quote.company = null;
        model.quote.companyPerson = null;
    } else {
        if (insuType !== 'vehicle') {
            model.quote.person = null;
            model.quote.person2 = null;
        }
    }

    return api.post('quoterequests', model)
        .then(resp => resp.data);
} 

export const submitValues = () =>
    (dispatch) => postQuote()
        .then(response => {
            dispatch(setQuote({
                success: true
            }));
            dispatch(setLoading(false));
        })
        .catch((error) => {
            // handleApiError(error);
            dispatch(setQuote({
                error: true
            }));
            dispatch(setLoading(false));
        });

const putQuote = () => {
    const formValues = store.getState().formValues;
    const model = JSON.parse(JSON.stringify(formValues));
    const insuType = model.quote.insuranceType;
    //change name of special info
    if (model.specialInfo) {
        model[formValues.quote.insuranceType] = model.specialInfo;
    }
    //add lang
    model['lang'] = store.getState().language;
    const insuCate = model.quote.insuranceCategory;
    if (insuCate === 'per') {
        model.quote.company = null;
        model.quote.companyPerson = null;
    } else {
        if (insuType !== 'vehicle') {
            model.quote.person = null;
            model.quote.person2 = null;
        }
    }

    return api.put('quoterequests/' + model.quote.quoteRequestId, model)
        .then(resp => resp.data);
} 

export const modifyValues = () =>
    (dispatch) => putQuote()
        .then(response => {
            dispatch(setQuote({
                success: true
            }));
            dispatch(setLoading(false));
            dispatch(setValues({}));
        })
        .catch((error) => {
            // handleApiError(error);
            dispatch(setQuote({
                error: true
            }));
            dispatch(setLoading(false));
        });     
