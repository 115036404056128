import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Login from '../form/templates/login';
import { getServiceUserFromStorage, saveServiceUserToStorage } from '../../utils/storage';
import { setServiceUser, setGlobalSpin } from '../../core/action';
import { useStoreDispatch } from '../../core/store';
import { serviceRoute } from '../../constant/route-constant';
import serviceApi from '../../api/service.api';
import http from '../../core/http';
import { useToast } from '../../core/notifier';
export default function ServiceUserLogin(props) {
  const [model, setModel] = useState({ toServicePage: false, loading: true });
  const dispatch = useStoreDispatch();
  const addToast = useToast();
  let redirectUrl = serviceRoute;
  if (props.location && props.location.state
    && props.location.state.from && props.location.state.from.indexOf(serviceRoute) > -1) {
    redirectUrl = props.location.state.from;
  }
  let user = getServiceUserFromStorage();
  useEffect(() => {
    if (user && user.token) {
      http.setToken(user.token, 'services');
      const fetchData = async () => {
        dispatch(setGlobalSpin(true));
        try {
          let data = await serviceApi.checkToken();
          data.token = user.token;
          saveServiceUserToStorage(data);
          dispatch(setServiceUser(data))
          setModel({ toServicePage: true });
        } catch {
          http.setToken(null, 'services');
          saveServiceUserToStorage(null);
        }
        finally {
          dispatch(setGlobalSpin(false));
        }
      };
      fetchData();
    }
  }, []);
  const onSubmit = async value => {
    dispatch(setGlobalSpin(true));
    try {
      const data = await serviceApi.login(value);
      saveServiceUserToStorage(data);
      http.setToken(data.token, 'services');
      dispatch(setServiceUser(data))
      setModel({ toServicePage: true });
    } catch (error) {
       addToast('loginFailed', 'error');
    } finally {
      dispatch(setGlobalSpin(false));
    }
  };
  if (model.toServicePage) {
    return <Redirect to={redirectUrl} />
  }
  return <Login onSubmit={onSubmit} title="Delin Assurances"
    bgImage='https://delinprod.blob.core.windows.net/public/bg-login-services.jpg'></Login>
}