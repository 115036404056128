import {connect} from 'react-redux';
import {addSpecialValue} from '../../../store/actions/formValuesActions';

const mapStateToProps = (state) => {
    const specialValues = state.formValues.specialInfo;
    return {
        label: {
            insuranceAmount: state.translation.label.specialInsuredAmount,
            cAn1: state.translation.label.specialCAn1,
        },
        values: {
            insuranceAmount: specialValues && specialValues.insuranceAmount ? specialValues.insuranceAmount : null,
            cAn1: specialValues && specialValues.cAn1 ? specialValues.cAn1 : null,
        }
    };
};

const mapDispatchToProps = (dispatch) => {
    return { 
        addValue: (key, value) => { dispatch(addSpecialValue(key, value)) },
    };
};

const ResponsibleController =
    (ResponsibleComponent) => {return connect(mapStateToProps, mapDispatchToProps)(ResponsibleComponent)};

export default ResponsibleController;
