import React, { Fragment } from 'react';
import Text from '../../../form/FieldTemplate/TextComponent';
import Telephone from '../../../form/FieldTemplate/TelephoneComponent';
import generateController from '../../controller/Content/ComponyInfoController';
import Block from '../../../form/FieldTemplate/BlockComponent';
import Select from '../../../form/FieldTemplate/SelectComponent';
import { colXL6, colXL12, colXL8, colXL16, colXL20 } from '../../../../constant/DevisContant';
import { Col, Row, Divider } from 'antd';
import CityAutoComplete from '../../../form/FieldTemplate/CityAutoCompleteComponent';
import PostalCodeText from '../../../form/FieldTemplate/PostalCodeComponent';
import CustomSwitch from '../../../form/FieldTemplate/SwitchComponent';
const ComponyInfo = (props) => {
    const { company: c, companyContact: cc, companyPerson: cp, companyPersonContact: cpc, insuranceType, allCompanyDrivers, ownerCompany } = props.values;
    const name = c.name;
    const isCar = insuranceType === 'vehicle';
    const isLocalPro = insuranceType === 'localPro';
    const updateOwnerCompany = (key, value) => {
        let oc = ownerCompany;
        oc[key] = value;
        props.addCommonValue('company', oc);
    };
    const updateOwnerCompanyContact = (key, value) => {
        let oc = ownerCompany;
        oc.contact[key] = value;
        props.addCommonValue('company', oc);
    };
    return <Fragment>
        <Row>
            <Col {...colXL12}>
                <Text
                    form={props.form}
                    name="companyName"
                    label={props.label.name}
                    value={name}
                    required
                    onBlur={(e) => {
                        props.addValue('name', e);
                    }}
                    left={5}
                    disabled={props.disabled}
                    upperCase
                />
            </Col>
            <Col {...colXL6}>
                <Select
                    form={props.form}
                    name="companyLegalStatus"
                    label={props.label.companyLegalStatus}
                    options={props.options.companyLegalStatus}
                    value={c.legalStatus}
                    onChange={(value) => props.addValue('legalStatus', value)}
                    left={10}
                    disabled={props.disabled}
                />
            </Col>
            {c.legalStatus === 'other' && <Col {...colXL6}>
                <Text
                    form={props.form}
                    name="companyLegalStatusOther"
                    label={props.label.companyLegalStatus}
                    value={c.legalStatusOther}
                    onBlur={(value) => props.addValue('legalStatusOther', value)}
                    left={10}
                    disabled={props.disabled}
                    colon={false}
                />
            </Col>}
            <Col {...colXL6}>
                <Block
                    form={props.form}
                    name="companySiret"
                    label={props.label.siret}
                    value={c.siren}
                    blocks={[3, 3, 3, 5]}
                    onBlur={(value) => props.addValue('siren', value)}
                    siret
                    number
                    left={10}
                    disabled={props.disabled}
                />
            </Col>
            <Col {...colXL6}>
                <Text
                    form={props.form}
                    name="NAF"
                    label={props.label.naf}
                    value={c.naf}
                    onBlur={(value) => props.addValue('naf', value)}
                    disabled={props.disabled}
                    left={10}
                />
            </Col>
            <Col {...colXL6}>
                <Telephone
                    form={props.form}
                    name="companyTel"
                    label={props.label.tel}
                    value={{ prefix: cc.telPrefix, value: cc.tel }}
                    onChange={(isPrefix, value) => props.addContactValue(isPrefix ? 'telPrefix' : 'tel', value)}
                    left={8}
                    disabled={props.disabled}
                />
            </Col>
            <Col {...colXL6}>
                <Telephone
                    form={props.form}
                    name="companyFax"
                    label={props.label.fax}
                    value={{ prefix: cc.faxPrefix, value: cc.fax }}
                    onChange={(isPrefix, value) => props.addContactValue(isPrefix ? 'faxPrefix' : 'fax', value)}
                    left={8}
                    disabled={props.disabled}
                />
            </Col>
            <Col {...colXL12}>
                <Text
                    form={props.form}
                    name="companyAddress1"
                    label={props.label.address}
                    value={cc.address1}
                    onBlur={(value) => props.addContactValue('address1', value)}
                    required={props.values.insuranceCategory === 'pro'}
                    left={5}
                    disabled={props.disabled}
                    maxLength={100}
                />
            </Col>
            <Col {...colXL12}>
                <Text
                    form={props.form}
                    name="companyAddress2"
                    label={props.label.address + '2'}
                    value={cc.address2}
                    onBlur={(value) => props.addContactValue('address2', value)}
                    left={5}
                    disabled={props.disabled}
                    colon={false}
                    maxLength={100}
                />
            </Col>
            <Col {...colXL20}>
                <Text
                    form={props.form}
                    name="companyAddressInfo"
                    label={props.label.addressInfo}
                    value={cc.address3}
                    onBlur={(value) => props.addContactValue('address3', value)}
                    left={3}
                    disabled={props.disabled}
                    maxLength={100}
                />
            </Col>
            <Col {...colXL6}>
                <PostalCodeText
                    form={props.form}
                    name="companyPostalCode"
                    label={props.label.postalCode}
                    value={cc.postalCode}
                    updateCity={(value) => {
                        props.addContactValue('city', value);
                    }}
                    updateCP={(value) => props.addContactValue('postalCode', value)}
                    required={props.values.insuranceCategory === 'pro'}
                    disabled={props.disabled}
                    left={10}
                />
            </Col>
            <Col {...colXL6}>
                <CityAutoComplete
                    form={props.form}
                    name="companyCity"
                    label={props.label.city}
                    value={cc.city}
                    updateCity={(value) => {
                        props.addContactValue('city', value);
                    }}
                    updateCP={(value) => props.addContactValue('postalCode', value)}
                    required={props.values.insuranceCategory === 'pro'}
                    left={10}
                    disabled={props.disabled}
                />
            </Col>
            <Col {...colXL6}>
                <Text
                    form={props.form}
                    name="companyCountry"
                    label={props.label.country}
                    value={props.values.country}
                    required={props.values.insuranceCategory === 'pro'}
                    left={10}
                    disabled={props.disabled}
                />
            </Col>
            {props.values.insuranceCategory === 'pro' &&
                <Fragment>
                    <Divider ><h4>{props.label.gerant}</h4></Divider>
                    <Col {...colXL6}>
                        <Select
                            form={props.form}
                            name="personalCivility"
                            label={props.label.civility}
                            options={props.options.civility}
                            value={cp.civility}
                            onChange={(value) => {
                                props.addCompanyPersonValue('civility', value);
                                if (props.values.isCompanyContactMainDriver) {
                                    props.addPersonValue('civility', value);
                                }
                            }}
                            left={10}
                            disabled={props.disabled}
                            zeroValue="none"
                        />
                    </Col>
                    <Col {...colXL6}>
                        <Text
                            form={props.form}
                            name="personalLastName"
                            label={props.label.lastName}
                            value={cp.lastName}
                            onBlur={(value) => {
                                props.addCompanyPersonValue('lastName', value)
                                if (props.values.isCompanyContactMainDriver) {
                                    props.addPersonValue('lastName', value);
                                }
                            }}
                            required
                            left={10}
                            disabled={props.disabled}
                            upperCase
                        />
                    </Col>
                    <Col {...colXL6}>
                        <Text
                            form={props.form}
                            name="personalFirstName"
                            label={props.label.firstName}
                            value={cp.firstName}
                            onBlur={(value) => {
                                props.addCompanyPersonValue('firstName', value);
                                if (props.values.isCompanyContactMainDriver) {
                                    props.addPersonValue('firstName', value);
                                }
                            }}
                            required
                            left={10}
                            disabled={props.disabled}
                            capitalize
                        />
                    </Col>
                    {cp.civility === 'ms' && <Col {...colXL6}>
                        <Text
                            form={props.form}
                            name="personalMaidenName"
                            label={props.label.maidenName}
                            value={cp.maidenName}
                            onBlur={(value) => {
                                props.addCompanyPersonValue('maidenName', value);
                                if (props.values.isCompanyContactMainDriver) {
                                    props.addPersonValue('maidenName', value);
                                }
                            }}
                            disabled={props.disabled}
                            upperCase
                        />
                    </Col>}
                    <Col {...colXL6}>
                        <Text
                            form={props.form}
                            name="personalFullName"
                            label={props.label.fullName}
                            value={cp.fullName}
                            onBlur={(value) => {
                                props.addCompanyPersonValue('fullName', value);
                                if (props.values.isCompanyContactMainDriver) {
                                    props.addPersonValue('fullName', value);
                                }
                            }}
                            left={12}
                            disabled={props.disabled}
                        />
                    </Col>
                    <Col {...colXL6}>
                        <Text
                            form={props.form}
                            name="personalEmail"
                            label={props.label.email}
                            value={cpc.email}
                            onBlur={(value) => {
                                props.addCompanyPersonContactValue('email', value);
                                props.addBasicValue('email', value);
                                if (props.values.isCompanyContactMainDriver) {
                                    props.addPersonContactValue('email', value);
                                }
                            }}
                            mail
                            required
                            left={10}
                            disabled={props.disabled}
                            lowerCase
                        />
                    </Col>
                    <Col {...colXL6}>
                        <Telephone
                            form={props.form}
                            name="personalMobile"
                            label={props.label.mobile}
                            value={{ prefix: cpc.mobilePrefix, value: cpc.mobile }}
                            onChange={(isPrefix, value) => {
                                props.addCompanyPersonContactValue(isPrefix ? 'mobilePrefix' : 'mobile', value)
                                if (props.values.isCompanyContactMainDriver) {
                                    props.addPersonContactValue(isPrefix ? 'mobilePrefix' : 'mobile', value)
                                }
                            }}
                            required
                            left={10}
                            disabled={props.disabled}
                        />
                    </Col>
                    <Col {...colXL6}>
                        <Telephone
                            form={props.form}
                            name="personalTel"
                            label={props.label.tel}
                            value={{ prefix: cpc.telPrefix, value: cpc.tel }}
                            onChange={(isPrefix, value) => {
                                props.addCompanyPersonContactValue(isPrefix ? 'telPrefix' : 'tel', value)
                                if (props.values.isCompanyContactMainDriver) {
                                    props.addPersonContactValue(isPrefix ? 'telPrefix' : 'tel', value)
                                }
                            }}
                            left={10}
                            disabled={props.disabled}
                        />
                    </Col>
                    {isCar && !allCompanyDrivers &&
                        <Col {...colXL6}>
                            <CustomSwitch
                                form={props.form}
                                name="isCompanyContactMainDriver"
                                label={props.label.isCompanyContactMainDriver}
                                value={props.values.isCompanyContactMainDriver}
                                onChange={(value) => {
                                    props.addBasicValue('isCompanyContactMainDriver', value);
                                    if (value) {
                                        props.addPersonValue('civility', cp.civility);
                                        props.addPersonValue('lastName', cp.lastName);
                                        props.addPersonValue('firstName', cp.firstName);
                                        props.addPersonValue('maidenName', cp.maidenName);
                                        props.addPersonValue('fullName', cp.fullName);
                                        props.addPersonContactValue('email', cpc.email);
                                        props.addPersonContactValue('mobilePrefix', cpc.mobilePrefix);
                                        props.addPersonContactValue('telPrefix', cpc.telPrefix);
                                        props.addPersonContactValue('mobile', cpc.mobile);
                                        props.addPersonContactValue('tel', cpc.tel);
                                    }
                                }}
                                className="form-item-control-center"
                                disabled={props.disabled}
                            />
                        </Col>
                    }
                </Fragment>
            }
            {props.values.ownerIsCompany && isLocalPro &&
                <Fragment>
                    <Divider ><h4>{props.label.houseOwner}</h4></Divider>
                    <Col {...colXL12}>
                        <Text
                            form={props.form}
                            name="ownerCompanyName"
                            label={props.label.name}
                            value={ownerCompany.name}
                            required
                            onBlur={(e) => {
                                updateOwnerCompany('name', e);
                            }}
                            left={5}
                            disabled={props.disabled}
                            upperCase
                        />
                    </Col>
                    <Col {...colXL6}>
                        <Select
                            form={props.form}
                            name="ownerCompanyLegalStatus"
                            label={props.label.companyLegalStatus}
                            options={props.options.companyLegalStatus}
                            value={ownerCompany.legalStatus}
                            onChange={(value) => updateOwnerCompany('legalStatus', value)}
                            left={10}
                            disabled={props.disabled}
                        />
                    </Col>
                    {ownerCompany.legalStatus === 'other' && <Col {...colXL6}>
                        <Text
                            form={props.form}
                            name="ownerCompanyLegalStatusOther"
                            label={props.label.companyLegalStatus}
                            value={ownerCompany.legalStatusOther}
                            onBlur={(value) => updateOwnerCompany('legalStatusOther', value)}
                            left={10}
                            disabled={props.disabled}
                            colon={false}
                        />
                    </Col>}
                    <Col {...colXL6}>
                        <Block
                            form={props.form}
                            name="ownerCompanySiret"
                            label={props.label.siret}
                            value={ownerCompany.siren}
                            blocks={[3, 3, 3, 5]}
                            onBlur={(value) => updateOwnerCompany('siren', value)}
                            siret
                            number
                            left={10}
                            disabled={props.disabled}
                        />
                    </Col>
                    <Col {...colXL12}>
                        <Text
                            form={props.form}
                            name="ownerCompanyAddress1"
                            label={props.label.address}
                            value={ownerCompany.contact.address1}
                            onBlur={(value) => updateOwnerCompanyContact('address1', value)}
                            required={props.values.insuranceCategory === 'pro'}
                            left={5}
                            disabled={props.disabled}
                            maxLength={100}
                        />
                    </Col>
                    <Col {...colXL12}>
                        <Text
                            form={props.form}
                            name="ownerCompanyAddress2"
                            label={props.label.address + '2'}
                            value={ownerCompany.contact.address2}
                            onBlur={(value) => updateOwnerCompanyContact('address2', value)}
                            left={5}
                            disabled={props.disabled}
                            colon={false}
                            maxLength={100}
                        />
                    </Col>
                    <Col {...colXL20}>
                        <Text
                            form={props.form}
                            name="ownerCompanyAddressInfo"
                            label={props.label.addressInfo}
                            value={ownerCompany.contact.address3}
                            onBlur={(value) => updateOwnerCompanyContact('address3', value)}
                            left={3}
                            disabled={props.disabled}
                            maxLength={100}
                        />
                    </Col>
                    <Col {...colXL6}>
                        <PostalCodeText
                            form={props.form}
                            name="ownerCompanyPostalCode"
                            label={props.label.postalCode}
                            value={ownerCompany.contact.postalCode}
                            updateCity={(value) => {
                                updateOwnerCompanyContact('city', value);
                            }}
                            updateCP={(value) => updateOwnerCompanyContact('postalCode', value)}
                            required={props.values.insuranceCategory === 'pro'}
                            disabled={props.disabled}
                            left={10}
                        />
                    </Col>
                    <Col {...colXL6}>
                        <CityAutoComplete
                            form={props.form}
                            name="ownerCompanyCity"
                            label={props.label.city}
                            value={ownerCompany.contact.city}
                            updateCity={(value) => {
                                updateOwnerCompanyContact('city', value);
                            }}
                            updateCP={(value) => updateOwnerCompanyContact('postalCode', value)}
                            required={props.values.insuranceCategory === 'pro'}
                            left={10}
                            disabled={props.disabled}
                        />
                    </Col>
                    <Col {...colXL6}>
                        <Text
                            form={props.form}
                            name="ownerCompanyCountry"
                            label={props.label.country}
                            value={props.values.country}
                            required={props.values.insuranceCategory === 'pro'}
                            left={10}
                            disabled={props.disabled}
                        />
                    </Col>
                </Fragment>
            }
        </Row>
    </Fragment>
};

export default generateController(ComponyInfo);
