import React, { Fragment } from 'react';
import generateController from '../../controller/SpecialFields/ResponsibleController';
import Amount from '../../../form/FieldTemplate/AmountComponent';

const Responsible = props =>
    <Fragment>
        <Amount
            form={props.form}
            name="specialInsuredAmount"
            label={props.label.insuranceAmount}
            value={props.values.insuranceAmount}
            onBlur={(value) => props.addValue('insuranceAmount', value)}
            disabled={props.disabled}
        />
        <Amount
            form={props.form}
            name="specialCAn1"
            label={props.label.cAn1}
            value={props.values.cAn1}
            onBlur={(value) => props.addValue('cAn1', value)}
            disabled={props.disabled}
        />
    </Fragment>
;

const ResponsibleController = generateController(Responsible);
export default ResponsibleController;
