import { baseURL, nodeApiURL } from "../constant/http-constant";
import { CustomError } from './error';
// const baseUrl = 'https://delinstaging.azurewebsites.net/_api';
// export async function postData(url = '', data = {}) {
//     if (url.indexOf('https://') !== 0) {
//         url = baseUrl + url;
//     }
//     // Default options are marked with *
//     const response = await fetch(url, {
//         method: 'POST', // *GET, POST, PUT, DELETE, etc.
//         mode: 'cors', // no-cors, *cors, same-origin
//         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
//         credentials: 'same-origin', // include, *same-origin, omit
//         headers: {
//             'Content-Type': 'application/json'
//             // 'Content-Type': 'application/x-www-form-urlencoded',
//         },
//         redirect: 'follow', // manual, *follow, error
//         referrer: 'no-referrer', // no-referrer, *client
//         body: JSON.stringify(data) // body data type must match "Content-Type" header
//     });
//     return await response.json(); // parses JSON response into native JavaScript objects
// }
class HttpClient {
    constructor() {
        this.baseUrl = baseURL;
        this.nodeApiUrl = nodeApiURL;
        this.config = {
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer', // no-referrer, *client
        }

    }
    async get(url = '') {
        url = this.getUrl(url);
        let request = { ...this.config };
        request.method = 'GET';
        if (this.authToken) {
            request.headers['authorization'] = 'bearer ' + this.authToken;
        }
        const response = await fetch(url, request);
        if (!response.ok) // or check for response.status
        {
            await this.handleError(response);
        }
        return response.json();
    }
    async delete(url = '') {
        url = this.getUrl(url);
        let request = { ...this.config };
        request.method = 'DELETE';
        if (this.authToken) {
            request.headers['authorization'] = 'bearer ' + this.authToken;
        }
        const response = await fetch(url, request);
        if (!response.ok) // or check for response.status
        {
            await this.handleError(response);
        }
        return response.json();
    }
    async post(url = '', data = {}) {
        url = this.getUrl(url);
        let request = { ...this.config };
        request.method = 'POST';
        if (this.authToken) {
            request.headers['authorization'] = 'bearer ' + this.authToken;
        }
        request.body = JSON.stringify(data);
        const response = await fetch(url, request);
        if (!response.ok) // or check for response.status
        {
            await this.handleError(response);
        }
        return response.json();
    }
    async put(url = '', data = {}) {
        url = this.getUrl(url);
        let request = { ...this.config };
        request.method = 'PUT';
        if (this.authToken) {
            request.headers['authorization'] = 'bearer ' + this.authToken;
        }
        request.body = JSON.stringify(data);
        const response = await fetch(url, request);
        if (!response.ok) // or check for response.status
        {
            await this.handleError(response);
        }
        return response.json();
    }
    async patch(url = '', data = {}) {
        url = this.getUrl(url);
        let request = { ...this.config };
        request.method = 'PATCH';
        if (this.authToken) {
            request.headers['authorization'] = 'bearer ' + this.authToken;
        }
        request.body = JSON.stringify(data);
        const response = await fetch(url, request);
        if (!response.ok) // or check for response.status
        {
            await this.handleError(response);
        }
        return response.json();
    }

    getUrl(url) {
        if (url.indexOf('https://') !== 0 && url.indexOf('http://') !== 0) {
            return url = this.baseUrl + url;
        }
        return url;
    }
    setToken(token, basePath) {
        this.authToken = token;
        this.basePath = basePath;
    }
    handleError = async response => {
        const { status } = response;
        if ((status === 401 || status === 403) && response.url.indexOf('/login') < 0 && response.url.indexOf('/checktoken') < 0) {
            window.location = window.location.origin + '/' + this.basePath;
        }
        let error = await response.json();
        try {
            error = await response.json();
        } catch {
            error = 'Erreur';
        }
        throw new CustomError(error);
    }
}
export default (new HttpClient());