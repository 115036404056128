import {api} from '../../../constant/Axios';
import {setLanguage} from '../actions/languageAction';
import {translation_cn} from '../../../translation/CN';
import {setTranslation} from '../actions/translationActions';

export const ADD_BASIC_VALUE = 'ADD_BASIC_VALUE';
export const ADD_COMPANY_VALUE = 'ADD_COMPANY_VALUE';
export const ADD_COMPANY_CONTACT_VALUE = 'ADD_COMPANY_CONTACT_VALUE';
export const ADD_PERSONAL_VALUE = 'ADD_PERSONAL_VALUE';
export const ADD_PERSONAL_CONTACT_VALUE = 'ADD_PERSONAL_CONTACT_VALUE';
export const ADD_SPECIAL_VALUE = 'ADD_SPECIAL_VALUE';
export const ADD_UPLOAD_VALUE = 'ADD_UPLOAD_VALUE';
export const SUBMIT_VALUES = 'SUBMIT_VALUES';
export const SET_VALUES = 'SET_VALUES';
export const SET_SPECIAL_VALUE = 'SET_SPECIAL_VALUE';
export const ADD_DELETE = 'ADD_DELETE';
export const ADD_SPECIAL_ADDRESS_VALUE = 'ADD_SPECIAL_ADDRESS_VALUE';
export const ADD_COMMON_VALUE = 'ADD_COMMON_VALUE';
export const ADD_OWNER_COMPANY_VALUE = 'ADD_OWNER_COMPANY_VALUE';
export const SET_UPLOAD_FILE_MODEL_VALUE = 'SET_UPLOAD_FILE_MODEL_VALUE';

export const setValues = (formValues) => {
    return {type: SET_VALUES, formValues};
};

export const addBasicValue = (key, value) => {
    return {type: ADD_BASIC_VALUE, key, value};
};

export const addCompanyValue = (key, value) => {
    return {type: ADD_COMPANY_VALUE, key, value};
};

export const addCompanyContactValue = (key, value) => {
    return {type: ADD_COMPANY_CONTACT_VALUE, key, value};
};

export const addPersonalValue = (prop, key, value) => {
    return {type: ADD_PERSONAL_VALUE, prop, key, value};
};

export const addPersonalContactValue = (prop, key, value) => {
    return {type: ADD_PERSONAL_CONTACT_VALUE, prop, key, value};
};

export const addSpecialValue = (key, value) => {
    return {type: ADD_SPECIAL_VALUE, key, value};
};

export const addSpecialAddressValue = (key, value) => {
    return {type: ADD_SPECIAL_ADDRESS_VALUE, key, value};
};

export const addUploadValue = (key, value) => {
    return {type: ADD_UPLOAD_VALUE, key, value};
};

export const addCommonValue = (key, value) => {
    return {type: ADD_COMMON_VALUE, key, value};
};
export const addOwnerCompanyValue = (key, value, contact) => {
    return {type: ADD_OWNER_COMPANY_VALUE, key, value, contact};
};

export const setSpecialValues = (specialInfo) => {
    return {type: SET_SPECIAL_VALUE, specialInfo};
};

export const setUploadFileModel = (uploadFileModel) => {
    return {type: SET_UPLOAD_FILE_MODEL_VALUE, uploadFileModel};
};

export const addDelete = (value) => {
    return {type: ADD_DELETE, value};
};

const getFormValues =
    (id) => api.get(`quoterequests/${id}`)
        .then(resp => resp.data);

export const fetchFormValues = (id) =>
    (dispatch) => getFormValues(id)
        .then(formValues => {
            //add formValues
            const {insuranceType, insuranceCategory} = formValues.quote;
            if (insuranceType) {
                formValues.specialInfo = formValues[insuranceType] || {};
            }
            if (insuranceType === 'borrow') {
                if (insuranceCategory === 'per' && formValues.specialInfo) {
                    formValues.quote.addPerson2InContract = formValues.specialInfo.personBorrowWith === 'withPerson';
                }
            }
            if (insuranceType === 'vehicle') {
                if (insuranceCategory === 'per' && formValues.specialInfo) {
                    formValues.quote.addPerson2InContract = formValues.specialInfo.secondDriver;
                }
            }
            dispatch(setValues(formValues));
            //change language and translation
            if (formValues.lang === 'zh') {
                dispatch(setLanguage('zh'));
                dispatch(setTranslation(translation_cn));
            }
        })
        .catch((error) => {
            console.log(error.toString());
        });
