import { exception } from "react-ga";

export function getFileExtension(name) {
    if (!name) {
        return undefined;
    }
    let ex = name.toLowerCase().split('.').pop();
    if (['pdf', 'jpg', 'jpeg', 'png'].some(i => i === ex)) {
        return ex;
    }
    return undefined;
}
export const getFileTypeName = fileType => {
    switch (fileType) {
        case "GrayCard":
            return 'Carte grise';
        case "CompanyKbis":
            return 'K-bis';
        case "DriverLicense":
            return 'Permis de conduire';
        case "Identity":
            return 'Pièce d\'identité';
        case "InsuranceRecord":
            return 'Relevé information';
        case "Lease":
            return 'Bail';
        case "OldContract":
            return 'Contrat d\'assurance';
        case "Other":
            return 'Autre';
        case "Photo":
            return 'Photo';
        case "SciKbis":
            return 'K-bis';
        case "Quote":
            return 'Devis';
        case "SignedQuote":
            return 'Devis signé';
        case "Contract":
            return 'Contrat';
        case "Rib":
            return 'Rib';
        case "SCIRib":
            return 'Rib de l\'entreprise';
        case "AmortizationSchedule":
            return 'Tableau d\'amortissement';
        case "LoanContract":
            return 'Contrat de crédit';
        default:
            throw new exception();
    }
}
export const formatIncrementFileName = (baseName, fileNames, extension) => {
    if (!fileNames || fileNames.length === 0 || !fileNames.some(i => i.indexOf(baseName + '-') === 0)) {
        return baseName + '-1.' + extension;
    }
    const indexes = fileNames.filter(i => i.indexOf(baseName + '-') === 0)
        .map(i => {
            let v = i.replace(baseName + '-', '');
            let ex = getFileExtension(v);
            if (ex) {
                v = v.replace('.' + ex, '');
                return parseInt(v, 10);
            } else {
                return 0;
            }
        });
    let max = Math.max(...indexes);
    if (!max) {
        max = 0;
    }
    return baseName + '-' + (max + 1).toString() + '.' + extension;
};
export const getCustomerName = quote => {
    const { company, person } = quote;
    if (company) {
        return company.name.toUpperCase();
    }
    if (person) {
        const { lastName = '', firstName = '' } = person;
        return lastName.toUpperCase() + '-' + firstName;
    }
    return '';
};