import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { boLoginRoute } from '../../constant/route-constant';
import { useStore } from '../../core/store';
const PrivateBORoute = ({ component: Component, isBoAuthenticated, ...rest }) => {
    const {boUser} = useStore();
    return <Route
        {...rest}
        render={props =>
            !!boUser ? (
                <Component {...props} />
            ) : (
                    <Redirect
                        to={{
                            pathname: boLoginRoute,
                            state: { from: window.location.pathname }
                        }}
                    />
                )
        }
    />
};
export default PrivateBORoute;