import React, { Fragment } from 'react';
import Home from './HomeComponent';
import { Route, Switch } from 'react-router-dom';
import Devis from './quotation/StepsComponent';
import Modify from './quotation/ModifyComponent';
import MainHeader from './header/MainHeaderComponent';
const BackOfficeComponent = () => (
    <Fragment>
        <MainHeader></MainHeader>
        <div className="content-area">
            <Switch>
                <Route exact path="/qr" component={Devis} />
                <Route path={`/qr/:name`} component={Modify} />
                <Route exact path="/" component={Home} />
                <Route component={Home} />
            </Switch>
        </div>
        <footer>
            <div className="contact-bar">
                <p>SARL DELIN ASSURANCES,</p>
                <p>Sarl au capital de 8.000 €, siège social : 12 rue de Vivienne 75002 Paris,</p>
                <p>Bureau : 13 rue de Bois Moussay 93240 Stains, N° ORIAS : 17006767,</p>
                <p>Tel : 01 58 69 32 08, 	Fax : 01 58 69 88 90, Port / Wechat : 0666 924 888,</p>
                <p>BNP PARIBAS PARIS CENTRE AFF, BIC : BNPADRPPXXX</p>
                <p>IBAN : FR76-3000-4031-2000-0109-9404-216,</p>
            </div>
            <div>
                <p>&copy; 2019 - DELIN ASSURANCES</p>
            </div>
        </footer>
    </Fragment>
);

export default BackOfficeComponent;