import {connect} from 'react-redux';
import {addSpecialValue, addCompanyValue, addPersonalContactValue} from '../../../store/actions/formValuesActions';

const mapStateToProps = (state) => {
    const specialValues = state.formValues.specialInfo;
    return {
        label: {
            insuranceAddressSame: state.translation.label.specialInsuranceAddressPerSame,
            habitationType: state.translation.label.specialHabitationType,
            insuranceAmount: state.translation.label.specialInsuredAmount,
            insuredSurface: state.translation.label.specialInsuredSurface,
            insuredRoomNumber: state.translation.label.specialInsuredRoomNumber,
            insuredFloor: state.translation.label.specialInsuredFloor,
            insuranceVol: state.translation.label.specialInsuranceVol,
            insuranceVolMontant: state.translation.label.specialInsuranceVolMontant,
            insuranceSecurityEquipment: state.translation.label.specialInsuranceSecurityEquipment,
            insuranceFireSafetyEquipment: state.translation.label.specialInsuranceFireSafetyEquipment,
            insuranceAlertEquipment: state.translation.label.specialInsuranceAlertEquipment,
            leaseNature: state.translation.label.specialLeaseNature,
            sci: state.translation.label.specialSCI,
        },
        options: {
            habitationType: state.translation.options.specialHabitationType,
            insuranceSecurityEquipment: state.translation.options.specialInsuranceSecurityEquipment,
            insuranceFireSafetyEquipment: state.translation.options.specialInsuranceFireSafetyEquipment,
            insuranceAlertEquipment: state.translation.options.specialInsuranceAlertEquipment,
            leaseNature: state.translation.options.specialLeaseNature,
            insuranceAddressSame: state.translation.options.specialInsuranceAddressSame,
        },
        values: {
            insuranceAddressSame: specialValues ? specialValues.insuranceAddressSame : null,
            habitationType: specialValues && specialValues.habitationType ? specialValues.habitationType : null,
            insuranceAmount: specialValues && specialValues.insuranceAmount ? specialValues.insuranceAmount : null,
            insuredSurface: specialValues && specialValues.insuredSurface ? specialValues.insuredSurface : null,
            insuredRoomNumber: specialValues && specialValues.insuredRoomNumber ? specialValues.insuredRoomNumber : null,
            insuredFloor: specialValues && specialValues.insuredFloor ? specialValues.insuredFloor : null,
            insuranceVol: specialValues && specialValues.insuranceVol ? specialValues.insuranceVol : null,
            insuranceVolMontant: specialValues && specialValues.insuranceVolMontant ? specialValues.insuranceVolMontant : null,
            insuranceSecurityEquipment: specialValues && specialValues.insuranceSecurityEquipment ? specialValues.insuranceSecurityEquipment : null,
            insuranceFireSafetyEquipment: specialValues && specialValues.insuranceFireSafetyEquipment ? specialValues.insuranceFireSafetyEquipment : null,
            insuranceAlertEquipment: specialValues && specialValues.insuranceAlertEquipment ? specialValues.insuranceAlertEquipment : null,
            leaseNature: specialValues && specialValues.leaseNature ? specialValues.leaseNature : null,
            sci: specialValues && specialValues.sci ? specialValues.sci : null,
        }
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addValue: (key, value) => { dispatch(addSpecialValue(key, value)) },
        addCompanyValue: (key, value) => { dispatch(addCompanyValue(key, value)) },
        addContactValue: (key, subkey, value) => { dispatch(addPersonalContactValue(key, subkey, value)) },
    };
};

const HabitationController =
    (HabitationComponent) => {return connect(mapStateToProps, mapDispatchToProps)(HabitationComponent)};

export default HabitationController;
