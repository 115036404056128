export const radioVerticalStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
    marginBottom: '10px'
};
export const formItemStyle = {
    labelCol: {
        md: { span: 24 },
        lg: { span: 14 },
    },
    wrapperCol: {
        md: { span: 24 },
        lg: { span: 10 },
    },
};
export const formItemCenterStyle = {
    labelCol: {
        md: { span: 24 },
        lg: { span: 12 },
    },
    wrapperCol: {
        md: { span: 24 },
        lg: { span: 12 },
    },
};
export const formItemEntireRowStyle = {
    labelCol: {
        md: { span: 24 },
        lg: { span: 7 },
    },
    wrapperCol: {
        md: { span: 24 },
        lg: { span: 17 },
    },
};
export const getFormItemStyle = (props) => {
    if (props.center) {
        return {
            labelCol: {
               span: 24
            },
            wrapperCol: {
               span: 24
            },
        };
    }
    if (props.left === 0) {
        return {
            labelCol: {
                span: 0
            },
            wrapperCol: {
                span: 24
            },
        };
    }
    if (props.left) {
        return {
            labelCol: {
                md: { span: 24 },
                lg: { span: props.left },
            },
            wrapperCol: {
                md: { span: 24 },
                lg: { span: props.right ? props.right : 24 - props.left },
            },
        };
    } 
    return {
        labelCol: {
            md: { span: 24 },
            lg: { span: 12 },
        },
        wrapperCol: {
            md: { span: 24 },
            lg: { span: 12 },
        },
    };
}
export const colXL6 =  {
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 12 },
    xl: { span: 6 },
};
export const colXL8 =  {
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 12 },
    xl: { span: 8 },
};
export const colXL16 =  {
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 12 },
    xl: { span: 16 },
};
export const colXL18 =  {
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 12 },
    xl: { span: 18 },
};
export const colXL12 =  {
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 12 },
    xl: { span: 12 },
};
export const colXL14 =  {
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 12 },
    xl: { span: 14 },
};
export const colXL24 =  {
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 12 },
    xl: { span: 24 },
};
export const colXL20 =  {
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 12 },
    xl: { span: 20 },
};
export const colXL10 =  {
    sm: { span: 24 },
    md: { span: 24 },
    lg: { span: 12 },
    xl: { span: 10 },
};
export const colItemEntireRowStyle =  {
    md: { span: 24 },
    lg: { span: 24 },
};
export const jumpInsuranceTypes = [
    'localPro',
    'habitation',
    'transport',
    'responsible',
];
export const excludePersonalExtraInfo = [
    'localPro',
    'transport',
    'responsible',
];
export const excludeInsuranceCategory = [
    'borrow',
    'life',
    'vehicle'
];
export const requiredSexInsuranceType = [
    'borrow',
    'life',
    'vehicle'
];
export const requiredBirthDateInsuranceType = [
    'borrow',
    'life',
    'vehicle'
];
export const carUsingWork = [
    'pro',
    'perPlus',
    'both'
];
export const hasCompanyKbis = [
    'localPro',
    'responsible',
    'transport'
];
export const hasKbis = [
    'localPro',
    'responsible',
    'transport'
];
export const hasSCIKbis = [
    'habitation',
    'borrow'
];
export const showInsuranceCategory = [
    'vehicle'
];
export const perInsuranceCategory = [
    'habitation',
    'life',
    'borrow'
];
export const proInsuranceCategory = [
    'localPro',
    'responsible',
    'transport'
];


