import React, { Fragment } from 'react';
import Text from '../../../form/FieldTemplate/TextComponent';
import generateController from '../../controller/Content/InsuranceAddressController';
import { Col } from 'antd';
import { colXL6, colXL20, colXL12 } from '../../../../constant/DevisContant';
import CityAutoComplete from '../../../form/FieldTemplate/CityAutoCompleteComponent';
import PostalCodeText from '../../../form/FieldTemplate/PostalCodeComponent';
const InsuranceAddress = (props) => (
    <Fragment>
        <Col {...colXL12}>
            <Text
                form={props.form}
                name="specialAddress1"
                label={props.label.address}
                value={props.values.address1}
                onBlur={(value) => props.addValue('address1', value)}
                required
                left={5}
                disabled={props.disabled}
                maxLength={100}
            />
        </Col>
        <Col {...colXL12}>
            <Text
                form={props.form}
                name="specialAddress2"
                label={''}
                value={props.values.address2}
                onBlur={(value) => props.addValue('address2', value)}
                left={0}
                disabled={props.disabled}
                maxLength={100}
                colon={false}
            />
        </Col>
        <Col {...colXL20}>
            <Text
                form={props.form}
                name="specialAddressInfo"
                label={props.label.addressInfo}
                value={props.values.address3}
                onBlur={(value) => props.addValue('address3', value)}
                disabled={props.disabled}
                left={3}
            />
        </Col>
        <Col {...colXL6}>
            <PostalCodeText
                form={props.form}
                name="specialPostalCode"
                label={props.label.postalCode}
                value={props.values.postalCode}
                updateCity={(value) => {
                    props.addValue('city', value);
                }}
                updateCP={(value) => props.addValue('postalCode', value)}
                disabled={props.disabled}
                left={10}
            />
        </Col>
        <Col {...colXL6}>
            <CityAutoComplete
                form={props.form}
                name="specialCity"
                label={props.label.city}
                value={props.values.city}
                updateCity={(value) => {
                    props.addValue('city', value);
                }}
                updateCP={(value) => props.addValue('postalCode', value)}
                disabled={props.disabled}
                required
                left={10}
            />
        </Col>
        <Col {...colXL6}>
            <Text
                form={props.form}
                name="specialCountry"
                label={props.label.country}
                value={props.values.country}
                required
                disabled
                left={10}
            />
        </Col>
    </Fragment>
);

export default generateController(InsuranceAddress);
