import { SET_BOUSER } from '../actions/userAction';

const boUser = (state = null, action) => {
    switch (action.type) {
        case SET_BOUSER:
            return action.user;
        default:
            return state;
    }
};

export default boUser;