import {connect} from 'react-redux';
import {addBasicValue, addSpecialValue} from '../../../store/actions/formValuesActions';

const mapStateToProps = (state) => {
    let {quote} = state.formValues;
    const specialValues = state.formValues.specialInfo;
    return {
        label: {
            carViolation: state.translation.label.specialCarViolation,
            carViolationDate: state.translation.label.specialCarViolationDate,
        },
        values: {
            insuranceType: quote.insuranceType || null,
            comment: quote.comment || null,
            carViolation: specialValues && specialValues.carViolation ? specialValues.carViolation : null,
            carViolationDate: specialValues && specialValues.carViolationDate ? specialValues.carViolationDate : null,
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return { 
        addBasicValue: (key, value) => { dispatch(addBasicValue(key, value)) },
        addSpecialValue: (key, value) => { dispatch(addSpecialValue(key, value)) },
    };
};

const CommentController =
    (CommentComponenet) => {return connect(mapStateToProps, mapDispatchToProps)(CommentComponenet)};

export default CommentController;
