export const translation_fr = {
    title: {
        general: "Info générale",
        insuranceType: "Type d'assurance",
        company: "Info d'entreprise",
        personal: "Info personnellle",
        upload: "Télécharger des fichiers",
        comment: "Info supplémentaire",
        detail: "Détails",
        code: 'Code',
        createdDate: 'Date de création',
        email: 'Email',
        name: 'Nom',
        categoryType: 'Type',
        jobTitle: 'Fonction'
    },
    label: {
        other: "Autre",
        insurance: "Assurance",
        contractTypeQuestion: "Vous souhaitez un devis pour",
        contractType: "Type de contrat",
        oldContractNumberQuestion: "Quel est le numéro de contrat de votre assureur actuel",
        oldContractNumber: "Numéro de contrat",
        oldInsuranceCompanyQuestion: "Quel est le nom de votre assureur actuel",
        oldInsuranceCompany: "Nom du assureur actuel",
        insuranceType: "Que souhaitez vous assurer?",
        insuranceCategoryQuestion: "Vous souhaitez une assurance pour",
        insuranceCategory: "Catégorie d'assurance",
        email: "Email",
        date: "Date",
        companyName: "Raison Sociale",
        companyLegalStatus: "Statut juridique",
        companySiren: "SIREN (RCS)",
        companySiret: "SIRET",
        NAF: "Code activité(NAF)",
        companyAddress: "Adresse",
        companyAddressInfo: "Complément",
        companyPostalCode: "Code postal",
        companyCity: "Ville",
        companyCountry: "Pays",
        companyTel: "Téléphone",
        companyMobile: "Mobile",
        fax: "Fax",
        personalCivility: "Civilité",
        personalLastName: "Nom",
        personalMaidenName: "Nom de jeune fille",
        personalFirstName: "Prénom",
        personalFullName: "姓名（中文)",
        personalSex: "Sexe",
        personalAddress: "Adresse",
        personalAddressInfo: "Complément",
        personalPostalCode: "Code postal",
        personalCity: "Ville",
        personalCountry: "Pays",
        personalTel: "Téléphone",
        personalFax: "Fax",
        personalMobile: "Mobile",
        personalBirth: "Date de naissance",
        onlyLetters: "uniquement pinyin ou français",
        specialLocalType: "Type",
        specialInsuredAmount: "Montant assuré",
        specialEquipmentAmount: "L'équipement",
        specialGlassAmount: "Bris de glace",
        specialContentAmount: "Contenu",
        specialITAmount: "Montant d'équipement informatique",
        specialInsuredSurface: "Surface assurée",
        specialInsuredFloor: "Nombre d'étage",
        specialCAn1: "CA N-1",
        specialLoanAmount: "Montant du prêt",
        specialLoanInterest: "Intérêt sur prêt",
        specialLoanDate: "Echéance de prêt",
        specialBank: "Banque",
        specialIBAN: "Compte bancaire IBAN",
        specialCarBrand: "Marque de voiture",
        specialCarRef: "Référence de voiture",
        specialCarPower: "Puissance fiscale",
        specialCarKm: "Kilométrage de voitures",
        specialCarKmAnnual: "Kilométrage estimé annuel",
        specialGrayCardDate: "La date de la carte grise",
        specialCarImmaDate: "La date de 1er immatriculation",
        specialDrivingLicenseDate: "Date de permis de conduire",
        specialDrivingLicenseValidDate: "Date de validité Permis de conduire",
        specialCarInsuranceType: "Type",
        specialCarUsing: "Utilisation de la voiture",
        specialWorkAddress: "Adresse de travail",
        specialWorkPostalCode: "Code postal de travail",
        specialWorkCity: "Ville de travail",
        specialBMPoint: "Bonus / Malus",
        specialGarageType: "Garage",
        specialCarlicenseNumber: "Combien de permis de conduire",
        specialCarNumber: "Combien de voitures",
        specialSecondDriver: "Conducteur secondaire",
        specialSpouseLastname: "Nom du conjoint",
        specialSpouseFirstname: "Prénom du conjoint",
        specialMatrimonialRegime: "Régime matrimoniale",
        specialLifeInsuranceType: "Type",
        specialCarModel: "Modèle de voiture",
        specialInsuranceVol: "Assurance Vol",
        specialVol: "Vol",
        specialInsuranceVolMontant: "Montant",
        specialInsuranceResponsible: "Responsabilité civile",
        specialEmployeeNumber: "Nombre d'employés",
        specialInsuranceLoss: "Perte d'exploitation",
        specialInsuranceSecurityEquipment: "Équipement de sécurité fermeture",
        specialInsuranceFireSafetyEquipment: "Équipement de sécurité incendie",
        specialInsuranceAlertEquipment: "Équipement de sécurité",
        specialElectricEquipmentCheck: "Vérification annuelle de l'équipement électrique",
        specialFireSafetyEquipmentCheck: "Vérification annuelle du matériel de prévention des incendies",
        specialLeaseNature: "La nature du bail",
        specialInsuredRoomNumber: "Nombre de pièces",
        specialRCSNum: "N° RCS",
        specialCAN: "Prévisionnel CA N",
        specialActivityCode: "Code activité",
        specialHabitationType: "Type",
        uploadCompanyKbis: "Société K-bis",
        uploadSCIKbis: "SCI K-BIS",
        uploadIdentity: "Pièce d'identité(recto et verso) ",
        uploadIdentityBack: "Pièce d'identité(verso)",
        uploadGrayCard: "Carte grise(recto et verso)",
        uploadGrayCardBack: "Carte grise(verso)",
        uploadDriverLicense: "Permis de conduire(recto et verso)",
        uploadDriverLicenseBack: "Permis de conduire(verso)",
        uploadInsuranceRecord: "Relevé information",
        uploadOldContract: "Contrat d'assurance existant",
        uploadRib: "RIB",
        uploadSCIRib: "RIB de l'entreprise",
        uploadAmortizationSchedule: "Tableau d'amortissement",
        uploadLoanContract: "Copie de contrat de crédit",
        specialSCI: "SCI",
        specialCarViolationDate: "Date de violation",
        specialCarViolation: "Avez vous eu des sinistres responsables pendant 3 ans",
        specialInsuranceAddressPerSame: "L'adresse est identique à votre adresse à domicile",
        specialInsuranceAddressProSame: "L'adresse est identique à l'adresse de votre société",
        addPerson2InContract: "Co-souscription",
        companyOwnedHouse: "Ce bien appartient à une société (SCI, SARL ou autre)",
        personBorrowWith: "Vous empruntez",
        allCompanyDrivers: "Assurer toutes les personnes de l'entreprise",
        contactPerson: "Personne de contact",
        coSubscriber: "Co-subscripteur",
        familySituation: "Situation familiale",
        coBorrower: "Co-emprunteur",
        secondDriverInsurance: "Assurer un deuxième conducteur",
        mainDriver: "Conducteur principal",
        profession: "Profession",
        howGetDriverLicence: "Comment avez-vous obtenu votre permis de conduire",
        hasYouBeenInTheSituation: "Avez-vous déjà été dans l'une de ces situations",
        in3Years: "Dans les 3 dernières années",
        in5Years: "Dans les 5 dernières années",
        driverLicenceCancelled: "Annulation ou suspension permis dans les 3 dernières années",
        lossNumIn3Years: "Nombre de sinistre(s) déclarés au cours des 36 derniers mois",
        grossMargin: "Marge brut",
        uploadOther: "Autre",
        uploadLease: "Bail",
        uploadPhotoOneMonth: "Photo moins 1 mois",
        day: "Jour",
        month: "Mois",
        year: "Année",
        duration: "Durée",
        borrowerRate1: "Quotité d'emprunteur",
        borrowerRate2: "Quotité de co-emprunteur",
        oldContractDueDate: "Date d'échéance",
        cancellationLetter: "Lettre de résiliation",
        expectedStartDateQuestion: "A quelle date souhaitez-vous le début des garanties",
        expectedStartDate: "Début des garanties",
        acceptedPaymentMethodsQuestion: "Quels modes de paiement acceptez-vous",
        acceptedPaymentMethods: "Modes de paiement",
        upload: "Télécharger",
        carRegistrationNumber: "Numéro d'immatriculation",
        gerant: "Gérant",
        houseOwner: "Propriétaire du bien",
        localContentAmount: "Montant du contenu dans le magasin",
        latestVerificationDate: "Date dernière vérification",
        smoker: "Fumeur",
        insuranceProportion: "Répartition Assurances",
        creditRedemption: "Rachat crédit",
        newDuration: "Nouvelle durée",
        redemptionAmount: "Montant rachat",
        loanAmountBeforeRedemption: "Capital restant dû",
        loanDurationBeforeRedemption: "Nombre de mois restant dû",
        currentInsuranceCompany: "Nom de l'assurance actuelle",
        insuranceType: "Type d'assurance",
        use: "Usage",
        energy: "Energie",
        assistant0: "Assistance 0km",
        juridicProtection: "Protection juridique"
    },
    options: {
        contractType: {
            new: "Un nouveau contrat",
            change: "Un changement de contrat"
        },
        insuranceType: {
            localPro: "Assurance local professionnel",
            habitation: "Assurance habitation",
            responsible: "Assurance responsabilité civile",
            transport: "Assurance transport de marchandises",
            borrow: "Assurance emprunt",
            life: "Assurance vie",
            vehicle: "Assurance automobile"
        },
        insuranceCategory: {
            pro: "Une entreprise",
            per: "Un particulier"
        },
        companyLegalStatus: {
            sarl: "SARL",
            sa: "SA",
            eurl: "EURL",
            groupe: "Groupe",
            sci: "SCI",
            other: "Autre",
        },
        realEstateCompanyLegalStatus: {
            sarl: "SARL",
            sci: "SCI",
            other: "Autre",
        },
        personalCivility: {  
            miss: "Mademoiselle",
            ms: "Madame",
            mr: "Monsieur"
        },
        personalSex: {
            m: "Homme",
            f: "Femme"
        },
        specialLocalType: {
            warehouse: "Entrepôt",
            shop: "Magasin",
            studio: "Atelier",
            restaurant: "Restaurant",
            bar: "Bar, TABAC",
            other: "Autre"
        },
        specialCarInsuranceType: {
            thirdParty: "Tiers",
            thirdPartyAndVol: "Tiers +",
            allRisk: "Tous risques",
        },
        specialCarUsing: {
            pro: "Professionnelle",
            per: "Personnelle",
            perPlus: "Personnelle (plus trajet au travailler)",
            both: "Professionnelle et privé",
        },
        specialGarageType: {
            public: "Parking public",
            private: "Garage privé",
            box: "Garage privé fermé",
        },
        specialMatrimonialRegime: {
            mariageShare: "Communauté légale réduite aux acquêts",
            seperate: "Séparation de biens",
            allShare: "Communauté universelle",
            partShare: "Participation aux acquêts",
        },
        specialLifeInsuranceType: {
            mutual: "Mutuelle",
            foresight: "Prévoyance",
            keyman: "Homme-clés",
            life: "Assurance-vie",
            investments: "Placements",
        },
        specialInsuranceSecurityEquipment: {
            glass: "Porte en verre",
            curtain: "Rideau métallique",
            metal: "Porte métallique",
            wood: "Porte en bois"
            // armored: "Blindée",
        },
        specialInsuranceFireSafetyEquipment: {
            // smokeDetector: "Détecteur(s) de fumée",
            automaticExtinguisher: "Sprinkler",
            armedFireFaucet: "RIA",
            smokeEvacuationSystem: "Désenfumage",
            fireExtinguisher: "Extincteur",
        },
        specialInsuranceAlertEquipment: {
            none: "Aucune",
            alarm: "Alarme",
            surveillance: "Vidéo-surveillance",
            agency: "Société-surveillance",
        },
        specialLeaseNature: {
            ownerOccupied: "Propriétaire occupant",
            ownerNonOccupied: "Propriétaire non occupant",
            lodger: "Locateur",
        },
        specialHabitationType: {
            apartment: "Appartement",
            house: "Maison",
            other: "Autre",
        },
        specialInsuranceAddressSame: {
            true: "Oui",
            false: "Non",
        },
        monthAbbr: ['janv', 'févr', 'mars', 'avr', 'mai', 'juin', 'juill', 'août', 'sept', 'oct', 'nov', 'déc'],
        personBorrowWith: {
            self: "Seul",
            withPerson: "Avec un co-comprunteur",
            // withCompany: "Au travers d'une société (SCI, SARL, ou autre)"
        },
        familySituation: {
            single: "Célibataire",
            married: "Marié(e)",
            pacs: "Pacs",
            maritalLife: "Vie maritale",
            separate: "Séparé(e)",
            divorced: "Divorcé(e)",
            widower: "Veuf(ve)"
        },
        localProLeaseNature: {
            ownerOccupied: "Propriétaire occupant",
            ownerNonOccupied: "Propriétaire non occupant",
            lodger: "Locateur",
            lodgerOnBehalfOfOwner: "Locateur pour compte de propriétaire"
        },
        paymentMethod: {
            check: "Chèque",
            bankCard: "Carte bancaire",
            monthlyAutoBankPayment: "Prélèvement mensuel",
            quarterlyAutoBankPayment: "Prélèvement trimestriel",
            yearlyAutoBankPayment: "Prélèvement annuel",
        },
        jobTitleM: {
            manager: "Directeur",
            clientAdvisor: "Conseiller clientèle",
            clientAdvisorAssistant: "Assistant conseiller clientèle"
        },
        jobTitleF: {
            manager: "Directrice",
            clientAdvisor: "Conseillère clientèle",
            clientAdvisorAssistant: "Assistante Conseillère Clientèle"
        },
        jobTitleAbbr: {
            manager: "Directeur(trice)",
            clientAdvisor: "Conseiller(e)",
            clientAdvisorAssistant: "Assistant(e)"
        },
        jobTitleMAbbr: {
            manager: "Directeur",
            clientAdvisor: "Conseiller",
            clientAdvisorAssistant: "Assistant"
        },
        jobTitleFAbbr: {
            manager: "Directeur(trice)",
            clientAdvisor: "Conseillère",
            clientAdvisorAssistant: "Assistante"
        },
        loanType: {
            realEstate: "Crédit immobilier",
            car: "Crédit vehicule",
            consumption: "Crédit vehicule",
            pro: "Crédit professionnel",
            other: "Autre"
        },
        realEstateLoanType: {
            principleResidence: "Habitation R principale",
            rentalInvestment: "Habitation Invest locatif",
            professionalPremises: "Locaux professionnels"
        },
        energyType: {
            fuel: "Essence",
            diesel: "Diesel",
            electric: "Electrique",
            hybrid: "Hybrides"
        }
    },
    messageError: {
        empty: "Ce champ est obligatoire !",
        format: "Format non valid !",
        uploadMessage: "Erreur de téléchargement",
        imageType: "Vous ne pouvez télécharger que des fichiers JPG, PNG, DOC et PDF!",
        imageSize: "Le fichier doit être inférieur à 6 Mo!",
        password: "Le mot de passe doit être de 8 caractères minimum et contenir au moins 1 lettre et 1 chiffre"
    },
    button: {
        next: "Suivant",
        prev: "Précédent",
        save: "Sauvegarder",
        finish: "Terminer",
        goBackHome: "Accueil",
        createNewQuotation: "Créer un devis",
        login: "Se connecter",
        edit: "Modifier",
        cancel: "Annuler"
    },
    values: {
        country: "France",
    },
    home: {
        content: {
            name: "DELIN ASSURANCES",
            intro: "est un cabinet de courtage indépendant, collabore avec plus une vingtaine de compagnie d’assurance, nationale et internationalement connues, ayant 25 ans d’expérience, qui a sélectionné une offre de produits d'assurances destinées aux professionnels et aux particuliers:",
            list: [
                "Assurance locaux professionnels (Magasin, Restaurent, Entrepôt, Atelier, Bar Tabac, etc)",
                "Assurance locaux personnels (Appartement, Pavillon, Garages, etc)",
                "Assurance responsabilité civile professionnelle, Assurance perte d’exploitations.",
                "Assurance marchandises transportées",
                "Assurance auto moto fourgon camion",
                "Assurances emprunteurs",
                "Assurances mutuelles santé Prévoyance",
            ],
            text: [
                "Contactez nous pour bénéficier des meilleures offres et faire des économies en groupant vos contrats.",
                "Nous vous accompagnons dans toutes vos démarches de changement d'assurance et dans l'optimisation des vos garanties.",
                "Contactez-nous par téléphone au 01 58 69 32 08, fax : 01 58 69 88 90",
            ],
            textMail: "Ou par email",
            mail: "delinassurances@gmail.com",
        },
        button: "Faire un devis",
    },
    error: {
        message: "Erreur",
        description: "Il y a une erreur lors de la création de votre demande, veuillez réessayer!",
        descriptionModify: "Il y a une erreur lors de la modification de votre demande, veuillez réessayer!",
        generalError: "Une erreur s'est produite, veuillez réessayer plus tard"
    },
    success: {
        message: "Success",
        description: "Félicitations, votre demande a été créé! Vous recevrez un email de confirmation. Dans cet email, vous aurez un lien pour modifier votre demande. Merci beaucoup!",
        descriptionModify: "Félicitations, votre demande a été modifié!",
    },
    user: {
        email: "Email",
        password: "Mot de passe",
        loginError: "Votre mot de passe ou votre email semble erroné.",
        logout: "Déconnexion",
        profile: "Profil",
        newPassword: "Nouveau mot de passe"
    },
};
