import { connect } from 'react-redux';
import { addSpecialAddressValue } from '../../../store/actions/formValuesActions';
import { fetchPostalCode } from '../../../store/actions/postalCodeAction';

const mapStateToProps = (state) => {
    const specialInfo = state.formValues.specialInfo;
    return {
        title: state.translation.title.company,
        label: {
            address: state.translation.label.companyAddress,
            addressInfo: state.translation.label.companyAddressInfo,
            postalCode: state.translation.label.companyPostalCode,
            city: state.translation.label.companyCity,
            country: state.translation.label.companyCountry,
        },
        values: {
            address1: specialInfo && specialInfo.address ? specialInfo.address.address1 : null,
            address2: specialInfo && specialInfo.address ? specialInfo.address.address2 : null,
            address3: specialInfo && specialInfo.address ? specialInfo.address.address3 : null,
            postalCode: specialInfo && specialInfo.address ? specialInfo.address.postalCode : null,
            city: specialInfo && specialInfo.address ? specialInfo.address.city : null,
            country: state.translation.values.country,
        },
        postalCode: state.postalCode,
        city: state.city,
    };
};

const mapDispatchToProps = (dispatch) => {
    return { 
        addValue: (key, value) => { dispatch(addSpecialAddressValue(key, value)) },
        getPostalCode: (value) => { dispatch(fetchPostalCode(value)) },
    };
};

const InsuranceAddressController =
    (InsuranceAddressComponent) => {return connect(mapStateToProps, mapDispatchToProps)(InsuranceAddressComponent)};

export default InsuranceAddressController;
