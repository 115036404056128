import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { Controller } from 'react-hook-form';
// A tester
export default function CheckboxInput({ register, name, label, errors, validator, model, control }) {
    const err = errors && errors[name] && true;
    const defaultValue = (model && model[name]) || false;
    return <FormControlLabel
        control={
            <Controller
                as={
                    <Checkbox />
                }
                control={control}
                name={name}
                rules={validator}
                defaultValue={defaultValue}
            />

        }
        label={label}
    />
}
