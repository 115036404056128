import http from '../core/http';
import { nodeApiURL } from '../constant/http-constant';
const serviceApi = {
    checkToken: async () => await http.get('service/checktoken'),
    login: async model => await http.post('service/login', model),
    changePassword: async (user) => {
        return await http.post('service/changepassword', user);
    },
    getServiceContracts: async () => await http.get('service/servicecontracts'),
    createTravelInsurance: async model => await http.post('service/travelinsurances', model),
    updateTravelInsurance: async (id, model) => await http.patch('service/travelinsurances/' + id, model),
    deleteTravelInsurance: async (id) => await http.delete('service/travelinsurances/' + id),
    searchTravalInsurances: async query => await http.get('service/travelinsurances?' + query),
    report: {
        travelinsurances: async (query) => await http.get(nodeApiURL + 'service/report/travelinsurancesummary?' + query)
    }
}
export default serviceApi;
