import React, { Fragment } from 'react';
import TextInput from './fields/text-input';
import Grid from '@material-ui/core/Grid';
import PasswordInput from './fields/password-input';
import Address from './fields/address';
import Telephone from './fields/tel-input';
import {NumberInput} from './fields/number-input';
import Date from './fields/date-ddl-input';
import Select from './fields/select';
import Email from './fields/email-input';
import Checkbox from './fields/checkbox';
function DynamicForm(props) {
    const { fields } = props;
    return (
        <Fragment>
            {fields.map(f => {
                const { input, col = 12, ...p } = f;
                switch (input) {
                    case 'text':
                        return <Grid item xs={12} md={col} key={f.name}>
                            <TextInput {...p}></TextInput>
                        </Grid>;
                    case 'email':
                        return <Grid item xs={12} md={col} key={f.name}>
                            <Email {...p}></Email>
                        </Grid>;
                    case 'password':
                        return <Grid item xs={12} md={col} key={f.name}>
                            <PasswordInput {...p}></PasswordInput>
                        </Grid>;
                    case 'address':
                        return <Address {...p} col={col} key={f.name}></Address>;
                    case 'tel':
                        return <Grid item xs={12} md={col} key={f.name}>
                            <Telephone {...p}></Telephone>
                        </Grid>;
                    case 'number':
                        return <Grid item xs={12} md={col} key={f.name}>
                            <NumberInput {...p}></NumberInput>
                        </Grid>;
                    case 'uint':
                        return <Grid item xs={12} md={col} key={f.name}>
                            <NumberInput {...p} numberProps={{allowNegative: false, allowLeadingZeros: true, decimalScale: 0, type: 'tel'}}></NumberInput>
                        </Grid>;
                    case 'currency':
                        return <Grid item xs={12} md={col} key={f.name}>
                            <NumberInput {...p} numberProps={{decimalSeparator: ',', decimalScale: 2, allowNegative: false, symbol: '€', thousandSeparator: ' '}}></NumberInput>
                        </Grid>;
                    case 'date':
                        return <Grid item xs={12} md={col} key={f.name}>
                            <Date {...p}></Date>
                        </Grid>;
                    case 'select':
                        return <Grid item xs={12} md={col} key={f.name}>
                            <Select {...p}></Select>
                        </Grid>;
                    case 'checkbox':
                        return <Grid item xs={12} md={col} key={f.name}>
                            <Checkbox {...p}></Checkbox>
                        </Grid>;
                    default:
                        return null;
                }
            })}
        </Fragment>
    );
}
export default DynamicForm;
