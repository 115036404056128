import {connect} from 'react-redux';
import {addSpecialValue, addCompanyValue, addPersonalContactValue} from '../../../store/actions/formValuesActions';
import {fetchBanks} from '../../../store/actions/banksAction';

const mapStateToProps = (state) => {
    const specialValues = state.formValues.specialInfo || {};
    return {
        label: {
            loanAmount: state.translation.label.specialLoanAmount,
            loanInterest: state.translation.label.specialLoanInterest,
            loanDate: state.translation.label.specialLoanDate,
            duration: state.translation.label.duration,
            bank: state.translation.label.specialBank,
            iban: state.translation.label.specialIBAN,
            month: state.translation.label.month,
            creditRedemption: state.translation.label.creditRedemption,
            newDuration: state.translation.label.newDuration,
            redemptionAmount: state.translation.label.redemptionAmount,
            loanAmountBeforeRedemption: state.translation.label.loanAmountBeforeRedemption,
            loanDurationBeforeRedemption: state.translation.label.loanDurationBeforeRedemption,
            currentInsuranceCompany: state.translation.label.currentInsuranceCompany,
            insuranceType: state.translation.label.insuranceType,
            use: state.translation.label.use,
            currentInsuranceCompany: state.translation.label.currentInsuranceCompany,

        },
        options: {
            loanType: state.translation.options.loanType,
            realEstateLoanType: state.translation.options.realEstateLoanType,
        },
        values: {
            loanType: specialValues.loanType,
            loanTypeOther: specialValues.loanTypeOther,
            realEstateLoanType: specialValues.realEstateLoanType,
            loanAmount: specialValues.loanAmount,
            loanDuration: specialValues.loanDuration,
            loanInterest: specialValues.loanInterest,
            loanDate: specialValues.loanDate,
            bank: specialValues.bank,
            creditRedemption: specialValues.creditRedemption,
            newLoanAmount: specialValues.newLoanAmount,
            newLoanInterest: specialValues.newLoanInterest,
            newLoanDate: specialValues.newLoanDate,
            newLoanDuration: specialValues.newLoanDuration,
            loanAmountBeforeRedemption: specialValues.loanAmountBeforeRedemption,
            loanDurationBeforeRedemption: specialValues.loanDurationBeforeRedemption,
            newBank: specialValues.newBank,
            insuranceCompany: specialValues.insuranceCompany,
        },
        banks: state.banks,
        companies: state.companies,
    };
};

const mapDispatchToProps = (dispatch) => {
    return { 
        addValue: (key, value) => { dispatch(addSpecialValue(key, value)) },
        addCompanyValue: (key, value) => { dispatch(addCompanyValue(key, value)) },
        addContactValue: (key, subkey, value) => { dispatch(addPersonalContactValue(key, subkey, value)) },
        getBanks: () => { dispatch(fetchBanks()) },
    };
};

const BorrowController =
    (BorrowComponent) => {return connect(mapStateToProps, mapDispatchToProps)(BorrowComponent)};

export default BorrowController;
