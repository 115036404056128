import { Checkbox, Form } from 'antd';
import React from 'react';

const FormItem = Form.Item;

function CustomSwitch(props) {
    const {getFieldDecorator} = props.form;
    const {className, label} = props; 

    return (
        <FormItem
        className={className}
        >
            {getFieldDecorator(props.name, {
                valuePropName: 'checked',
                initialValue: props.value,
            })(
                <Checkbox  onChange={(e)=>props.onChange(e.target.checked)} disabled={props.disabled} >{label}</Checkbox>
            )}         
        </FormItem>
    );
}

export default CustomSwitch;
