import React from 'react';
import { Controller } from "react-hook-form";
import { FormControl, Select as SelectControl, MenuItem, InputLabel } from '@material-ui/core';
export default function Select({ errors, control, name, label, model, validator, options }) {
    const err = errors && errors[name] && true;
    const defaultValue = (model && model[name]) || '';
    return <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Controller
            as={
                <SelectControl error={err}
                >
                    {options.map((op, index) => <MenuItem key={index} value={op.value}>{op.label}</MenuItem>)}
                </SelectControl>
            }
            control={control}
            name={name}
            rules={validator}
            defaultValue={defaultValue}
        />
    </FormControl>
}
