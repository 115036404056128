import { setGlobalSpin, setGlobalConfirm } from './action';
import { useStoreDispatch } from './store';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export function useLoader() {
    const dispatch = useStoreDispatch();
    return (v) => dispatch(setGlobalSpin(v));
}

export function useToast() {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    return (key, type='default') => enqueueSnackbar(t(key), {variant: type, anchorOrigin: { vertical: 'top', horizontal: 'right' }});
}

export function useConfirm() {
    const dispatch = useStoreDispatch();
    return () => new Promise((resolve, reject) => {
        dispatch(setGlobalConfirm({resolve}));
    });
}