import React, { Fragment } from 'react';
import Radio from '../../../form/FieldTemplate/RadioComponent';
import Text from '../../../form/FieldTemplate/TextComponent';
import CustomSwitch from '../../../form/FieldTemplate/SwitchComponent';
import generateController from '../../controller/SpecialFields/HabitationController';
import InsuranceAddress from '../Content/InsuranceAddress';
import Amount from '../../../form/FieldTemplate/AmountComponent';
import Select from '../../../form/FieldTemplate/SelectComponent';
import { Row, Col, Divider } from 'antd';
import { colXL6} from '../../../../constant/DevisContant';

class Habitation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            insuranceAddressSame: this.props.values.insuranceAddressSame,
        };
    }
    componentDidMount() {
    }
    render() {
        return (
            <Fragment>
                <Row>
                    <Col span={24}>
                        <Radio
                            form={this.props.form}
                            name="specialInsuranceAddressSame"
                            label={this.props.label.insuranceAddressSame}
                            options={this.props.options.insuranceAddressSame}
                            value={this.state.insuranceAddressSame ? 'true' : 'false'}
                            onChange={(value) => {
                                this.props.addValue('insuranceAddressSame', value === 'true');
                                this.setState({
                                    insuranceAddressSame: value === 'true',
                                });
                            }}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    {!this.state.insuranceAddressSame &&
                        <InsuranceAddress
                            form={this.props.form}
                            disabled={this.props.disabled}
                        />
                    }
                    <Divider></Divider>
                    <Col {...colXL6}>
                        <Select
                            form={this.props.form}
                            name="specialHabitationType"
                            label={this.props.label.habitationType}
                            options={this.props.options.habitationType}
                            value={this.props.values.habitationType}
                            onChange={(value) => this.props.addValue('habitationType', value)}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL6}>
                        <Amount
                            form={this.props.form}
                            name="specialInsuredAmount"
                            label={this.props.label.insuranceAmount}
                            value={this.props.values.insuranceAmount}
                            onBlur={(value) => this.props.addValue('insuranceAmount', value)}
                            disabled={this.props.disabled}
                            required
                        />
                    </Col>
                    <Col {...colXL6}>
                        <Text
                            form={this.props.form}
                            name="specialInsuredSurface"
                            label={this.props.label.insuredSurface}
                            value={this.props.values.insuredSurface}
                            onBlur={(value) => this.props.addValue('insuredSurface', value)}
                            required
                            number
                            surface
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL6}>
                        <Text
                            form={this.props.form}
                            name="specialInsuredRoomNumber"
                            label={this.props.label.insuredRoomNumber}
                            value={this.props.values.insuredRoomNumber}
                            onBlur={(value) => this.props.addValue('insuredRoomNumber', value)}
                            required
                            number
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL6}>
                        <Text
                            form={this.props.form}
                            name="specialInsuredFloor"
                            label={this.props.label.insuredFloor}
                            value={this.props.values.insuredFloor}
                            onBlur={(value) => this.props.addValue('insuredFloor', value)}
                            number
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col {...colXL6}>
                                <CustomSwitch
                                    form={this.props.form}
                                    name="specialInsuranceVol"
                                    label={this.props.label.insuranceVol}
                                    value={this.props.values.insuranceVol}
                                    onChange={(value) => {
                                        this.props.addValue('insuranceVol', value);
                                    }}
                                    className="xl-panding-left-60"
                                    disabled={this.props.disabled}
                                />
                            </Col>
                            <Col {...colXL6}>
                                <Amount
                                    form={this.props.form}
                                    name="specialInsuranceVolMontant"
                                    label={this.props.label.insuranceVolMontant}
                                    value={this.props.values.insuranceVolMontant}
                                    onBlur={(value) => this.props.addValue('insuranceVolMontant', value)}
                                    disabled={this.props.disabled}
                                    left={10}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Fragment>
        );
    }
};

const HabitationController = generateController(Habitation);
export default HabitationController;
