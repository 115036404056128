import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStore } from '../../core/store';
import { Backdrop } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.tooltip + 1,
    color: '#fff',
  },
}));

export default function GlobalSpin() {
  const classes = useStyles();
  const { globalSpin } = useStore();
  return (
    <Backdrop
      className={classes.backdrop}
      open={!!globalSpin}
    >
      <CircularProgress color="inherit" />
    </Backdrop>

  );
}
