import React from 'react';
import { connect } from 'react-redux';
import { translation_fr } from '../../../translation/FR';
import { translation_cn } from '../../../translation/CN';
import lan_fr from '../../../images/france.svg';
import lan_cn from '../../../images/china.svg';
import { setTranslation } from '../../store/actions/translationActions';
import { setLanguage } from '../../store/actions/languageAction';

const mapDispatchToProps = (dispatch) => {
    return {
        setTranslation: (trans) => { dispatch(setTranslation(trans)); },
        setLanguage: (lan) => { dispatch(setLanguage(lan)) },
    };
};

const LangSelectorComponent = (props) => {
    return <div className="language-bar">
        <a
            title="Français"
            onClick={(e) => {
                e.preventDefault();
                props.setLanguage('fr');
                props.setTranslation(translation_fr);
            }}
        >
            <img src={lan_fr} alt="Français" />
        </a>
        <a
            title="中文"
            onClick={(e) => {
                e.preventDefault();
                props.setLanguage('zh');
                props.setTranslation(translation_cn);
            }}
        >
            <img src={lan_cn} alt="中文" />
        </a>
    </div>
};

const LangSelectorController = connect(null, mapDispatchToProps)(LangSelectorComponent);
export default LangSelectorController;