import {connect} from 'react-redux';

const mapStateToProps = (state) => {
    return {
        insuranceType: state.formValues.quote.insuranceType || null,
    };
};

const InsuranceSpecialFieldsController =
    (InsuranceSpecialFieldsComponent) => {return connect(mapStateToProps)(InsuranceSpecialFieldsComponent)};

export default InsuranceSpecialFieldsController;
