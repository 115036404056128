import React from 'react';
import { Tooltip, Icon } from 'antd';

const FormItemLabelComponent = (props) => {
    const {tooltip, label} = props;
    return tooltip ? 
    (<span>
        {label}&nbsp;
        <Tooltip title={tooltip}>
            <Icon type="info-circle" />
        </Tooltip>
    </span>) : (<span>{label}</span>);
};

export default FormItemLabelComponent