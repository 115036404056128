import {SET_COMPANY} from '../actions/companyAction';

const companies = (state = null, action) => {
    switch (action.type) {
        case SET_COMPANY:
            return action.companies;
        default:
            return state;
    }
};

export default companies;
