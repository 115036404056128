import React from 'react';
import {getFormItemStyle} from '../../../constant/DevisContant';
import {Form, Input} from 'antd';
import {connect} from 'react-redux';
import FormItemLabel from './FormItemLabelComponent';
import { fetchCityByCP } from '../../../utils/geo';

const FormItem = Form.Item;

function PostalCode(props) {
    const {getFieldDecorator} = props.form;
    const formItemLayout = getFormItemStyle(props);
    let rules = [];
    if (props.required) {
        rules.push({required: true, message: props.emptyMessage});
    }
    const {tooltip, label} = props; 
    return (
        <FormItem 
            {...formItemLayout}
            label={
                <FormItemLabel label={label} tooltip={tooltip}/>
            }
            colon={props.colon}
        >
            {getFieldDecorator(props.name, {
                initialValue: props.value,
                rules: rules,
            })(
                <Input 
                    onChange={(e) => {
                        let text = e.target.value;
                        props.updateCP(text);
                        if (text && text.length === 5) {
                            fetchCityByCP(text).then(result => {
                                if (result) {
                                    props.updateCity(result);
                                }
                            })
                        }
                    }} 
                    onBlur={ (e) => {
                        if (props.onBlur) {
                            props.onBlur(e);
                        }
                    } 
                    }
                    disabled={props.disabled}
                />
            )}
        </FormItem>
    );
}

const mapStateToProps = (state) => {
    return {
        emptyMessage: state.translation.messageError.empty, 
        formatMessage: state.translation.messageError.format
    };
};

const TextController = connect(mapStateToProps)(PostalCode);
export default TextController;
