import { baseURL, nodeApiURL } from "../constant/http-constant";
import { CustomError } from './error';
class HttpFormClient {
    constructor() {
        this.baseUrl = baseURL;
        this.nodeApiUrl = nodeApiURL;
        this.config = {
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                // 'Content-Type': 'multipart/form-data'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer', // no-referrer, *client
        }

    }
    async post(url = '', formData) {
        url = this.getUrl(url);
        let request = { ...this.config };
        request.method = 'POST';
        if (this.authToken) {
            request.headers['authorization'] = 'bearer ' + this.authToken;
        }
        request.body = formData
        const response = await fetch(url, request);
        if (!response.ok) // or check for response.status
        {
            await this.handleError(response);
        }
        return response.json();
    }

    getUrl(url) {
        if (url.indexOf('https://') !== 0 && url.indexOf('http://') !== 0) {
            return url = this.baseUrl + url;
        }
        return url;
    }
    setToken(token, basePath) {
        this.authToken = token;
        this.basePath = basePath;
    }
    handleError = async response => {
        const { status } = response;
        if ((status === 401 || status === 403) && response.url.indexOf('/login') < 0 && response.url.indexOf('/checktoken') < 0) {
            window.location = window.location.origin + '/' + this.basePath;
        }
        let error = await response.json();
        try {
            error = await response.json();
        } catch {
            error = 'Erreur';
        }
        throw new CustomError(error);
    }
}
export default (new HttpFormClient());