import {getStaticFile} from '../../../constant/Axios';

export const SET_COMPANY = 'SET_COMPANY';

export const setCompany = (companies) => {
    return {type: SET_COMPANY, companies};
};

const getCompany =
    () => getStaticFile('/options/insurancecompanies_fr.json')
        .then(resp => resp.data ? resp.data.sort((a, b) => a.text > b.text ? 1 : -1) : []);

export const fetchCompany = () =>
    (dispatch) => getCompany()
        .then(companies => {
            dispatch(setCompany(companies));
        })
        .catch((error) => {
            console.log(error.toString());
        });
