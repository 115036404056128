import React, { Fragment, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { Grid } from '@material-ui/core';
import {NumberField} from './number-input';
export default function AddressInput({ register, unregister, setValue, errors, model, name, col, validator }) {
    const defaultValue = model || {};
    const formName = name | {};
    const {address1 = 'address1', address2 = 'address2', address3 = 'address3', postalCode = 'postalCode', city = 'city'} = formName;
    let address1Err = errors && errors[address1] && true;
    let cityErr = errors && errors[city] && true;
    let postalCodeErr = errors && errors[postalCode] && true;
    useEffect(() => {
        register({ name: postalCode }, validator && validator[postalCode]);
        register({ name: city }, validator && validator[city]);
        return () => {
            unregister(postalCode);
            unregister(city);
        };
    }, [register, unregister, city, validator, postalCode]);
    return <Fragment>
        <Grid item xs={12} md={col} key={address1}>
            <TextField inputRef={validator && validator[address1] ? register(validator[address1]) : register} label="Adresse 1" name={address1} defaultValue={defaultValue[address1] || null} error={address1Err} fullWidth></TextField>
        </Grid>
        <Grid item xs={12} md={col} key={address2}>
            <TextField inputRef={validator && validator[address2] ? register(validator[address1]) : register} label="Adresse 2" name={address2} defaultValue={defaultValue[address2] || null} fullWidth></TextField>
        </Grid>
        <Grid item xs={12} md={col} key={address3}>
            <TextField inputRef={validator && validator[address3] ? register(validator[address1]) : register} label="Adresse 3" name={address3} defaultValue={defaultValue[address3] || null} fullWidth></TextField>
        </Grid>
        <Grid item xs={12} md={col} key={postalCode}>
            <NumberField label="Code postal" defaultValue={defaultValue[postalCode] || null} onChange={e => setValue(postalCode, e.target.value)} numberProps={{format: '#####'}} error={postalCodeErr}></NumberField>
        </Grid>
        <Grid item xs={12} md={col} key={city}>
            <TextField label="Ville" defaultValue={defaultValue[city] || null} fullWidth onInput={e => setValue(city, e.target.value)} error={cityErr}></TextField>
        </Grid>
    </Fragment>
}
