import React, { Fragment } from 'react';
import Radio from '../../../form/FieldTemplate/RadioComponent';
import Text from '../../../form/FieldTemplate/TextComponent';
import AutoComplete from '../../../form/FieldTemplate/AutoCompleteComponent';
import generateController from '../../controller/Content/ContractTypeController';
import CustomSwitch from '../../../form/FieldTemplate/SwitchComponent';
import { showInsuranceCategory } from '../../../../constant/DevisContant';
import CustomDate from '../../../form/FieldTemplate/DateDropdownListComponent';
import CheckboxGroup from '../../../form/FieldTemplate/CheckboxGroupComponent';
class InsuranceTypeForm extends React.Component {
    componentDidMount() {
        if (this.props.companies === null) {
            this.props.getCompanies();
        }
    }
    render() {
        const { label, options, values, form, disabled } = this.props;
        const { insuranceType, insuranceCategory } = values;
        return (
            <Fragment>
                <Radio
                    form={form}
                    name="contractType"
                    label={disabled ? label.contractType : label.contractTypeQuestion}
                    options={options.contractType}
                    value={values.contractType}
                    onChange={(value) => {
                        this.props.addBasicValue('contractType', value);
                    }}
                    required
                    center={!disabled}
                    disabled={disabled}
                    colon={true}
                    left={6}
                />
                {values.contractType === 'change' &&
                    <div className="label-left">
                        <AutoComplete
                            form={form}
                            name="oldInsuranceCompany"
                            dataSource={this.props.companies}
                            label={disabled ? label.oldInsuranceCompany : label.oldInsuranceCompanyQuestion}
                            value={values.oldInsuranceCompany}
                            onChange={(value) => this.props.addBasicValue('oldInsuranceCompany', value)}
                            required
                            left={disabled ? 6 :12}
                            disabled={disabled}
                        />
                        <Text
                            form={form}
                            name="oldContractNumber"
                            label={disabled ? label.oldContractNumber : label.oldContractNumberQuestion}
                            value={values.oldContractNumber}
                            onBlur={(value) => this.props.addBasicValue('oldContractNumber', value)}
                            required
                            left={disabled ? 6 :12}
                            disabled={disabled}
                        />
                        <CustomDate
                            form={form}
                            name="oldContractDueDate"
                            label={label.oldContractDueDate}
                            value={values.oldContractDueDate}
                            onChange={(value) => this.props.addBasicValue('oldContractDueDate', value)}
                            disabled={disabled}
                            left={disabled ? 6 :12}
                            right={12}
                        />
                        <CustomSwitch
                            form={form}
                            name="cancellationLetter"
                            label={label.cancellationLetter}
                            value={values.cancellationLetter}
                            onChange={(value) => {
                                this.props.addBasicValue('cancellationLetter', value);
                            }}
                            className={disabled ?  'xl-panding-left-100' :'form-item-control-center'}
                            disabled={disabled}
                        />
                    </div>
                }
                <CustomDate
                    form={form}
                    name="expectedStartDate"
                    label={disabled ? label.expectedStartDate : label.expectedStartDateQuestion}
                    value={values.expectedStartDate}
                    onChange={(value) => this.props.addBasicValue('expectedStartDate', value)}
                    disabled={disabled}
                    center={!disabled}
                    left={6}
                    right={6}
                    required
                    className="date-center"
                    maxYear={new Date().getFullYear() + 1}
                />
                {
                    showInsuranceCategory.indexOf(this.props.values.insuranceType) !== -1 &&
                    <Radio
                        form={form}
                        name="insuranceCategory"
                        label={disabled ? label.insuranceCategory : label.insuranceCategoryQuestion}
                        options={options.insuranceCategory}
                        value={insuranceCategory}
                        onChange={(value) => {
                            this.props.addBasicValue('insuranceCategory', value);
                        }}
                        required
                        center={!disabled}
                        left={6}
                        disabled={disabled}
                    />
                }
                {
                    insuranceType === 'habitation' &&
                    <Radio
                        form={form}
                        name="leaseNature"
                        label={label.leaseNature}
                        options={options.leaseNature}
                        value={values.leaseNature}
                        onChange={(value) => {
                            this.props.addSpecialValue('leaseNature', value);
                            this.props.addBasicValue('insuranceCategory', 'per')
                        }}
                        required
                        center={!disabled}
                        left={6}
                        disabled={disabled}
                    />
                }
                {
                    insuranceType === 'localPro' &&
                    <Radio
                        form={form}
                        name="localProLeaseNature"
                        label={label.leaseNature}
                        options={options.localProLeaseNature}
                        value={values.leaseNature}
                        onChange={(value) => {
                            this.props.addSpecialValue('leaseNature', value);
                        }}
                        required
                        center={!disabled}
                        left={6}
                        disabled={disabled}
                    />
                }
                {
                    (values.leaseNature === 'ownerOccupied'
                        || values.leaseNature === 'ownerNonOccupied' || insuranceType === 'borrow') &&
                    <CustomSwitch
                        form={form}
                        name="ownerIsCompany"
                        label={label.companyOwnedHouse}
                        value={values.ownerIsCompany}
                        onChange={(value) => {
                            this.props.addCommonValue('ownerIsCompany', value);

                        }}
                        required
                        disabled={disabled}
                        className={disabled ?  'xl-panding-left-100' :''}
                    />
                }
                {
                    (insuranceType === 'life' && insuranceCategory === 'per') &&
                    <CustomSwitch
                        form={form}
                        name="addPerson2InContract"
                        label={label.addPerson2InContract}
                        value={values.addPerson2InContract}
                        onChange={(value) => {
                            this.props.addBasicValue('addPerson2InContract', value);
                        }}
                        required
                        disabled={disabled}
                        className={disabled ?  'xl-panding-left-100' :''}
                    />
                }
                {
                    (insuranceType === 'borrow') && !disabled &&
                    <Radio
                        form={form}
                        name="personBorrowWith"
                        label={label.personBorrowWith}
                        options={options.personBorrowWith}
                        value={values.personBorrowWith}
                        onChange={(value) => {
                            this.props.addSpecialValue('personBorrowWith', value);
                            this.props.addBasicValue('addPerson2InContract', value === 'withPerson');

                        }}
                        required
                        center={!disabled}
                        left={6}
                        disabled={disabled}
                    />
                }
                {
                    (insuranceType === 'vehicle' && insuranceCategory === 'pro') &&
                    <CustomSwitch
                        form={form}
                        name="allCompanyDrivers"
                        label={label.allCompanyDrivers}
                        value={values.allCompanyDrivers}
                        onChange={(value) => {
                            this.props.addSpecialValue('allCompanyDrivers', value);
                            this.props.addSpecialValue('hasSecondDriver', false);
                        }}
                        required
                        disabled={disabled}
                        className={disabled ?  'xl-panding-left-100' :''}
                    />
                }
                <CheckboxGroup
                        form={form}
                        name="paymentMethod"
                        label={disabled ?  label.acceptedPaymentMethods : label.acceptedPaymentMethodsQuestion}
                        value={values.paymentMethods}
                        options={options.paymentMethod}
                        onChange={(value) => {
                            this.props.addCommonValue('paymentMethods', value);
                        }}
                        center={!disabled}
                        left={6}
                        disabled={disabled}
                    />

            </Fragment>
        );
    }
}
export default generateController(InsuranceTypeForm);
