import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Login from '../form/templates/login';
import { boRoute } from '../../constant/route-constant';
import { getBoUserFromSessionStorage, saveBoUserToSessionStorage } from '../../utils/storage';
import { setUser, setGlobalSpin } from '../../core/action';
import { useStoreDispatch } from '../../core/store';
import boApi from '../../api/bo.api';
import http from '../../core/http';
import { useToast } from '../../core/notifier';
export default function BoLogin(props) {
  const [model, setModel] = useState({ toBo: false, loading: true });
  const dispatch = useStoreDispatch();
  const addToast = useToast();
  let redirectUrl = boRoute;
  if (props.location && props.location.state
    && props.location.state.from && props.location.state.from.indexOf(boRoute) > -1) {
    redirectUrl = props.location.state.from;
  }
  const user = getBoUserFromSessionStorage();
  useEffect(() => {
    if (user && user.token) {
      http.setToken(user.token, 'bo');
      const fetchData = async () => {
        dispatch(setGlobalSpin(true));
        try {
          let data = await boApi.users.checktoken();
          data.token = user.token;
          saveBoUserToSessionStorage(data);
          dispatch(setUser(data))
          setModel({ toBo: true });
        } catch {
          http.setToken(null, 'bo');
          saveBoUserToSessionStorage(null);
        }
        finally {
          dispatch(setGlobalSpin(false));
        }
      };
      fetchData();
    }
  }, []);
  const onSubmit = async value => {
    dispatch(setGlobalSpin(true));
    try {
      const data = await boApi.users.login(value);
      saveBoUserToSessionStorage(data);
      http.setToken(data.token, 'bo');
      dispatch(setUser(data))
      setModel({ toBo: true });
    } catch (error) {
      addToast('loginFailed', 'error');
    } finally {
      dispatch(setGlobalSpin(false));
    }
  };
  if (model.toBo) {
    return <Redirect to={redirectUrl} />
  }
  return <Login onSubmit={onSubmit} title="Backoffice"
    bgImage='https://delinprod.blob.core.windows.net/public/bg-login-bo.jpg'></Login>
}