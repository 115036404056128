import React, { Suspense, lazy } from 'react';
import { translation_fr } from '../translation/FR';
import { translation_cn } from '../translation/CN';
import { LocaleProvider, Spin } from 'antd';
import fr_FR from 'antd/lib/locale-provider/fr_FR';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateBORoute from './_bo/private-bo-route';
import { setTranslation } from './store/actions/translationActions';
import { setLanguage } from './store/actions/languageAction';
import BoLogin from './_bo/login';
import { boLoginRoute, boRoute } from '../constant/route-constant';
import ServiceLogin from './_service/login';
import Public from './_public';
import { serviceLoginRoute, serviceRoute } from '../constant/route-constant';
import PrivateServiceRoute from './_service/private-services-route';
const Bo = lazy(() => import('./_bo/index'));
const Services = lazy(() => import('./_service/index'));
const locale = {
    fr: fr_FR,
    zh: zh_CN
};

const mapStateToProps = (state) => {
    return {
        language: state.language,
        translation: state.translation,
        spinning: state.spin.spinning
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setTranslation: (trans) => { dispatch(setTranslation(trans)); },
        setLanguage: (lan) => { dispatch(setLanguage(lan)) },
    };
};
class AppComponent extends React.Component {
    constructor(props) {
        super(props);
        this.props.setTranslation(this.props.language === 'zh' ? translation_cn : translation_fr);
    }
    render() {
        return (
            <LocaleProvider locale={locale[this.props.language]}>
                <Router>
                    <Spin size="large" spinning={this.props.spinning} wrapperClassName="root-spin">
                        <Suspense fallback={<div>Chargement...</div>}>
                            <Switch>
                                <Route exact path="/qr" component={Public} />
                                <Route path={`/qr/:name`} component={Public} />
                                <Route exact path={serviceLoginRoute} component={ServiceLogin} />
                                <PrivateServiceRoute path={serviceRoute} component={Services} />
                                <Route exact path={boLoginRoute} component={BoLogin} />
                                <PrivateBORoute path={boRoute} component={Bo}/>
                                <Route exact path="/" component={Public} />
                                <Route component={Public} />
                            </Switch>
                        </Suspense>
                    </Spin>
                </Router>
            </LocaleProvider>
        );
    }
}

const AppController = connect(mapStateToProps, mapDispatchToProps)(AppComponent);
export default AppController;
