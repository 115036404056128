import {SET_BANK} from '../actions/banksAction';

const banks = (state = null, action) => {
    switch (action.type) {
        case SET_BANK:
            return action.banks;
        default:
            return state;
    }
};

export default banks;
