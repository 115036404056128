import React, { Fragment } from 'react';
import generateController from '../../controller/Content/UploadController';
import FileUploader from '../../../form/FieldTemplate/FileUploaderComponent';
import { Row, Col } from 'antd';
import { getCustomerName } from '../../../../utils/file';

class Upload extends React.Component {
    render() {
        let name = getCustomerName(this.props.values);
        return (
            <Fragment>
                <Row className="quotaionFiles">
                    <Col col={24}>
                        <FileUploader
                            form={this.props.form}
                            name="uploadIdentity"
                            label={this.props.label.identity}
                            value={this.props.values.identity}
                            onChange={(files) => this.props.addValue('identity', files)}
                            onRemove={(location) => this.props.addDelete(location)}
                            left={6}
                            baseName={name + '-Pièce d\'identité'}
                        />
                    </Col>

                    {this.props.values.insuranceType === 'borrow' &&
                        <Fragment>
                            <Col col={24}>
                                <FileUploader
                                    form={this.props.form}
                                    name="amortizationSchedule"
                                    label={this.props.label.amortizationSchedule}
                                    value={this.props.values.amortizationSchedule}
                                    onChange={(files) => this.props.addValue('amortizationSchedule', files)}
                                    onRemove={(location) => this.props.addDelete(location)}
                                    required
                                    left={6}
                                    baseName={name + '-Tableau d\'amortissement'}
                                />
                            </Col>
                            <Col col={24}>
                                <FileUploader
                                    form={this.props.form}
                                    name="loanContract"
                                    label={this.props.label.loanContract}
                                    value={this.props.values.loanContract}
                                    onChange={(files) => this.props.addValue('loanContract', files)}
                                    onRemove={(location) => this.props.addDelete(location)}
                                    required
                                    left={6}
                                    baseName={name + '-Contrat de crédit'}
                                />
                            </Col>
                        </Fragment>


                    }
                    {(this.props.values.insuranceCategory === 'pro' || this.props.values.ownerIsCompany) &&
                        <Col col={24}>
                            <FileUploader
                                form={this.props.form}
                                name="uploadCompanyKbis"
                                label={this.props.label.companyKbis}
                                value={this.props.values.companyKbis}
                                onChange={(files) => this.props.addValue('companyKbis', files)}
                                onRemove={(location) => this.props.addDelete(location)}
                                required
                                left={6}
                                baseName={name + '-K-bis'}
                            />
                        </Col>

                    }
                    {this.props.values.insuranceType === 'borrow' &&
                        <Col col={24}>
                            <FileUploader
                                form={this.props.form}
                                name="rib"
                                label={this.props.label.rib}
                                value={this.props.values.rib}
                                onChange={(files) => this.props.addValue('rib', files)}
                                onRemove={(location) => this.props.addDelete(location)}
                                left={6}
                                baseName={name + '-Rib'}
                            />
                        </Col>
                    }
                    {this.props.values.insuranceType === 'vehicle' &&
                        <Fragment>
                            <Col col={24}>
                                <FileUploader
                                    form={this.props.form}
                                    name="uploadGrayCard"
                                    label={this.props.label.grayCard}
                                    value={this.props.values.grayCard}
                                    onChange={(files) => this.props.addValue('grayCard', files)}
                                    onRemove={(location) => this.props.addDelete(location)}
                                    required
                                    left={6}
                                    baseName={name + '-Carte grise'}
                                />
                            </Col>
                            <Col col={24}>
                                <FileUploader
                                    form={this.props.form}
                                    name="uploadDriverLicense"
                                    label={this.props.label.driverLicense}
                                    value={this.props.values.driverLicense}
                                    onChange={(files) => this.props.addValue('driverLicense', files)}
                                    onRemove={(location) => this.props.addDelete(location)}
                                    required
                                    left={6}
                                    baseName={name + '-Permis de conduire'}
                                />
                            </Col>
                            <Col col={24}>
                                <FileUploader
                                    form={this.props.form}
                                    name="uploadInsuranceRecord"
                                    label={this.props.label.insuranceRecord}
                                    value={this.props.values.insuranceRecord}
                                    onChange={(files) => this.props.addValue('insuranceRecord', files)}
                                    onRemove={(location) => this.props.addDelete(location)}
                                    required
                                    left={6}
                                    baseName={name + '-Relevé information'}
                                />
                            </Col>

                        </Fragment>
                    }
                    {['localPro', 'habitation'].indexOf(this.props.values.insuranceType) > -1 &&
                        <Fragment>
                            <Col col={24}>
                                <FileUploader
                                    form={this.props.form}
                                    name="lease"
                                    label={this.props.label.lease}
                                    value={this.props.values.lease}
                                    onChange={(files) => this.props.addValue('lease', files)}
                                    onRemove={(location) => this.props.addDelete(location)}
                                    left={6}
                                    baseName={name + '-Bail'}
                                />
                            </Col>
                            <Col col={24}>
                                <FileUploader
                                    form={this.props.form}
                                    name="photoOneMonth"
                                    label={this.props.label.photoOneMonth}
                                    value={this.props.values.photo}
                                    onChange={(files) => this.props.addValue('photo', files)}
                                    onRemove={(location) => this.props.addDelete(location)}
                                    left={6}
                                    baseName={name + '-Photo'}
                                />
                            </Col>
                        </Fragment>
                    }
                    {this.props.values.contractType === 'change' &&
                        <Col col={24}>
                            <FileUploader
                                form={this.props.form}
                                name="uploadOldContract"
                                label={this.props.label.oldContract}
                                value={this.props.values.oldContract}
                                onChange={(files) => this.props.addValue('oldContract', files)}
                                onRemove={(location) => this.props.addDelete(location)}
                                left={6}
                                baseName={name + '-Contrat d\'assurance'}
                            />
                        </Col>
                    }
                    <Col col={24}>
                        <FileUploader
                            form={this.props.form}
                            name="uploadOther"
                            label={this.props.label.other}
                            value={this.props.values.other}
                            onChange={(files) => this.props.addValue('other', files)}
                            onRemove={(location) => this.props.addDelete(location)}
                            left={6}
                            baseName={name + '-Autre'}
                        />
                    </Col>
                </Row>
            </Fragment>
        );
    }
};

export default generateController(Upload);
