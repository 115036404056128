import React, {useEffect, useState } from 'react';
import { FormControl, Select, MenuItem, InputLabel, Box, TextField, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    select: {
        width: '80px',
    },
}));
export default function TelInput({ register, errors, model = { prefix: '+33' }, name, validator, label, setValue }) {
    const classes = useStyles();
    const { prefix, number} = name;
    let telephoneErr = errors && errors[number] && true;
    useEffect(() => {
        register({ name: prefix });
        setValue(prefix, model[prefix] || null);
      }, [register, prefix, setValue]);
    const [prefixValue, setPrefixValue] = useState(model[prefix] || null);
    return <Box display="flex">
        <FormControl className={classes.select}>
            <InputLabel></InputLabel>
            <Select defaultValue={prefixValue}
                value={prefixValue} onChange={e => {
                    setValue(prefix, e.target.value);
                    setPrefixValue(e.target.value);
                }}
            >
                <MenuItem value={'+33'}>+33</MenuItem>
            </Select>
        </FormControl>
        <TextField inputRef={validator ? register(validator) : register} defaultValue={model[number] || null} name={number} type='tel' fullWidth label={label} error={telephoneErr}></TextField>
    </Box>
}
