import {connect} from 'react-redux';
import {addSpecialValue} from '../../../store/actions/formValuesActions';

const mapStateToProps = (state) => {
    const specialValues = state.formValues.specialInfo || {};
    return {
        label: {
            insuranceAddressSame: state.translation.label.specialInsuranceAddressProSame,
            localType: state.translation.label.specialLocalType,
            insuranceAmount: state.translation.label.specialInsuredAmount,
            equipmentAmount: state.translation.label.specialEquipmentAmount,
            glassAmount: state.translation.label.specialGlassAmount,
            itAmount: state.translation.label.specialITAmount,
            insuredSurface: state.translation.label.specialInsuredSurface,
            insuredFloor: state.translation.label.specialInsuredFloor,
            vol: state.translation.label.specialVol,
            insuranceVolMontant: state.translation.label.specialInsuranceVolMontant,
            insuranceResponsible: state.translation.label.specialInsuranceResponsible,
            employeeNumber: state.translation.label.specialEmployeeNumber,
            insuranceLoss: state.translation.label.specialInsuranceLoss,
            cAn1: state.translation.label.specialCAn1,
            insuranceSecurityEquipment: state.translation.label.specialInsuranceSecurityEquipment,
            insuranceFireSafetyEquipment: state.translation.label.specialInsuranceFireSafetyEquipment,
            insuranceAlertEquipment: state.translation.label.specialInsuranceAlertEquipment,
            fireSafetyEquipmentCheck: state.translation.label.specialFireSafetyEquipmentCheck,
            electricEquipmentCheck: state.translation.label.specialElectricEquipmentCheck,
            leaseNature: state.translation.label.specialLeaseNature,
            date: state.translation.label.date,
            grossMargin: state.translation.label.grossMargin,
            specialRCSNum: state.translation.label.specialRCSNum,
            specialCAN: state.translation.label.specialCAN,
            specialActivityCode: state.translation.label.specialActivityCode,
            localContentAmount: state.translation.label.localContentAmount,
            insurance: state.translation.label.insurance,
            specialContentAmount: state.translation.label.specialContentAmount,
            latestVerificationDate: state.translation.label.latestVerificationDate,
        },
        options: {
            localType: state.translation.options.specialLocalType,
            insuranceSecurityEquipment: state.translation.options.specialInsuranceSecurityEquipment,
            insuranceFireSafetyEquipment: state.translation.options.specialInsuranceFireSafetyEquipment,
            insuranceAlertEquipment: state.translation.options.specialInsuranceAlertEquipment,
            leaseNature: state.translation.options.specialLeaseNature,
            insuranceAddressSame: state.translation.options.specialInsuranceAddressSame,
        },
        values: specialValues
    };
};

const mapDispatchToProps = (dispatch) => {
    return { 
        addValue: (key, value) => { dispatch(addSpecialValue(key, value)) },
    };
};

const LocalProController =
    (LocalProComponent) => {return connect(mapStateToProps, mapDispatchToProps)(LocalProComponent)};

export default LocalProController;
