import {connect} from 'react-redux';
import {addBasicValue, setSpecialValues} from '../../../store/actions/formValuesActions';
import {proInsuranceCategory, perInsuranceCategory} from '../../../../constant/DevisContant';

const mapStateToProps = (state) => {
    return {
        label: {
            insuranceType: state.translation.label.insuranceType,
            insuranceCategory: state.translation.label.insuranceCategory,
        },
        options: {
            insuranceType: state.translation.options.insuranceType,
            insuranceCategory: state.translation.options.insuranceCategory,
        },
        values: {
            insuranceType: state.formValues.quote.insuranceType,
            insuranceCategory: state.formValues.quote.insuranceCategory,
        }
    };
};

const mapDispatchToProps = (dispatch) => {
    return { 
        addValue: (key, value) => { 
            dispatch(addBasicValue(key, value));
            if (key === 'insuranceType') {
                dispatch(addBasicValue('contractType', null));
                dispatch(addBasicValue('insuranceCategory', null));
                dispatch(addBasicValue('oldContractNumber', null));
                dispatch(addBasicValue('oldInsuranceCompany', null));
                dispatch(addBasicValue('addPerson2InContract', null));
                if (proInsuranceCategory.indexOf(value) > -1) {
                    dispatch(addBasicValue('insuranceCategory', 'pro'));
                }
                if (perInsuranceCategory.indexOf(value) > -1) {
                    dispatch(addBasicValue('insuranceCategory', 'per'));
                }
            }
        },
        setSpecialValues: (values) => { dispatch(setSpecialValues(values)) },
    };
};

const InsuranceTypeController =
    (InsuranceTypeComponenet) => {return connect(mapStateToProps, mapDispatchToProps)(InsuranceTypeComponenet)};

export default InsuranceTypeController;
