import {combineReducers} from 'redux';
import translation from './translationReducer';
import formValues from './formValuesReducer';
import cars from './carReducer';
import companies from './companyReducer';
import language from './languageReducer';
import quote from './quoteReducer';
import loading from './loadingReducer';
import banks from './banksReducer';
import postalCode from './postalCodeReducer';
import city from './cityReducer';
import spin from './spinReducer';
import boUser from './userReducer';

const reducer = combineReducers({
    language,
    translation,
    formValues,
    cars,
    companies,
    quote,
    loading,
    banks,
    postalCode,
    city,
    spin,
    boUser
});

export default reducer;
