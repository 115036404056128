import React from 'react';
import {getFormItemStyle} from '../../../constant/DevisContant';
import {Form, Checkbox} from 'antd';
import {connect} from 'react-redux';
import FormItemLabel from './FormItemLabelComponent';

const FormItem = Form.Item;

class CustomCheckboxGroup extends React.Component {
    transferOptions(options) {
        const newOptions = [];
        Object.keys(options).map(function(key) {
            newOptions.push({
                label: options[key],
                value: key
            });
            return key;
        });
        return newOptions;
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        const formItemLayout = getFormItemStyle(this.props);
        let rules = [];
        if (this.props.required) {
            rules.push({required: true, message: this.props.emptyMessage});
        }
        const {tooltip, label} = this.props;

        return (
            <FormItem 
                {...formItemLayout}
                label={
                    <FormItemLabel label={label} tooltip={tooltip}/>
                }
            >
                {getFieldDecorator(this.props.name, {
                    initialValue: this.props.value,
                    rules: rules,
                })(
                    <Checkbox.Group
                        size="large"
                        options={this.transferOptions(this.props.options)}
                        onChange={(value) => {
                            if (this.props.onChange) {
                                this.props.onChange(value);
                            }
                        }}
                        disabled={this.props.disabled}
                    />
                )}
            </FormItem>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        emptyMessage: state.translation.messageError.empty
    };
};

const CustomCheckboxGroupController = connect(mapStateToProps)(CustomCheckboxGroup);
export default CustomCheckboxGroupController;
