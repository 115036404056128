import React, { Fragment } from 'react';
import FormAction from '../../form/FieldTemplate/FormActionComponent';
import { Form, Alert, Button } from 'antd';
import generateController from '../controller/CommentController';
import CommentContent from './Content/Comment';
import { getMainAreaY } from '../../../utils/window';
import { Link } from 'react-router-dom';
class FormComponent extends React.Component {
    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.setLoading(true);
                this.props.postFormValues();
            }
        });
    }
    generateSuccessMessage() {
        const {button} = this.props;
        return (
            <Fragment>
                <Alert
                    style={{ textAlign: 'left' }}
                    message={this.props.success.message}
                    description={this.props.success.description}
                    type="success"
                />
                <div className="quote-success-bts">
                    <Button type="primary">
                        <Link to="/">{button.goBackHome}</Link>
                    </Button>
                    <Button type="primary">
                        <Link to="/qr" onClick={() => {
                            this.props.setQuote(null);
                            this.props.setFileModel({});
                            this.props.onCreateNew();
                        }}>{button.createNewQuotation}
                        </Link>
                    </Button>
                </div>
            </Fragment>

        );
    }
    generateForm() {

        return (
            <Fragment>
                {this.props.quote && this.props.quote.error &&
                    <Fragment>
                        <Alert
                            style={{ textAlign: 'left' }}
                            message={this.props.error.message}
                            description={this.props.error.description}
                            type="error"
                            closable
                        />
                        <br />
                    </Fragment>
                }
                <h1>{this.props.title}</h1>
                <Form className="label-left" onSubmit={(e) => this.handleSubmit(e)}>
                    <CommentContent
                        form={this.props.form}
                        disabled={this.props.disabled}
                    />
                    <FormAction
                        prev
                        onClick={() => {
                            this.props.onClick();
                        }}
                        finish
                    />
                </Form>
            </Fragment>
        );
    }

    componentDidMount() {
        window.scrollTo(0, getMainAreaY());
    }

    render() {
        return (
            <section>
                {this.props.quote && this.props.quote.success
                    ? this.generateSuccessMessage()
                    : this.generateForm()
                }
            </section>
        );
    }
}

const CommentForm = Form.create()(generateController(FormComponent));
export default CommentForm;
