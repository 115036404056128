import React from 'react';
import { getFormItemStyle } from '../../../constant/DevisContant';
import { Form, AutoComplete } from 'antd';
import { connect } from 'react-redux';
import FormItemLabel from './FormItemLabelComponent';
import { fetchCities } from '../../../utils/geo';

const FormItem = Form.Item;

class CityAutoComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = { cities: [] };
    }
    render() {
        let props = this.props;
        const { getFieldDecorator } = props.form;
        const formItemLayout = getFormItemStyle(props);
        let rules = [];
        if (props.required) {
            rules.push({ required: true, message: props.emptyMessage });
        }
        const { tooltip, label } = props;

        return (
            <FormItem
                {...formItemLayout}
                label={
                    <FormItemLabel label={label} tooltip={tooltip} />
                }
            >
                {getFieldDecorator(props.name, {
                    initialValue: props.value,
                    rules: rules,
                })(
                    <AutoComplete
                        dataSource={this.state.cities}
                        filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                        onChange={(value) => {
                            props.updateCity(value);
                        }}
                        onSearch={(value) => {
                            if (value && value.length === 3) {
                                fetchCities(value).then(cities => {
                                    if (cities.length === 1) {
                                        let c = cities[0].value.split('@');
                                        props.updateCity(c[1]);
                                        props.updateCP(c[0]);
                                    } else {
                                        this.setState({ cities });
                                    }
                                });
                            }
                        }}
                        onSelect={(value) => {
                            let c = value.split('@');
                            props.updateCity(c[1]);
                            props.updateCP(c[0]);
                        }}
                        disabled={this.props.disabled}
                    />
                )}
            </FormItem>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        emptyMessage: state.translation.messageError.empty
    };
};

const CityAutoCompleteController = connect(mapStateToProps)(CityAutoComplete);
export default CityAutoCompleteController;
