import {connect} from 'react-redux';
import {addSpecialValue} from '../../../store/actions/formValuesActions';

const mapStateToProps = (state) => {
    const specialValues = state.formValues.specialInfo;
    return {
        label: {
            spouseLastname: state.translation.label.specialSpouseLastname,
            spouseFirstname: state.translation.label.specialSpouseFirstname,
            spouseBirth: state.translation.label.personalBirth,
            lifeInsuranceType: state.translation.label.specialLifeInsuranceType,
            matrimonialRegime: state.translation.label.specialMatrimonialRegime,
            insuranceAmount: state.translation.label.specialInsuredAmount,
            onlyLetters: state.translation.label.onlyLetters,
        },
        options: {
            lifeInsuranceType: state.translation.options.specialLifeInsuranceType,
            matrimonialRegime: state.translation.options.specialMatrimonialRegime,
        },
        values: {
            spouseLastname: specialValues && specialValues.spouseLastname ? specialValues.spouseLastname : null,
            spouseFirstname: specialValues && specialValues.spouseFirstname ? specialValues.spouseFirstname : null,
            spouseBirth: specialValues && specialValues.spouseBirth ? specialValues.spouseBirth : null,
            lifeInsuranceType: specialValues && specialValues.lifeInsuranceType ? specialValues.lifeInsuranceType : null,
            matrimonialRegime: specialValues && specialValues.matrimonialRegime ? specialValues.matrimonialRegime : null,
            insuranceAmount: specialValues && specialValues.insuranceAmount ? specialValues.insuranceAmount : null,
        }
    };
};

const mapDispatchToProps = (dispatch) => {
    return { 
        addValue: (key, value) => { dispatch(addSpecialValue(key, value)) },
    };
};

const LifeController =
    (LifeComponent) => {return connect(mapStateToProps, mapDispatchToProps)(LifeComponent)};

export default LifeController;
