import React from 'react';
import {getFormItemStyle,} from '../../../constant/DevisContant';
import {Form, Upload, Icon, Button, notification} from 'antd';
import {connect} from 'react-redux';
import FormItemLabel from './FormItemLabelComponent';
import { baseURL } from '../../../constant/http-constant';

const FormItem = Form.Item;

class FileUploader extends React.Component {
    constructor(props) {
        super(props);
        let counter = 1;
        let fileList = this.props.value ? this.props.value : [];
        let baseName = props.baseName;
        fileList.forEach(f => {
            if (f.name && f.name.indexOf(baseName) === 0) {
                let n = f.name.replace(baseName, '').split('.')[0];
                let c = parseInt(n, 10);
                if (c && c > counter) {
                    counter = c + 1;
                }
            }
        });
        this.state = {
            fileList,
            counter
        };
    }
    getFileExtension(name) {
        if (!name) {
            return undefined;
        }
        let ex = name.toLowerCase().split('.').pop();
        if (['pdf', 'jpg', 'jpeg', 'png'].some(i => i === ex)) {
            return ex;
        }
        return undefined;
    }
    beforeUpload(file, fileList) {
        const fileAllowedType = [
            'image/jpeg', 
            'image/png', 
            'application/pdf',
        ];
        const isType = fileAllowedType.indexOf(file.type) > -1;
        if (!isType) {
            notification.open({
                message: this.props.uploadMessage,
                description: this.props.fileType,
            });
        }
        const isLt6M = file.size / 1024 / 1024 < 6;
        if (!isLt6M) {
            notification.open({
                message: this.props.uploadMessage,
                description: this.props.fileSize,
            });
        }
    
        return isType && isLt6M;
    }
    render() {
        const {getFieldDecorator} = this.props.form;
        const formItemLayout = getFormItemStyle(this.props);
        let rules = [];
        if (this.props.required) {
            rules.push({required: true, message: this.props.emptyMessage});
        }
        const {tooltip, label} = this.props; 

        return (
            <FormItem 
                {...formItemLayout}
                label={
                    <FormItemLabel label={label} tooltip={tooltip}/>
                }
            >
                {getFieldDecorator(this.props.name, {
                    initialValue: this.props.value,
                    rules: rules,
                })(
                    <Upload 
                        className="upload-file"
                        name={this.props.name}
                        action={baseURL + 'upload'}
                        defaultFileList={this.state.fileList}
                        beforeUpload={(file, fileList) => this.beforeUpload(file, fileList)}
                        onRemove={(file) => {
                            if (file.location && file.location.indexOf('/_api/file/quote/') > -1) {
                                this.props.onRemove(file.location);
                            }
                        }}
                        onChange={(info) => {
                            const file = info.file;
                            let fileList = info.fileList;
                            let fileExtension = this.getFileExtension(file.name);
                            if (typeof file.status === 'undefined' || !fileExtension || file.status === 'error') {
                                const index = fileList.indexOf(file);
                                const newFileList = fileList.slice();
                                newFileList.splice(index, 1);
                                fileList = newFileList;
                            }
                            if (file.status === 'done' && fileList.length > 0) {
                                const f = fileList[fileList.length - 1];
                                f.name = `${this.props.baseName}-${this.state.counter}.${fileExtension}`;
                                this.setState({counter: this.state.counter + 1});
                            }
                            if (info.file.status === 'done' || info.file.status === 'removed') {
                                const customFileList = fileList.map((file) => {
                                    return {
                                        uid: file.uid,
                                        name: file.name,
                                        status: file.status,
                                        location: file.location ? file.location : file.response.name,
                                    };
                                });
                                this.props.onChange(customFileList.length > 0 ? customFileList : null);
                            }
                            this.setState({fileList});
                        }}
                        fileList={this.state.fileList}
                        multiple={true}
                    >
                        {this.state.fileList.length < 5 &&
                            <Button>
                                <Icon type="upload" /> {this.props.upload}
                            </Button>
                        }
                    </Upload>
                )}
            </FormItem>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        emptyMessage: state.translation.messageError.empty,
        uploadMessage: state.translation.messageError.uploadMessage,
        fileType: state.translation.messageError.imageType,
        fileSize: state.translation.messageError.imageSize,
        upload: state.translation.label.upload,
    };
};

const FileUploaderController = connect(mapStateToProps)(FileUploader);
export default FileUploaderController;
