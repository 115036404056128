import React from 'react';
import {getFormItemStyle} from '../../../constant/DevisContant';
import {Form, AutoComplete} from 'antd';
import {connect} from 'react-redux';
import FormItemLabel from './FormItemLabelComponent';

const FormItem = Form.Item;

function CustomAutoComplete(props) {
    const {getFieldDecorator} = props.form;
    const formItemLayout = getFormItemStyle(props);
    let rules = [];
    if (props.required) {
        rules.push({required: true, message: props.emptyMessage});
    }
    const {tooltip, label} = props;

    return (
        <FormItem
            {...formItemLayout}
            label={
                <FormItemLabel label={label} tooltip={tooltip}/>
            }
        >
            {getFieldDecorator(props.name, {
                initialValue: props.value,
                rules: rules,
            })(
                <AutoComplete
                    dataSource={props.dataSource}
                    filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                    onChange={(value) => {
                        if (props.onChange) {
                            props.onChange(value);
                        }
                    }}
                    disabled={props.disabled}
                />
            )}
        </FormItem>
    );
}

const mapStateToProps = (state) => {
    return {
        emptyMessage: state.translation.messageError.empty
    };
};

const CustomAutoCompleteController = connect(mapStateToProps)(CustomAutoComplete);
export default CustomAutoCompleteController;
