import React from 'react';
import {getFormItemStyle} from '../../../constant/DevisContant';
import {Form, Radio, Input} from 'antd';
import {connect} from 'react-redux';
import FormItemLabel from './FormItemLabelComponent';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

class CustomRadio extends React.Component {
    render() {
        const {getFieldDecorator} = this.props.form;
        const formItemLayout = getFormItemStyle(this.props);
        let rules = [];
        if (this.props.required) {
            rules.push({required: true, message: this.props.emptyMessage});
        }
        const {tooltip, label, options, disabled} = this.props; 
        const ops = Object.keys(options).map(key => ({label: options[key], value: key}));
        return ( !disabled ? 
            <FormItem 
                {...formItemLayout}
                label={
                    <FormItemLabel label={label} tooltip={tooltip}/>
                }
                colon={this.props.colon}
                className={this.props.cssName}
            >
                {getFieldDecorator(this.props.name, {
                    initialValue: this.props.value,
                    rules: rules,
                })(
                    <RadioGroup options={ops} onChange={(e) => {
                        if (this.props.onChange) {
                            this.props.onChange(e.target.value);
                        }
                    }} disabled={disabled}>
                    </RadioGroup>
                )}
            </FormItem> : <FormItem 
                {...formItemLayout}
                label={
                    <FormItemLabel label={label} tooltip={tooltip}/>
                }
                colon={this.props.colon}
            >
                {getFieldDecorator(this.props.name, {
                    initialValue: options[this.props.value],
                    rules: rules,
                })(
                    <Input disabled={disabled}>
                    </Input>
                )}
            </FormItem>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        emptyMessage: state.translation.messageError.empty
    };
};

const CustomRadioController = connect(mapStateToProps)(CustomRadio);
export default CustomRadioController;
