export const getMainAreaY = () => {
    return 0;
}
export const openObjectUrl = (content, type = 'application/pdf') => {
    let tab = window.open();
    let byteCharacters = atob(content);
    let sliceSize = 512; 
    if (byteCharacters.length > 0) {
        let byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);
            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            let byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        let blob = new Blob(byteArrays, { type });
        let fileURL = URL.createObjectURL(blob);
        tab.location.href = fileURL;
    }
}
