import {connect} from 'react-redux';
import { setValues } from '../../store/actions/formValuesActions';

const mapStateToProps = (state) => {
    return {
        formValues: state.formValues,
    };
};

const mapDispatchToProps = (dispatch) => {
    return { 
        setFormValues: (value) => { dispatch(setValues(value)) },
    };
};

const StepsController =
    (StepsComponenet) => {return connect(mapStateToProps, mapDispatchToProps)(StepsComponenet)};

export default StepsController;
