import {
    ADD_BASIC_VALUE,
    ADD_COMPANY_VALUE,
    ADD_PERSONAL_VALUE,
    ADD_SPECIAL_VALUE,
    ADD_UPLOAD_VALUE,
    SET_VALUES,
    SET_SPECIAL_VALUE,
    ADD_DELETE,
    ADD_COMPANY_CONTACT_VALUE,
    ADD_PERSONAL_CONTACT_VALUE,
    ADD_SPECIAL_ADDRESS_VALUE,
    SET_UPLOAD_FILE_MODEL_VALUE,
    ADD_COMMON_VALUE,
    ADD_OWNER_COMPANY_VALUE,
} from '../actions/formValuesActions';

const formValues = (state = { quote: {} }, action) => {
    let newState = Object.assign({}, state);
    let newValue = {};
    let oldContact = { telPrefix: '+33', faxPrefix: '+33', mobilePrefix: '+33', countryCode: 'FR' };
    let newContact = {};
    switch (action.type) {
        case ADD_BASIC_VALUE:
            newValue[action.key] = action.value;
            newState.quote = Object.assign(newState.quote, newValue);
            return newState;
        case ADD_COMPANY_VALUE:
            newValue[action.key] = action.value;
            const oldCompony = newState.quote.company ? newState.quote.company : {};
            const newCompony = Object.assign(oldCompony, newValue);
            newState.quote.company = Object.assign({}, newCompony);
            return newState;
        case ADD_COMPANY_CONTACT_VALUE:
            newValue[action.key] = action.value;
            const company = newState.quote.company ? newState.quote.company : {};
            oldContact = company.contact || oldContact;
            newContact = Object.assign(oldContact, newValue);
            company.contact = newContact;
            newState.quote.company = company;
            return newState;
        case ADD_PERSONAL_VALUE:
            newValue[action.key] = action.value;
            const { prop } = action;
            const oldPersonal = newState.quote[prop] ? newState.quote[prop] : {};
            const newPersonal = Object.assign(oldPersonal, newValue);
            newState.quote[prop] = newPersonal;
            return newState;
        case ADD_PERSONAL_CONTACT_VALUE:
            newValue[action.key] = action.value;
            const { prop: p } = action;
            const personal = newState.quote[p] || {};
            oldContact = personal.contact || oldContact;
            newContact = Object.assign(oldContact, newValue);
            personal.contact = newContact;
            newState.quote[p] = personal;
            return newState;
        case ADD_SPECIAL_VALUE:
            newValue[action.key] = action.value;
            const oldSpecial = newState.specialInfo ? newState.specialInfo : {};
            const newSpecial = Object.assign(oldSpecial, newValue);
            newState.specialInfo = newSpecial;
            return newState;
        case ADD_SPECIAL_ADDRESS_VALUE:
            newValue[action.key] = action.value;
            newState.specialInfo = newState.specialInfo || {};
            const oldSpecialAddress = (newState.specialInfo.address) ? newState.specialInfo.address : {};
            const newSpecialAddress = Object.assign(oldSpecialAddress, newValue);
            newState.specialInfo.address = newSpecialAddress;
            return newState;
        case ADD_UPLOAD_VALUE:
            newValue[action.key] = action.value ? action.value.slice() : null;
            const oldUpload = newState.fileModel ? newState.fileModel : {};
            const newUpload = Object.assign(oldUpload, newValue);
            newState.fileModel = newUpload;
            return newState;
        case ADD_COMMON_VALUE:
            newValue[action.key] = action.value;
            const commonData = newState.commonData ? newState.commonData : {};
            const newCommonData = Object.assign(commonData, newValue);
            newState.commonData = newCommonData;
            return newState;
        case ADD_OWNER_COMPANY_VALUE:
            newValue[action.key] = action.value;
            newState.commonData = newState.commonData ? newState.commonData : {};
            let owenercompany = newState.commonData.company || {};
            owenercompany.contact = owenercompany.contact || {};
            if (action.contact) {
                owenercompany.contact = Object.assign(owenercompany.contact, newValue);
            } else {
                owenercompany = Object.assign(owenercompany, newValue);
            }
            newState.commonData.company = owenercompany;
            return newState;
        case SET_VALUES:
            return action.formValues.quote ? action.formValues : { quote: {} };
        case SET_SPECIAL_VALUE:
            newState.specialInfo = action.specialInfo;
            return newState;
        case SET_UPLOAD_FILE_MODEL_VALUE:
            newState.fileModel = action.uploadFileModel;
            return newState;
        case ADD_DELETE:
            const deleteArray = newState.deletedFiles ? newState.deletedFiles : [];
            deleteArray.push(action.value);
            newState.deletedFiles = deleteArray;
            return newState;
        default:
            return newState;
    }
};

export default formValues;