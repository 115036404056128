import React from 'react';
import {getFormItemStyle} from '../../../constant/DevisContant';
import {Form, Select} from 'antd';
import {connect} from 'react-redux';
import FormItemLabel from './FormItemLabelComponent';

const FormItem = Form.Item;
const Option = Select.Option;

const CustomSelect = (props) => {
    const {getFieldDecorator} = props.form;
    const formItemLayout = getFormItemStyle(props);
    let rules = [];
    if (props.required) {
        rules.push({required: true, message: props.emptyMessage});
    }
    const options = props.options;
    const multiple = props.multiple ? props.multiple : false;
    const children = [];
    if (!multiple && !props.hideEmptyOption) {
        children.push(<Option key="0" value={props.zeroValue || ''}>&nbsp;</Option>);
    }
    Object.keys(options).map((value) => {
        children.push(<Option key={value} value={value}>{options[value]}</Option>);
        return value;
    })
    const {tooltip, label} = props; 

    return (
        <FormItem 
            {...formItemLayout}
            label={
                <FormItemLabel label={label} tooltip={tooltip}/>
            }
        >
            {getFieldDecorator(props.name, {
                initialValue: props.value,
                rules: rules,
            })(
                <Select 
                    mode={multiple ? 'multiple' : 'default'} 
                    onChange={(value) => {
                        if (props.onChange) {
                            props.onChange(value);
                        }
                    }}
                    disabled={props.disabled}
                >
                    {children}
                </Select>
            )}
        </FormItem>
    );
}

const mapStateToProps = (state) => {
    return {
        emptyMessage: state.translation.messageError.empty,
    };
};

const TextController = connect(mapStateToProps)(CustomSelect);
export default TextController;
