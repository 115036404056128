import {SET_CAR} from '../actions/carActions';

const cars = (state = null, action) => {
    switch (action.type) {
        case SET_CAR:
            return action.cars;
        default:
            return state;
    }
};

export default cars;
