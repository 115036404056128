import {SET_POSTALCODE} from '../actions/postalCodeAction';

const postalCode = (state = [], action) => {
    switch (action.type) {
        case SET_POSTALCODE:
            return action.postalCode;
        default:
            return state;
    }
};

export default postalCode;
