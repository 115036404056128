import React, { useReducer } from 'react';
import reducer from '../core/reducer';
import { StateContext, DispatchContext } from '../core/context';
import AppComponent from './app-component';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import GlobalSpin from './widgets/global-spin';
import GlobalConfirm from './widgets/global-confirm';
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DatePickerUtils from '../utils/date-picker-utils';
import frLocale from "date-fns/locale/fr";
import CssBaseline from '@material-ui/core/CssBaseline';
import ReactGA from 'react-ga';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
ReactGA.initialize('UA-154333542-1');
ReactGA.pageview(window.location.pathname);
const notistackRef = React.createRef();
const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
}
function App() {
    const [state, dispatch] = useReducer(reducer, {});
    const theme = createMuiTheme({
        palette: {
            primary: {
                light: '#9b5c58',
                main: '#6a312f',
                dark: '#3c0706',
                contrastText: '#fff',
            },
            secondary: {
                light: '#686c83',
                main: '#3d4156',
                dark: '#161b2d',
                contrastText: '#fff',
            },
        },
    });
    return (<DispatchContext.Provider value={dispatch}>
        <StateContext.Provider value={state}>
            <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DatePickerUtils} locale={frLocale}>
                    <SnackbarProvider ref={notistackRef}
                        action={(key) => (
                            <IconButton edge="start" color="inherit" onClick={onClickDismiss(key)} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        )}>
                        <CssBaseline />
                        <AppComponent></AppComponent>
                        <GlobalSpin></GlobalSpin>
                        <GlobalConfirm></GlobalConfirm>
                    </SnackbarProvider>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        </StateContext.Provider>
    </DispatchContext.Provider>);
}
export default App;