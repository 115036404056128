import React, {Fragment} from 'react';
import generateController from '../../controller/Content/InsuranceSpecialFieldsController';
import LocalPro from '../SpecialFields/LocalProComponent';
import Responsible from '../SpecialFields/ResponsibleComponent';
import Transport from '../SpecialFields/TransportComponent';
import Borrow from '../SpecialFields/BorrowComponent';
import Life from '../SpecialFields/LifeComponent';
import Vehicle from '../SpecialFields/VehicleComponent';
import Habitation from '../SpecialFields/HabitationComponent';

const InsuranceSpecialInfo = (props) => (
    <Fragment>
        {props.insuranceType === 'localPro' &&
            <LocalPro
                form={props.form}
                disabled={props.disabled}
            />
        }
        {props.insuranceType === 'habitation' &&
            <Habitation
                form={props.form}
                disabled={props.disabled}
            />
        }
        {props.insuranceType === 'responsible' &&
            <Responsible
                form={props.form}
                disabled={props.disabled}
            />
        }
        {props.insuranceType === 'transport' &&
            <Transport
                form={props.form}
                disabled={props.disabled}
            />
        }
        {props.insuranceType === 'borrow' &&
            <Borrow
                form={props.form}
                disabled={props.disabled}
            />
        }
        {props.insuranceType === 'life' &&
            <Life
                form={props.form}
                disabled={props.disabled}
            />
        }
        {props.insuranceType === 'vehicle' &&
            <Vehicle
                form={props.form}
                disabled={props.disabled}
            />
        }
    </Fragment>
);

export default generateController(InsuranceSpecialInfo);
