import React from 'react';
import {getFormItemStyle} from '../../../constant/DevisContant';
import {Form, Input, Select} from 'antd';
import {connect} from 'react-redux';
import FormItemLabel from './FormItemLabelComponent';

const FormItem = Form.Item;

class Telephone extends React.Component {
    validateTel(inputName) {
        this.props.form.resetFields([inputName]);
    }
    generatePrefix(inputName) {
        const { getFieldDecorator } = this.props.form;
        const prefixName = inputName + 'Prefix';

        return getFieldDecorator(prefixName, {
            initialValue: this.props.value && this.props.value.prefix ? this.props.value.prefix : '+33',
        })(
            <Select 
                style={{ width: 70 }} 
                onChange={(value)=>{
                    this.validateTel(inputName);
                    if (this.props.onChange) {
                        this.props.onChange(true, value);
                    }
                }}
                disabled={this.props.disabled}
            >
                <Select.Option value="+33">+33</Select.Option>
                <Select.Option value="+86">+86</Select.Option>
            </Select>
        );
    }
    telPattern(inputName) {
        const { getFieldValue } = this.props.form;
        const prefixName = inputName + 'Prefix';
        let pattern = /^0?[1-9][0-9]{8}$/;
        if (typeof getFieldValue(prefixName) !== 'undefined') {
            pattern = getFieldValue(prefixName) === '+86' ? /^[1-9][0-9]{10}$/ : pattern;
        } else {
            pattern = this.props.value && this.props.value.prefix === '+86' ? /^[1-9][0-9]{10}$/ : pattern;
        }

        return pattern;
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = getFormItemStyle(this.props);
        let rules = [{pattern: this.telPattern(this.props.name), message: this.props.formatMessage}];
        if (this.props.required) {
            rules.push({required: true, message: this.props.emptyMessage});
        }
        const {tooltip, label} = this.props; 

        return (
                <FormItem 
                    {...formItemLayout}
                    label={
                        <FormItemLabel label={label} tooltip={tooltip}/>
                    }
                >
                    {getFieldDecorator(this.props.name, {
                        initialValue: this.props.value ? this.props.value.value : null,
                        rules: rules,
                    })(
                        <Input addonBefore={this.generatePrefix(this.props.name)} onChange={(e)=>{
                            if (this.props.onChange) {
                                this.props.onChange(false, e.target.value);
                            }
                        }} onBlur={(e) => {
                            let v = e.target.value;
                            if (v.indexOf('0') === 0) {
                                v = v.substring(1);
                                if (this.props.onChange) {
                                    this.props.onChange(false, v);
                                }
                                this.props.form.setFieldsValue({[this.props.name]: v});
                                
                            }
                        }} 
                        disabled={this.props.disabled}/>
                    )}
                </FormItem>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        emptyMessage: state.translation.messageError.empty, 
        formatMessage: state.translation.messageError.format
    };
};

const TelephoneController = connect(mapStateToProps)(Telephone);
export default TelephoneController;
