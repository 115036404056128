import {connect} from 'react-redux';
import {addUploadValue, addDelete} from '../../../store/actions/formValuesActions';

const mapStateToProps = (state) => {
    const upload = state.formValues.fileModel || {};
    const label = state.translation.label;
    const commonData = state.formValues.commonData || {};
    const ownerIsCompany = commonData.ownerIsCompany;
    return {
        label: {
            companyKbis: label.uploadCompanyKbis,
            sciKbis: label.uploadSCIKbis,
            identity: label.uploadIdentity,
            identityBack: label.uploadIdentityBack,
            grayCard: label.uploadGrayCard,
            grayCardBack: label.uploadGrayCardBack,
            driverLicense: label.uploadDriverLicense,
            driverLicenseBack: label.uploadDriverLicenseBack,
            insuranceRecord: label.uploadInsuranceRecord,
            oldContract: label.uploadOldContract,
            other: label.uploadOther,
            lease: label.uploadLease,
            photoOneMonth: label.uploadPhotoOneMonth,
            rib: label.uploadRib,
            companyRib: label.uploadCompanyRib,
            amortizationSchedule: label.uploadAmortizationSchedule,
            loanContract: label.uploadLoanContract,
        },
        values: {
            insuranceType: state.formValues.quote.insuranceType || null,
            companyKbis: upload.companyKbis,
            sciKbis: upload.sciKbis,
            identity: upload.identity,
            grayCard: upload.grayCard,
            driverLicense: upload.driverLicense,
            insuranceRecord: upload.insuranceRecord,
            oldContract: upload.oldContract,
            other: upload.other,
            lease: upload.lease,
            photo: upload.photo,
            rib: upload.rib,
            amortizationSchedule: upload.amortizationSchedule,
            loanContract: upload.loanContract,
            sci: state.formValues.specialInfo && state.formValues.specialInfo.sci ? state.formValues.specialInfo.sci : null,
            contractType: state.formValues.quote.contractType || null,
            insuranceCategory: state.formValues.quote.insuranceCategory,
            person: state.formValues.quote.person,
            company: state.formValues.quote.company,
            ownerIsCompany: ownerIsCompany
        }
    };
};

const mapDispatchToProps = (dispatch) => {
    return { 
        addValue: (key, value) => { 
            dispatch(addUploadValue(key, value));
        },
        addDelete: (value) => { dispatch(addDelete(value)); },
    };
};

const UploadController =
    (UploadComponenet) => {return connect(mapStateToProps, mapDispatchToProps)(UploadComponenet)};

export default UploadController;
