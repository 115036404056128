import React from 'react';
import { getFormItemStyle } from '../../../constant/DevisContant';
import { Form } from 'antd';
import { connect } from 'react-redux';
import Cleave from 'cleave.js/react';
import FormItemLabel from './FormItemLabelComponent';
import { numToString, stringToNum } from '../../../utils/num';

const FormItem = Form.Item;

const Amount = (props) => {
    const { getFieldDecorator } = props.form;
    const formItemLayout = getFormItemStyle(props);
    let rules = [];
    if (props.required) {
        rules.push({ required: true, message: props.emptyMessage });
    }
    const { tooltip, label, value } = props;
    let v = numToString(value);
    let suffix = props.suffix || '€';
    return (
        <FormItem
            {...formItemLayout}
            label={
                <FormItemLabel label={label} tooltip={tooltip} />
            }
        >
            <span className="ant-input-affix-wrapper">
                {getFieldDecorator(props.name, {
                    initialValue: v,
                    rules: rules,
                })(
                    <Cleave
                        className="ant-input"
                        options={{ numeral: true, delimiter: ' ', numeralDecimalMark: ',', numericOnly: true }}
                        onChange={(e) => {
                            if (props.onChange) {
                                props.onChange(stringToNum(e.target.value));
                            }
                        }}
                        onInput={(e) => {
                            e.target.value = e.target.value.replace('.', ',');
                        }}
                        disabled={props.disabled}
                        onBlur={(e) => {
                            if (props.onBlur) {
                                props.onBlur(stringToNum(e.target.value));
                            }
                        }}
                    />
                )}

                <span className="ant-input-suffix">{suffix}</span>
            </span>
        </FormItem>
    );
}

const mapStateToProps = (state) => {
    return {
        emptyMessage: state.translation.messageError.empty,
    };
};

const TextController = connect(mapStateToProps)(Amount);
export default TextController;
