import {SET_LANGUAGE} from '../actions/languageAction';

const language = (state = 'fr', action) => {
    switch (action.type) {
        case SET_LANGUAGE:
            return action.language;
        default:
            return state;
    }
};

export default language;
