import {getStaticFile} from '../../../constant/Axios';

export const SET_CAR = 'SET_CAR';

export const setCars = (cars) => {
    return {type: SET_CAR, cars};
};

const getCars =
    () => getStaticFile('/options/cars_fr.json')
    .then(resp => resp.data ? resp.data.sort((a, b) => a.text > b.text ? 1 : -1) : []);

export const fetchCars = () =>
    (dispatch) => getCars()
        .then(cars => {
            const models = {};
            cars.map((car) => {
                models[car.value] = car.models;
                return car;
            });
            const carsValue = { 
                brands: cars,
                models: models
            };
            dispatch(setCars(carsValue));
        })
        .catch((error) => {
            console.log(error.toString());
        });
