import {getStaticFile} from '../../../constant/Axios';

export const SET_BANK = 'SET_BANK';

export const setBanks = (banks) => {
    return {type: SET_BANK, banks};
};

const getBanks =
    () => getStaticFile('/options/banks_fr.json')
        .then(resp => resp.data ? resp.data.sort((a, b) => a.text > b.text ? 1 : -1) : []);

export const fetchBanks = () =>
    (dispatch) => getBanks()
        .then(banks => {
            dispatch(setBanks(banks));
        })
        .catch((error) => {
            console.log(error.toString());
        });
