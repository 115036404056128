const authTokenKey = 'authToken';
const userNameKey = 'userName';
const boUserKey = 'boUser';
const serviceUserKey = 'serviceUser';
export const getUserName = () => {
    return localStorage.getItem(userNameKey);
}

export const setUserName = (value) => {
    return localStorage.setItem(userNameKey, value);
}

export const getAuthToken = () => {
    return localStorage.getItem(authTokenKey);
}

export const setAuthToken = (value) => {
    return localStorage.setItem(authTokenKey, value);
}

export const hasAuthInfo = () => {
    return localStorage.getItem(userNameKey) && localStorage.getItem(authTokenKey);
}
export const removeAuthInfo = () => {
    localStorage.removeItem(userNameKey);
    localStorage.removeItem(authTokenKey);
}

export const getBoUserFromSessionStorage = () => {
    let user = localStorage.getItem(boUserKey);
    if (user) {
        return JSON.parse(user);
    } else {
        return null;
    }
};

export const saveBoUserToSessionStorage = (value) => {
    if (value === null || value === undefined) {
        localStorage.removeItem(boUserKey);
    } else {
        localStorage.setItem(boUserKey, JSON.stringify(value));
    }
    
}
export const getServiceUserFromStorage = () => {
    let user = localStorage.getItem(serviceUserKey);
    if (user) {
        return JSON.parse(user);
    } else {
        return null;
    }
};

export const saveServiceUserToStorage = (value) => {
    if (value === null || value === undefined) {
        localStorage.removeItem(serviceUserKey);
    } else {
        localStorage.setItem(serviceUserKey, JSON.stringify(value));
    }
    
}
