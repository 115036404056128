export const translation_cn = {
    title: {
        general: "基本信息",
        insuranceType: "保险类型",
        company: "公司信息",
        personal: "个人信息",
        upload: "上传文件",
        comment: "补充信息",
        detail: "保险信息",
        code: '编号',
        createdDate: '创建日期',
        email: '邮箱',
        name: '姓名',
        categoryType: '类别',
        jobTitle: '职位'
    },
    label: {
        other: "其它",
        insurance: "保险",
        contractTypeQuestion: "请选择您需要报价的合同类型",
        contractType: "合同类型",
        oldContractNumberQuestion: "您现在的保险公司的合同号码是多少",
        oldContractNumber: "现保险公司的合同号码",
        oldInsuranceCompanyQuestion: "你现在的保险公司的名字是什么",
        oldInsuranceCompany: "保险公司",
        insuranceType: "请选择您需要的保险类型",
        insuranceCategoryQuestion: "请您选择受保的性质",
        insuranceCategory: "受保的性质",
        email: "邮箱地址",
        date: "日期",
        companyName: "公司名称",
        companyLegalStatus: "公司的司法形式",
        companySiren: "公司注册号码",
        companySiret: "公司注册号码",
        NAF: "行业代码",
        companyAddress: "地址",
        companyAddressInfo: "地址补充",
        companyPostalCode: "邮编",
        companyCity: "城市",
        companyCountry: "国家",
        companyTel: "电话号码",
        companyMobile: "手机号码",
        personalCivility: "称呼",
        personalLastName: "姓",
        personalMaidenName: "曾用姓",
        personalFirstName: "名",
        personalFullName: "姓名（中文)",
        personalSex: "性别",
        personalAddress: "地址",
        personalAddressInfo: "地址补充",
        personalPostalCode: "邮编",
        personalCity: "城市",
        personalCountry: "国家",
        personalTel: "电话号码",
        personalFax: "传真号码",
        personalMobile: "手机号码",
        personalBirth: "出生日期",
        onlyLetters: "只能是拼音或法语",
        specialLocalType: "类型",
        specialInsuredAmount: "受保货值金额",
        specialEquipmentAmount: "设备",
        specialGlassAmount: "玻璃",
        specialContentAmount: "货物",
        specialITAmount: "计算机的受保金额",
        specialInsuredSurface: "受保的面积",
        specialInsuredFloor: "受保的层数",
        specialCAn1: "去年营业额",
        specialLoanAmount: "贷款的金额",
        specialLoanInterest: "贷款的利息",
        specialLoanDate: "贷款的日期",
        specialBank: "贷款的银行",
        specialIBAN: "银行的账号 IBAN",
        specialCarBrand: "汽车的牌子",
        specialCarRef: "汽车的内饰的型号",
        specialCarPower: "汽车的马力",
        specialCarKm: "汽车的公里数",
        specialCarKmAnnual: "预计的年驾驶公里数",
        specialGrayCardDate: "汽车灰卡时间",
        specialCarImmaDate: "汽车第一上牌时间",
        specialDrivingLicenseDate: "驾驶证日期",
        specialDrivingLicenseValidDate: "驾驶证有效日期",
        specialCarInsuranceType: "汽车保险类型",
        specialCarUsing: "汽车的使用情况",
        specialWorkAddress: "上班地址",
        specialWorkPostalCode: "上班邮编",
        specialWorkCity: "上班地址城市",
        specialBMPoint: "奖罚点数",
        specialGarageType: "车房的情况",
        specialCarlicenseNumber: "同一户有多少驾驶证",
        specialCarNumber: "同一户有多少辆车",
        specialSecondDriver: "第二驾驶人",
        specialSpouseLastname: "配偶姓",
        specialSpouseFirstname: "配偶名",
        specialMatrimonialRegime: "婚姻制度",
        specialLifeInsuranceType: "人寿保险类型",
        specialCarModel: "汽车的型号",
        specialInsuranceVol: "是否要保偷盗",
        specialVol: "保偷盗",
        specialInsuranceVolMontant: "金额",
        specialInsuranceResponsible: "是否要保责任险",
        specialEmployeeNumber: "员工人数",
        specialInsuranceLoss: "是否要保亏损险",
        specialInsuranceSecurityEquipment: "受保的安全设备",
        specialInsuranceFireSafetyEquipment: "受保的消防设备",
        specialInsuranceAlertEquipment: "受保的警报设备",
        specialElectricEquipmentCheck: "是否有做电设备的年检",
        specialFireSafetyEquipmentCheck: "是否有做防火设备的年检",
        specialLeaseNature: "租赁的性质",
        specialInsuredRoomNumber: "受保的房间数",
        specialRCSNum: "RCS号",
        specialCAN: "预计今年销售额",
        specialActivityCode: "活动代号",
        specialHabitationType: "类型",
        uploadCompanyKbis: "公司K-bis",
        uploadSCIKbis: "房地产公司K-BIS",
        uploadIdentity: "身份证正反面",
        uploadIdentityBack: "身份证背面",
        uploadGrayCard: "灰卡正反面",
        uploadGrayCardBack: "灰卡背面",
        uploadDriverLicense: "驾驶证正反面",
        uploadDriverLicenseBack: "驾驶证背面",
        uploadInsuranceRecord: "保险记录",
        uploadOldContract: "原来的保险合同",
        uploadRib: "RIB",
        uploadCompanyRib: "公司RIB",
        uploadAmortizationSchedule: "摊销时间表",
        uploadLoanContract: "贷款合同",
        specialSCI: "是否房地产公司",
        specialCarViolationDate: "违规日期",
        specialCarViolation: "最近三年是否违规",
        specialInsuranceAddressPerSame: "受保地址是否为个人住址",
        specialInsuranceAddressProSame: "受保地址是否为公司地址",
        personalIdentityType: "身份证类型",
        addPerson2InContract: "Co-souscription",
        companyOwnedHouse: "该不动产是否属于一个公司 (房地产公司, 有限责任公司 或 其它类型公司)",
        personBorrowWith: "您贷款的名义",
        allCompanyDrivers: "全部员工",
        contactPerson: "联系人",
        coSubscriber: "Co-souscripteur",
        familySituation: "婚姻状况",
        coBorrower: "共同借款人",
        secondDriverInsurance: "加上第二驾驶人",
        mainDriver: "主驾驶人",
        profession: "职业",
        howGetDriverLicence: "Comment avez-vous obtenu votre permis de conduire",
        hasYouBeenInTheSituation: "Avez-vous déjà été dans l'une de ces situations",
        in3Years: "Dans les 3 dernières années",
        in5Years: "Dans les 5 dernières années",
        driverLicenceCancelled: "三年内是否被吊销驾驶证",
        lossNumIn3Years: "Nombre de sinistre(s) déclarés au cours des 36 derniers mois",
        grossMargin: "毛利润",
        uploadOther: "其它材料",
        uploadLease: "合同",
        uploadPhotoOneMonth: "一月内的照片",
        day: "日",
        month: "月",
        year: "年",
        duration: "期限",
        borrowerRate1: "贷款人1的保险份额",
        borrowerRate2: "贷款人2的保险份额",
        oldContractDueDate: "到期日期",
        cancellationLetter: "需要解约信",
        expectedStartDateQuestion: "期望的保险合同生效日期",
        expectedStartDate: "保险合同生效日期",
        acceptedPaymentMethodsQuestion: "您接受的付款方式",
        acceptedPaymentMethods: "付款方式",
        upload: "上传",
        carRegistrationNumber: "车牌号",
        gerant: "主管人",
        houseOwner: "不动产拥有者",
        localContentAmount: "店铺内物品金额",
        latestVerificationDate: "最近检查的日期",
        smoker: "吸烟者",
        insuranceProportion: "保险占比",
        creditRedemption: "贷款回购",
        newDuration: "新的期限",
        redemptionAmount: "回购金额",
        loanAmountBeforeRedemption: "剩余金额",
        loanDurationBeforeRedemption: "剩余期限",
        currentInsuranceCompany: "当前保险公司",
        insuranceType: "保险类型",
        use: "用途",
        energy: "能源",
        assistant0: "零公里支持",
        juridicProtection: "司法保护"
    },
    options: {
        contractType: {
            new: "新合同",
            change: "换合同"
        },
        insuranceType: {
            localPro: "商业场所保险",
            habitation: "个人场所保险",
            responsible: "责任保险，经营亏损保险",
            transport: "货物运输保险",
            borrow: "借款人保险",
            life: "人寿保险",
            vehicle: "汽车保险",
        },
        insuranceCategory: {
            pro: "公司",
            per: "个人"
        },
        companyLegalStatus: {
            sarl: "有限责任公司",
            sa: "股份有限公司",
            eurl: "个体户",
            groupe: "集团有限公司",
            sci: "房地产公司",
            other: "其它",
        },
        realEstateCompanyLegalStatus: {
            sarl: "有限责任公司",
            sci: "房地产公司",
            other: "其它",
        },
        personalCivility: {
            miss: "小姐",
            ms: "女士",
            mr: "先生"
        },
        personalSex: {
            m: "男",
            f: "女"
        },
        specialLocalType: {
            warehouse: "仓库",
            shop: "店铺",
            studio: "工厂",
            restaurant: "餐馆",
            bar: "酒吧, TABAC",
            other: "其它"
        },
        specialCarInsuranceType: {
            thirdParty: "单保",
            thirdPartyAndVol: "单保加偷",
            allRisk: "全保",
        },
        specialCarUsing: {
            pro: "公用",
            per: "私用",
            perPlusWork: "私用加上班",
            both: "公私两用",
        },
        specialGarageType: {
            public: "公共车位",
            private: "私人车房",
            box: "私人密封车房",
        },
        specialMatrimonialRegime: {
            mariageShare: "婚后财产共享制",
            seperate: "财产分割制",
            allShare: "全部财产共有制",
            partShare: "部分财产共有制",
        },
        specialLifeInsuranceType: {
            mutual: "医疗附加险",
            foresight: "Prévoyance",
            keyman: "Homme-clés",
            life: "人寿保险",
            investments: "投资",
        },
        specialInsuranceSecurityEquipment: {
            glass: "Porte en verre",
            curtain: "Rideau métallique",
            metal: "Porte métallique",
            wood: "Porte en bois"
            // armored: "Blindée",
        },
        specialInsuranceFireSafetyEquipment: {
            // smokeDetector: "Détecteur(s) de fumée",
            automaticExtinguisher: "Sprinkler",
            armedFireFaucet: "RIA",
            smokeEvacuationSystem: "Désenfumage",
            fireExtinguisher: "Extincteur",
        },
        specialInsuranceAlertEquipment: {
            none: "Aucune",
            alarm: "Alarme",
            surveillance: "Vidéo-surveillance",
            agency: "Société-surveillance",
        },
        specialLeaseNature: {
            ownerOccupied: "业主自住",
            ownerNonOccupied: "业主出租",
            lodger: "租客",
        },
        specialHabitationType: {
            apartment: "公寓",
            house: "别墅",
            other: "其它",
        },
        specialInsuranceAddressSame: {
            true: "是",
            false: "否",
        },
        monthAbbr: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
        personBorrowWith: {
            self: "自己",
            withPerson: "共同借款人",
            // withCompany: "以公司名义 (房地产公司, 有限责任公司 或 其它类型公司)"
        },
        familySituation: {
            single: "单身",
            married: "已婚",
            pacs: "Pacs",
            maritalLife: "Vie maritale",
            separate: "Séparé(e)",
            divorced: "离异",
            widower: "Veuf(ve)"
        },
        localProLeaseNature: {
            ownerOccupied: "业主自用",
            ownerNonOccupied: "业主出租",
            lodger: "租客",
            lodgerOnBehalfOfOwner: "租客加房东保险"
        },
        paymentMethod: {
            check: "支票",
            bankCard: "银行卡",
            monthlyAutoBankPayment: "银行按月分期扣款",
            quarterlyAutoBankPayment: "银行按季度分期扣款",
            yearlyAutoBankPayment: "银行按年分期扣款",
        },
        jobTitleM: {
            manager: "Directeur",
            clientAdvisor: "Conseiller clientèle",
            clientAdvisorAssistant: "Assistant conseiller clientèle"
        },
        jobTitleF: {
            manager: "Directrice",
            clientAdvisor: "Conseillère clientèle",
            clientAdvisorAssistant: "Assistante Conseillère Clientèle"
        },
        jobTitleAbbr: {
            manager: "Directeur(trice)",
            clientAdvisor: "Conseiller(e)",
            clientAdvisorAssistant: "Assistant(e)"
        },
        loanType: {
            realEstate: "不动产贷款",
            car: "汽车贷款",
            consumption: "消费贷款",
            pro: "公司贷款",
            other: "其它"
        },
        realEstateLoanType: {
            principleResidence: "主要自住房",
            rentalInvestment: "投资房",
            professionalPremises: "商用地产"
        },
        energyType: {
            fuel: "汽油",
            diesel: "柴油",
            electric: "电动",
            hybrid: "混合"
        }
    },
    messageError: {
        empty: "请填写必填项！",
        format: "格式不对！",
        uploadMessage: "上传错误",
        imageType: "您只能上传JPG，PNG，DOC和PDF文件！",
        imageSize: "文件必须小于6MB！",
        password: "Le mot de passe doit être de 8 caractères minimum et contenir au moins 1 lettre et 1 chiffre"
    },
    button: {
        next: "下一页",
        prev: "前一页",
        save: "保存",
        finish: "完成",
        goBackHome: "返回首页",
        createNewQuotation: "新建询价",
        login: "登陆",
        cancel: "取消"
    },
    values: {
        country: "法国",
    },
    home: {
        content: {
            name: "德林保险有限公司",
            intro: "是一家独立保险经纪公司，和法国以及世界上20多家知名保险公司合作，在行业内有二十多年的经验，为专业人士和个人选择了一系列保险产品：",
            list: [
                "商业场所保险(店铺，餐馆, 仓库, 工厂，酒吧TABAC, 等等)",
                "个人场所保险(公寓，别墅, 车房, 等等)",
                "责任保险, 经营亏损保险",
                "货物运输保险",
                "汽车保险(汽车，货车，摩托车)",
                "借款人保险",
                "人寿健康保险",
            ],
            text: [
                "联系我们以便获得最优惠的价格，并通过您的多个保险合同来节省费用。",
                "我们会陪伴您在保险变更的所有步骤和优化您的合同条件",
                "电话联系方式 + 33 666 924 888",
            ],
            textMail: "或邮件",
            mail: "delinassurances@gmail.com",
        },
        button: "询价",
    },
    error: {
        message: "错误",
        description: "创建您的报价请求时出现错误，请重试！",
        descriptionModify: "修改您的报价请求时出现错误，请重试！",
    },
    success: {
        message: "成功",
        description: "恭喜，您的报价请求已创建！ 您将收到一封确认电子邮件。 在这封电子邮件中，您将有一个链接来修改您的报价请求。 非常感谢您！",
        descriptionModify: "恭喜，您的报价请求更改成功！",
    },
    user: {
        email: "邮箱",
        password: "密码",
        loginError: "密码或邮箱错误",
        logout: "退出登陆",
        profile: "个人信息",
        newPassword: "Nouveau mot de passe"
    },
};
