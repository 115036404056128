export const SET_USER = 'SET_USER';
export const SET_GLOBALSPIN = 'SET_GLOBALSPIN';
export const SET_GLOBALCONFIRM = 'SET_GLOBALCONFIRM';
export const SET_SERVICE_USER = 'SET_SERVICE_USER';
export const setUser = (user) => {
    return {type: SET_USER, value: user};
};
export const setServiceUser = (user) => {
    return {type: SET_SERVICE_USER, value: user};
};
export const setGlobalSpin = (show) => {
    return {type: SET_GLOBALSPIN, value: show};
}
export const setGlobalConfirm = (obj) => {
    return {type: SET_GLOBALCONFIRM, value: obj};
}