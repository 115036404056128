import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import generateController from '../../controller/Content/InsuranceTypeController';
import { List, Card } from 'antd';
import { insuranceTypeLogo } from '../../../../constant/LogoConstant';
import InsuranceTypeForm from './InsuranceTypeForm';

const InsuranceType = (props) => {
    const insuranceType = props.options.insuranceType;
    const value = props.values.insuranceType;
    return (
        <Fragment>
            {!value ? <List className="insurance-list"
                grid={{ gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 4,
                    lg: 4,
                    xl: 4,
                    xxl: 4,}}
                dataSource={Object.keys(insuranceType)}
                renderItem={item => (
                    <List.Item>
                        <Card cover={
                            <FontAwesomeIcon className='insurance-logo-lg' icon={insuranceTypeLogo[item]} />
                        }
                        onClick={() => {
                            props.addValue('insuranceType', item);
                            props.setSpecialValues({});
                        }}
                        >{insuranceType[item]}</Card>
                    </List.Item>
                )}
            /> : <InsuranceTypeForm form={props.form} disabled={props.disabled}></InsuranceTypeForm>}
        </Fragment>
    );
}

export default generateController(InsuranceType);
