import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// the translations
// (tip move them in a JSON file and import them)
const resources = {
    fr: {
        common: {
            login: "Se connecter",
            password: "Mot de passe",
            loginFailed: " Le mot de passe est incorrect. Veuillez réessayer.",
            saved: "Enregistré",
            savedMessage: "Cette action a bien été enregistrée.",
            error: "Erreur",
            errorMessage: "Une erreur s'est produite lors de votre action."
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "fr",
        defaultNS: 'common',
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;