import React, { Fragment } from 'react';
import generateController from '../../controller/SpecialFields/TransportController';
import Amount from '../../../form/FieldTemplate/AmountComponent';

const Transport = props =>
    <Fragment>
        <Amount
            form={props.form}
            name="specialInsuredAmount"
            label={props.label.insuranceAmount}
            value={props.values.insuranceAmount}
            onBlur={(value) => props.addValue('insuranceAmount', value)}
            disabled={props.disabled}
        />
    </Fragment>;

const TransportController = generateController(Transport);
export default TransportController;
