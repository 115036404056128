import {connect} from 'react-redux';
import {addPersonalValue, addPersonalContactValue, addBasicValue, addSpecialValue, addCommonValue, addOwnerCompanyValue} from '../../../store/actions/formValuesActions';
import { fetchPostalCode } from '../../../store/actions/postalCodeAction';

const mapStateToProps = (state) => {
    const personalValues = state.formValues.quote.person;
    const personal2Values = state.formValues.quote.person2;
    const commonData = state.formValues.commonData || {};
    const ownerCompany = commonData.company || {};
    ownerCompany.contact = ownerCompany.contact || {};
    const ownerIsCompany = commonData.ownerIsCompany;
    const specialInfo = state.formValues.specialInfo || {};
    return {
        label: {
            civility: state.translation.label.personalCivility,
            lastName: state.translation.label.personalLastName,
            maidenName: state.translation.label.personalMaidenName,
            firstName: state.translation.label.personalFirstName,
            fullName: state.translation.label.personalFullName,
            sex: state.translation.label.personalSex,
            tel: state.translation.label.personalTel,
            fax: state.translation.label.personalFax,
            mobile: state.translation.label.personalMobile,
            birth: state.translation.label.personalBirth,
            address: state.translation.label.personalAddress,
            addressInfo: state.translation.label.personalAddressInfo,
            postalCode: state.translation.label.personalPostalCode,
            city: state.translation.label.personalCity,
            country: state.translation.label.personalCountry,
            email: state.translation.label.email,
            familySituation: state.translation.label.familySituation,
            specialMatrimonialRegime: state.translation.label.specialMatrimonialRegime,
            coSubscriber: state.translation.label.coSubscriber,
            coBorrower: state.translation.label.coBorrower,
            secondDriver: state.translation.label.specialSecondDriver,
            secondDriverLicenseDate: state.translation.label.specialDrivingLicenseDate,
            secondDriverLicenseValidDate: state.translation.label.specialDrivingLicenseValidDate,
            mainDriver: state.translation.label.mainDriver,
            lossNumIn3Years: state.translation.label.lossNumIn3Years,
            driverLicenceCancelled: state.translation.label.driverLicenceCancelled,
            drivingLicenseDate: state.translation.label.specialDrivingLicenseDate,
            drivingLicenseValidDate: state.translation.label.specialDrivingLicenseValidDate,
            secondDriverInsurance: state.translation.label.secondDriverInsurance,
            date: state.translation.label.date,
            duration: state.translation.label.duration,
            month: state.translation.label.month,
            houseOwner: state.translation.label.houseOwner,
            name: state.translation.label.companyName,
            companyLegalStatus: state.translation.label.companyLegalStatus,
            siren: state.translation.label.companySiren,
            siret: state.translation.label.companySiret,
            smoker: state.translation.label.smoker,
            insuranceProportion: state.translation.label.insuranceProportion,
            bmPoint: state.translation.label.specialBMPoint,
        },
        options: {
            civility: state.translation.options.personalCivility,
            sex: state.translation.options.personalSex,
            specialMatrimonialRegime: state.translation.options.specialMatrimonialRegime,
            familySituation: state.translation.options.familySituation,
            lossNumIn3Years: {0: '0', 1: '1', 2: '2', 3: '3', 4: '4', 5: '5', 6: '>=6'},
            companyLegalStatus: state.translation.options.companyLegalStatus,
        },
        values: {
            person: personalValues || {},
            personContact: personalValues ? (personalValues.contact || {}) : {},
            person2: personal2Values || {},
            person2Contact: (personal2Values || {}).contact || {},
            insuranceType: state.formValues.quote.insuranceType,
            insuranceCategory: state.formValues.quote.insuranceCategory,
            addPerson2InContract: state.formValues.quote.addPerson2InContract,
            country: state.translation.values.country,
            driver: state.formValues.specialInfo.driver || {},
            secondDriver: state.formValues.specialInfo.secondDriver || {},
            ownerCompany: ownerCompany,
            ownerIsCompany: ownerIsCompany,
            borrower1Smoker: specialInfo.borrower1Smoker,
            borrower2Smoker: specialInfo.borrower2Smoker,
            borrower1Proportion: specialInfo.borrower1Proportion,
            borrower2Proportion: specialInfo.borrower2Proportion
        },
        language: state.language
    };
};

const mapDispatchToProps = (dispatch) => {
    return { 
        addPersonValue: (key, value) => { dispatch(addPersonalValue('person', key, value)) },
        addPersonContactValue: (key, value) => { dispatch(addPersonalContactValue('person', key, value)) },
        addPerson2Value: (key, value) => { dispatch(addPersonalValue('person2', key, value)) },
        addPerson2ContactValue: (key, value) => { dispatch(addPersonalContactValue('person2', key, value)) },
        addBasicValue: (key, value) => { dispatch(addBasicValue(key, value)) },
        getPostalCode: (value) => { dispatch(fetchPostalCode(value)) },
        addSpecialValue: (key, value) => { dispatch(addSpecialValue(key, value)); },
        addCommonValue: (key, value) => { dispatch(addCommonValue(key, value)) },
        addOwnerCompanyValue: (key, value, contact) => { dispatch(addOwnerCompanyValue(key, value, contact)) },
    };
};

const PersonalInfoController =
    (PersonalInfoComponent) => {return connect(mapStateToProps, mapDispatchToProps)(PersonalInfoComponent)};

export default PersonalInfoController;
