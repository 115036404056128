import {SET_TRANSLATION} from '../actions/translationActions';

const translation = (state = {}, action) => {
    switch (action.type) {
        case SET_TRANSLATION:
            return action.translation;
        default:
            return state;
    }
};

export default translation;
