import React from 'react';
import { useStore, useStoreDispatch } from '../../core/store';
import { setGlobalConfirm } from '../../core/action';
import { Dialog, DialogContent, DialogActions, Button, DialogContentText } from '@material-ui/core';

export default function GlobalConfirm() {
    const { globalConfirm } = useStore();
    const dispatch = useStoreDispatch();
    const open = !!globalConfirm;
    const click = (v) => {
        globalConfirm.resolve(v);
        dispatch(setGlobalConfirm(null))
    }
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Souhaitez-vous confirmer cette action ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => click(false)} color="primary">
                    NON
          </Button>
                <Button onClick={() => click(true)} color="primary" autoFocus>
                    OUI
          </Button>
            </DialogActions>
        </Dialog>

    );
}
