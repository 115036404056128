import {connect} from 'react-redux';
import {addSpecialValue} from '../../../store/actions/formValuesActions';

const mapStateToProps = (state) => {
    const specialValues = state.formValues.specialInfo;
    return {
        label: {
            insuranceAmount: state.translation.label.specialInsuredAmount,
        },
        values: {
            insuranceAmount: specialValues && specialValues.insuranceAmount ? specialValues.insuranceAmount : null,
        }
    };
};

const mapDispatchToProps = (dispatch) => {
    return { 
        addValue: (key, value) => { dispatch(addSpecialValue(key, value)) },
    };
};

const TransportController =
    (TransportComponent) => {return connect(mapStateToProps, mapDispatchToProps)(TransportComponent)};

export default TransportController;
