import {connect} from 'react-redux';
import {fetchFormValues, addSpecialValue} from '../../store/actions/formValuesActions';
import {modifyValues} from '../../store/actions/quoteAction';
import {setLoading} from '../../store/actions/loadingAction';

const mapStateToProps = (state) => {
    return {
        title: {
            contarctType: state.translation.title.general,
            company: state.translation.title.company,
            personal: state.translation.title.personal,
            specialInfo: state.translation.options.insuranceType[state.formValues.quote.insuranceType],
            upload: state.translation.title.upload,
            comment: state.translation.title.comment,
            detail: state.translation.title.detail,
        },
        values: state.formValues,
        insuranceCategory: state.formValues.quote.insuranceCategory,
        loading: state.loading,
        nextText: state.translation.button.save,
        insuranceType: state.formValues.quote.insuranceType,
        allCompanyDrivers: state.formValues.specialInfo ? state.formValues.specialInfo.allCompanyDrivers : null,
        error: {
            message: state.translation.error.message,
            description: state.translation.error.descriptionModify,
        },
        success: {
            message: state.translation.success.message,
            description: state.translation.success.descriptionModify,
        },
        quote: state.quote,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFormValues: (id, isId) => { dispatch(fetchFormValues(id, isId)) },
        addSpecialValue: (key, value) => { dispatch(addSpecialValue(key, value)) },
        putFormValues: () => { dispatch(modifyValues()) },
        setLoading: (value) => { dispatch(setLoading(value)) },
    };
};

const ModifyController =
    (ModifyComponenet) => {return connect(mapStateToProps, mapDispatchToProps)(ModifyComponenet)};

export default ModifyController;
