import { SET_SPINNING } from '../actions/spinAction';

const spin = (state = {spinning: false}, action) => {
    const s = {...state};
    switch (action.type) {
        case SET_SPINNING:
            s.spinning = action.spinning;
            return s;
        default:
            return s;
    }
};

export default spin;