import { Col, Row } from 'antd';
import React, { Fragment } from 'react';
import { carUsingWork, colXL12, colXL16, colXL24, colXL6 } from '../../../../constant/DevisContant';
import AutoComplete from '../../../form/FieldTemplate/AutoCompleteComponent';
import CheckboxGroup from '../../../form/FieldTemplate/CheckboxGroupComponent';
import CityAutoComplete from '../../../form/FieldTemplate/CityAutoCompleteComponent';
import CustomDate from '../../../form/FieldTemplate/DateDropdownListComponent';
import PostalCodeText from '../../../form/FieldTemplate/PostalCodeComponent';
import Select from '../../../form/FieldTemplate/SelectComponent';
import Text from '../../../form/FieldTemplate/TextComponent';
import GreyCardTooltip from '../../../widgets/greyCardTooltip';
import generateController from '../../controller/SpecialFields/VehicleController';
import CustomSwitch from '../../../form/FieldTemplate/SwitchComponent';
class Vehicle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            models: this.props.cars ? this.props.cars.models[this.props.values.carBrand] : [],
            secondDriver: this.props.values.secondDriver,
            carUsing: this.props.values.carUsing,
        };
    }
    componentDidMount() {
        if (this.props.cars === null) {
            this.props.getCars();
        }
    }
    render() {
        let carCVLabel = <Fragment>{this.props.label.carPower}&nbsp;(P.6)&nbsp;<GreyCardTooltip fileName="carte-grise-specimen-p6"></GreyCardTooltip></Fragment>
        let carModel = <Fragment>{this.props.label.carModel}&nbsp;(D.3)&nbsp;<GreyCardTooltip fileName="carte-grise-specimen-d3"></GreyCardTooltip></Fragment>
        let carBrand = <Fragment>{this.props.label.carBrand}&nbsp;(D.1)&nbsp;<GreyCardTooltip fileName="carte-grise-specimen-d1"></GreyCardTooltip></Fragment>
        let carImmaDate = <Fragment>{this.props.label.carImmaDate}&nbsp;(B)&nbsp;<GreyCardTooltip fileName="carte-grise-specimen-b"></GreyCardTooltip></Fragment>
        let grayCardDate = <Fragment>{this.props.label.grayCardDate}&nbsp;(I)&nbsp;<GreyCardTooltip fileName="carte-grise-specimen-i"></GreyCardTooltip></Fragment>
        return (
            <Fragment>
                <Row>
                    <Col {...colXL12}>
                        <AutoComplete
                            form={this.props.form}
                            name="specialCarBrand"
                            dataSource={this.props.cars ? this.props.cars.brands : []}
                            label={carBrand}
                            value={this.props.values.carBrand}
                            onChange={(value) => {
                                this.props.addValue('carBrand', value);
                                this.setState({
                                    models: this.props.cars.models[value]
                                });
                            }}
                            required
                            left={14}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL12}>
                        <AutoComplete
                            form={this.props.form}
                            name="specialCarModel"
                            dataSource={this.state.models}
                            label={carModel}
                            value={this.props.values.carModel}
                            onChange={(value) => this.props.addValue('carModel', value)}
                            required
                            left={14}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL12}>
                        <Text
                            form={this.props.form}
                            name="specialCarRef"
                            label={this.props.label.carRef}
                            value={this.props.values.carRef}
                            onBlur={(value) => this.props.addValue('carRef', value)}
                            left={14}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL12}>
                        <Text
                            form={this.props.form}
                            name="specialCarPower"
                            label={carCVLabel}
                            value={this.props.values.carPower}
                            onBlur={(value) => this.props.addValue('carPower', value)}
                            number
                            power
                            left={14}
                            required
                            disabled={this.props.disabled}
                        />
                    </Col>

                    <Col {...colXL12}>
                        <Text
                            form={this.props.form}
                            name="carRegistrationNumber"
                            label={this.props.label.carRegistrationNumber}
                            value={this.props.values.registrationNumber}
                            onBlur={(value) => this.props.addValue('registrationNumber', value)}
                            left={14}
                            disabled={this.props.disabled}
                            required
                            upperCase
                        />
                    </Col>
                    <Col {...colXL12}>
                        <Select
                            form={this.props.form}
                            name="energyType"
                            label={this.props.label.energy}
                            options={this.props.options.energyType}
                            value={this.props.values.energyType}
                            onChange={(value) => {
                                this.props.addValue('energyType', value);
                            }}
                            left={10}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL12}>
                        <CustomDate
                            form={this.props.form}
                            name="specialCarImmaDate"
                            label={carImmaDate}
                            value={this.props.values.carImmaDate}
                            onChange={(value) => this.props.addValue('carImmaDate', value)}
                            left={14}
                            right={10}
                            required
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL12}>
                        <CustomDate
                            form={this.props.form}
                            name="specialGrayCardDate"
                            label={grayCardDate}
                            value={this.props.values.grayCardDate}
                            onChange={(value) => this.props.addValue('grayCardDate', value)}
                            left={14}
                            right={10}
                            required
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL12}>
                        <Text
                            form={this.props.form}
                            name="specialCarKm"
                            label={this.props.label.carKm}
                            value={this.props.values.carKm}
                            onBlur={(value) => this.props.addValue('carKm', value)}
                            number
                            km
                            left={14}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL12}>
                        <Text
                            form={this.props.form}
                            name="specialCarKmAnnual"
                            label={this.props.label.carKmAnnual}
                            value={this.props.values.carKmAnnual}
                            onBlur={(value) => this.props.addValue('carKmAnnual', value)}
                            number
                            km
                            left={14}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL16}>
                        <Select
                            form={this.props.form}
                            name="specialCarUsing"
                            label={this.props.label.carUsing}
                            options={this.props.options.carUsing}
                            value={this.props.values.carUsing}
                            onChange={(value) => {
                                this.props.addValue('carUsing', value);
                                this.setState({
                                    carUsing: value,
                                });
                            }}
                            left={10}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    {(carUsingWork.indexOf(this.state.carUsing) > -1) &&
                        <Fragment>
                            <Col {...colXL24}>
                                <Text
                                    form={this.props.form}
                                    name="specialWorkAddress"
                                    label={this.props.label.workAddress}
                                    value={this.props.values.workAddress}
                                    onBlur={(value) => this.props.addValue('workAddress', value)}
                                    disabled={this.props.disabled}
                                    left={6}
                                />
                            </Col>
                            <Col {...colXL12}>
                                <PostalCodeText
                                    form={this.props.form}
                                    name="specialWorkPostalCode"
                                    label={this.props.label.workPostalCode}
                                    value={this.props.values.workPostalCode}
                                    updateCity={(value) => {
                                        this.props.addValue('workCity', value);
                                    }}
                                    updateCP={(value) => this.props.addValue('workPostalCode', value)}
                                    disabled={this.props.disabled}
                                />
                            </Col>
                            <Col {...colXL12}>
                                <CityAutoComplete
                                    form={this.props.form}
                                    name="specialWorkCity"
                                    label={this.props.label.workCity}
                                    value={this.props.values.workCity}
                                    updateCity={(value) => {
                                        this.props.addValue('workCity', value);
                                    }}
                                    updateCP={(value) => this.props.addValue('workPostalCode', value)}
                                    disabled={this.props.disabled}
                                />
                            </Col>
                        </Fragment>
                    }
                    <Col {...colXL12}>
                        <Select
                            form={this.props.form}
                            name="specialGarageType"
                            label={this.props.label.garageType}
                            options={this.props.options.garageType}
                            value={this.props.values.garageType}
                            onChange={(value) => this.props.addValue('garageType', value)}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL24}>
                        <Row>
                            <Col {...colXL12}>
                                <CheckboxGroup
                                    form={this.props.form}
                                    name="specialCarInsuranceType"
                                    label={this.props.label.carInsuranceType}
                                    options={this.props.options.carInsuranceType}
                                    value={this.props.values.carInsuranceType ? this.props.values.carInsuranceType : []}
                                    onChange={(value) => this.props.addValue('carInsuranceType', value)}
                                    left={6}
                                    disabled={this.props.disabled}
                                />
                            </Col>
                            <Col {...colXL6}>
                                <CustomSwitch
                                    form={this.props.form}
                                    name="assistant0"
                                    label={this.props.label.assistant0}
                                    value={this.props.values.assistant0Km}
                                    onChange={(value) => {
                                        this.props.addValue('assistant0Km', value);
                                    }}
                                    disabled={this.props.disabled}
                                    className="xl-panding-left-60"
                                />
                            </Col>
                            <Col {...colXL6}>
                                <CustomSwitch
                                    form={this.props.form}
                                    name="juridicProtection"
                                    label={this.props.label.juridicProtection}
                                    value={this.props.values.juridicProtection}
                                    onChange={(value) => {
                                        this.props.addValue('juridicProtection', value);
                                    }}
                                    disabled={this.props.disabled}
                                />
                            </Col>
                        </Row>

                    </Col>

                </Row>
            </Fragment>
        );
    }
}

const VehicleController = generateController(Vehicle);
export default VehicleController;
