import { Icon, Popover } from 'antd';
import React from 'react';

class GreyCardTooltip extends React.Component {
  state = {
    visible: false,
  };

  hide = () => {
    this.setState({
      visible: false,
    });
  };

  handleVisibleChange = visible => {
    this.setState({ visible });
  };

  render() {
    return (
      <Popover
        content={<img alt="" src={'/images/' + this.props.fileName + '.jpg'}></img>}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
      >
        <Icon type="info-circle" />
      </Popover>
    );
  }
}

export default GreyCardTooltip;