import {SET_QUOTE} from '../actions/quoteAction';

const quote = (state = null, action) => {
    switch (action.type) {
        case SET_QUOTE:
            return action.quote;
        default:
            return state;
    }
};

export default quote;
