import React from 'react';
import { getFormItemStyle } from '../../../constant/DevisContant';
import { Form, Select } from 'antd';
import { connect } from 'react-redux';
import FormItemLabel from './FormItemLabelComponent';
import * as moment from 'moment';

const FormItem = Form.Item;
const Option = Select.Option;

class DateComponent extends React.Component {
    constructor(props) {
        super(props);
        const { value } = props;
        if (value) {
            this.state = { year: value.year(), month: value.month() + 1, date: value.date() };
        } else {
            this.state = { year: undefined, month: undefined, date: undefined };
        }
        let dates = new Array(31).fill(null).map((item, index) => index + 1);
        let months = new Array(12).fill(null).map((item, index) => index + 1);
        const minYear = 1900;
        const maxYear = props.maxYear || new Date().getFullYear();
        let years = new Array(maxYear - minYear + 1).fill(null).map((item, index) => maxYear - index);
        this.months = months;
        this.years = years;
        this.dates = dates;
    }
    
    onDateChange = (y, m, d) => {
        let {date, month, year} = this.state;
        if (y !== undefined) {
            if (y <= 0) {
                this.props.onChange(undefined);
            } else if (date && month) {
                this.props.onChange(moment(`${y}-${month.toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}T00:00:00Z`));
            }
            this.setState({year: y});
        }
        if (m !== undefined) {
            if (m === 0) {
                this.props.onChange(undefined);
            } else if (date && year) {
                this.props.onChange(moment(`${year}-${m.toString().padStart(2, '0')}-${date.toString().padStart(2, '0')}T00:00:00Z`));
            }
            this.setState({month: m});
        }
        if (d !== undefined) {
            if (d === 0) {
                this.props.onChange(undefined);
            } else if (year && month) {
                this.props.onChange(moment(`${year}-${month.toString().padStart(2, '0')}-${d.toString().padStart(2, '0')}T00:00:00Z`));
            }
            this.setState({date: d});
        }
    }
    render() {
        let {date, month, year} = this.state;
        let {dates, months, years} = this;
        let {dateLabel} = this.props;
        return (
            <div className='ddl_date'>
                <Select placeholder={dateLabel.day} defaultValue={date} onChange={value => this.onDateChange(undefined, undefined, value)} disabled={this.props.disabled}>
                    {
                        dates.map((item, index) => <Option className='ddl_date_option' key={index} value={item}>{item === 0 ? <span>&nbsp;</span> : item.toString().padStart(2, '0')}</Option>)
                    }
                </Select>
                <Select placeholder={dateLabel.month}  defaultValue={month} onChange={value => this.onDateChange(undefined, value, undefined)} disabled={this.props.disabled}>
                    {
                        months.map((item, index) => <Option className='ddl_date_option' key={index} value={item}>{item === 0 ? <span>&nbsp;</span> : item.toString().padStart(2, '0')}</Option>)
                    }
                </Select>
                <Select placeholder={dateLabel.year}  defaultValue={year} onChange={value => this.onDateChange(value, undefined, undefined)} disabled={this.props.disabled}> 
                    {
                        years.map((item, index) => <Option className='ddl_date_option' key={index} value={item} >{item <= 0 ? <span>&nbsp;</span> : item}</Option>)
                    }
                </Select>
            </div>
        );
    }
}

function DateDropdown(props) {
    const { getFieldDecorator } = props.form;
    const formItemLayout = getFormItemStyle(props);
    let rules = [];
    if (props.required) {
        rules.push({ required: true, message: props.emptyMessage });
    }
    const { tooltip, label, value, disabled } = props;
    let v = null;
    if (value) {
        v = moment.utc(value);
    }
    return (
        <FormItem
            {...formItemLayout}
            label={
                <FormItemLabel label={label} tooltip={tooltip} />
            }
            className={props.className}
        >
            {getFieldDecorator(props.name, {
                initialValue: v,
                rules: rules,
            })(
                <DateComponent
                    maxYear={props.maxYear}
                    dateLabel={props.dateLabel}
                    disabled={disabled}
                    onChange={(d) => {
                        if (props.onChange) {
                            props.onChange(d);
                        }
                    }} />
            )}
        </FormItem>
    );
}

const mapStateToProps = (state) => {
    return {
        emptyMessage: state.translation.messageError.empty,
        monthAbbr: state.translation.options.monthAbbr,
        dateLabel: {
            day: state.translation.label.day,
            month: state.translation.label.month,
            year: state.translation.label.year,
        }
    };
};

const DateDropdownController = connect(mapStateToProps)(DateDropdown);
export default DateDropdownController;
