import http from '../core/http';
import httpForm from '../core/http-form';
import { nodeApiURL } from '../constant/http-constant';
const boApi = {
    service: {
        getServicecustomer: async id => await http.get('bo/servicecustomers/' + id),
        updateServicecustomer: async model => await http.put('bo/servicecustomers/' + model.serviceCustomerId, model),
        updateServiceAccount: async (id, model) => await http.put('bo/service/accounts/' + id, model),
        createServiceAccount: async model => await http.post('bo/service/accounts', model),
        createCustomerContract: async (customerId, model) => await http.post('bo/servicecontracts?customerId=' + customerId, model),
        getServiceAccounts: async customerId => await http.get('bo/serviceaccounts?serviceCustomerId=' + customerId),
        createServiceCustomer: async model => await http.post('bo/servicecustomers', model),
        getServiceCustomers: async () => await http.get('bo/servicecustomers'),
        searchTravelInsurances: async query => await http.get('bo/travelinsurances?' + query),
        changePassword: async (user) => {
            return await http.post('bo/service/account/changepassword', user);
        },
    },
    quoteRequests: {
        assign: value => http.post('bo/quoterequests/assign', value),
        getQuoteRequest: id => {
            return http.get('bo/quoterequests/' + id).then((formValues) => {
                if (!formValues) {
                    return null;
                }
                const { insuranceType, insuranceCategory } = formValues.quote;
                if (insuranceType) {
                    formValues.specialInfo = formValues[insuranceType] || {};
                }
                if (insuranceType === 'borrow') {
                    if (insuranceCategory === 'per' && formValues.specialInfo) {
                        formValues.quote.addPerson2InContract = formValues.specialInfo.personBorrowWith === 'withPerson';
                    }
                }
                if (insuranceType === 'vehicle') {
                    if (insuranceCategory === 'per' && formValues.specialInfo) {
                        formValues.quote.addPerson2InContract = formValues.specialInfo.secondDriver;
                    }
                }
                return formValues;
            })
        },
        searchQuotations: (request) => {
            return http.post('bo/quoterequests/search', request).then((result) => {
                if (result) {
                    result.result = result.result.map(i => {
                        if (i.insuranceCategory === 'per') {
                            i.companyName = '';
                            i.name = i.personName;
                        } else {
                            i.name = i.companyPersonName;
                        }
                        return i;
                    });
                    return result;
                } else {
                    return {};
                }
            });
        },
        getQuoteRequestPdf: id => {
            return http.get(nodeApiURL + 'bo/report/quoterequest/' + id);
        },
        saveQuoteRequest: (id, value) => {
            return http.put('bo/quoterequests/' + id, value)
        },
        deleteFile: (quoteRequestId, fileType, fileUid) => http.delete('bo/quoterequests/' + quoteRequestId + '/files/' + fileUid + '?fileType=' + fileType),
        addFile: (quoteRequestId, fileType, fileName, formData) => httpForm.post('bo/quoterequests/' + quoteRequestId + '/files?fileType=' + fileType + '&fileName=' + fileName, formData),
    },
    customerInsurances: {
        saveCustomerInsurance: (value) => http.post('bo/customerinsurances', value),
        getCustomerInsuranceByQuoteRequestId: qrId => http.get('bo/quoterequest/customerinsurance/' + qrId),
        deleteFile: (quoteRequestId, fileType, fileUid) => http.delete('bo/quoterequest/customerinsurance/' + quoteRequestId + '/files/' + fileUid + '?fileType=' + fileType),
        addFile: (quoteRequestId, fileType, fileName, formData) => httpForm.post('bo/quoterequest/customerinsurance/' + quoteRequestId + '/files?fileType=' + fileType + '&fileName=' + fileName, formData),
    },
    users: {
        getUsers: async () => {
            return await http.get('bo/users');
        },
        getClientAdvisors: async () => {
            return await http.get('bo/users/jobtitle/clientadvisor');
        },
        createUsers: async (user) => {
            return await http.post('bo/users', user);
        },
        updateUsers: async (user) => {
            return await http.put('bo/users/' + user.userId, user);
        },
        changePassword: async (user) => {
            return await http.post('bo/user/changepassword', user);
        },
        checktoken: async () => http.get('bo/user/checktoken'),
        login: async value => await http.post('bo/user/login', value),
    }
};

export default boApi;
