import React from 'react';
import FormAction from '../../form/FieldTemplate/FormActionComponent';
import {Form, Button} from 'antd';
import InsuranceTypeContent from './Content/InsuranceType';
import {connect} from 'react-redux';
import { getMainAreaY } from '../../../utils/window';
import { addBasicValue, setSpecialValues } from '../../store/actions/formValuesActions';

class FormComponent extends React.Component {
    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.onSubmit(values);
            }
        });
    }

    componentDidMount() {
        window.scrollTo(0, getMainAreaY());
    }
    
    render() {
        const {insuranceType, insuranceTypes, title, addValue, setSpecialValues} = this.props;
        return (
            <section>
                <div style={{display: 'flex'}}>
                    <h1>
                        {insuranceType ? insuranceTypes[insuranceType] : title}
                    </h1>
                    {insuranceType && <Button onClick={() => {
                        addValue('insuranceType', null);
                        setSpecialValues({});
                    }} type="primary" icon="edit" style={{marginLeft: '10px'}}><span>Modifier</span></Button>} 
                </div>
                <Form onSubmit={(e)=>this.handleSubmit(e)}>
                    <InsuranceTypeContent
                        form={this.props.form}
                    />
                    {insuranceType && <FormAction />}
                </Form>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        title: state.translation.title.insuranceType,
        insuranceType: state.formValues.quote.insuranceType,
        insuranceTypes: state.translation.options.insuranceType
    };
};

const mapDispatchToProps = (dispatch) => {
    return { 
        addValue: (key, value) => { 
            dispatch(addBasicValue(key, value));
        },
        setSpecialValues: (values) => { dispatch(setSpecialValues(values)) },
    };
};

const InsuranceTypeForm = Form.create()(connect(mapStateToProps, mapDispatchToProps)(FormComponent));
export default InsuranceTypeForm;
