import { api } from '../../../constant/Axios';
import { setCity } from './cityAction';

export const SET_POSTALCODE = 'SET_POSTALCODE';

export const setPostalCode = (postalCode) => {
    return { type: SET_POSTALCODE, postalCode };
};

const getPostalCode =
    (value) => api.get('communes?codePostal=' + value)
        .then(resp => resp.data);
const getCities = (value) => api.get('communes?nom=' + value)
.then(resp => resp.data);

export const fetchPostalCode = (value) =>
    (dispatch) => getPostalCode(value)
        .then(result => {
            let city = [];
            let postalCode = [];
            result.map((citiesAndPC) => {
                city.push(citiesAndPC.name);
                postalCode.push(citiesAndPC.postalCode);
                return citiesAndPC;
            });
            dispatch(setPostalCode(postalCode));
            dispatch(setCity(city));
        })
        .catch((error) => {
            console.log(error.toString());
        });
export const fetchCities = (value, callBack) =>
    () => getCities(value)
        .then(result => {
            if (result && result.length > 0) {
                const cities = result.reduce((list, city) => 
                city.codesPostaux.foreach(i => list.push({value: i + '@' + city.nom, text: i + ' ' + city.nom })), []);
                callBack(cities);
            } else {
                callBack([]);
            }
        })
        .catch((error) => {
            console.log(error.toString());
        });
