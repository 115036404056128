import React from 'react';
import {Button} from 'antd';
import {connect} from 'react-redux';

function FormAction(props) {
    return (
        <div className="form-action">
            <Button 
                className="btn-valid" 
                type="primary" 
                htmlType="submit"
                loading={props.loading}
            >
                {props.finish ? props.finishText : props.nextText}
            </Button>
            {props.prev && 
                <Button className="btn-return" type="primary" htmlType="button" onClick={() => props.onClick()}>
                    {props.prevText}
                </Button>
            }
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        nextText: state.translation.button.next, 
        prevText: state.translation.button.prev,
        finishText: state.translation.button.finish,
        loading: state.loading
    };
};

const FormActionController = connect(mapStateToProps)(FormAction);
export default FormActionController;
