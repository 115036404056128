import React, { Fragment } from 'react';
import generateController from '../../controller/SpecialFields/LifeController';
import Amount from '../../../form/FieldTemplate/AmountComponent';
import Select from '../../../form/FieldTemplate/SelectComponent';
import { Row, Col } from 'antd';
import { colXL8 } from '../../../../constant/DevisContant';

const Life = props =>
    <Fragment>
        <Row>
            <Col {...colXL8}>
                <Select
                    form={props.form}
                    name="specialLifeInsuranceType"
                    label={props.label.lifeInsuranceType}
                    options={props.options.lifeInsuranceType}
                    value={props.values.lifeInsuranceType}
                    onChange={(value) => props.addValue('lifeInsuranceType', value)}
                    disabled={props.disabled}
                />
            </Col>
            <Col {...colXL8}>
                <Amount
                    form={props.form}
                    name="specialInsuredAmount"
                    label={props.label.insuranceAmount}
                    value={props.values.insuranceAmount}
                    onBlur={(value) => props.addValue('insuranceAmount', value)}
                    disabled={props.disabled}
                />
            </Col>
        </Row>
    </Fragment>
    ;

const LifeController = generateController(Life);
export default LifeController;
