import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import { Box } from '@material-ui/core';
import toNumber from 'lodash-es/toNumber';
import isNumber from 'lodash-es/isNumber';
function NumberFormatCustom(props) {
    const { inputRef, onChange, symbol, ...other } = props;
    return (
        <Box display="flex" alignItems="center" width="100%">
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={values => {
                    onChange({target: {value: values.floatValue}});
                }}
            />
            {symbol && <Box>{symbol}</Box>}
        </Box>

    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
};

export function NumberField({ defaultValue, onChange, label, numberProps, error}) {
    return <TextField
            fullWidth label={label} defaultValue={defaultValue} onChange={v => {
                if (onChange) {
                    onChange(v);
                }
            }}
            InputProps={{
                inputComponent: NumberFormatCustom,
                inputProps: numberProps,
            }}
            error={error}
        />

}
export function NumberInput({ register, unregister, setValue, name, label, errors, numberProps, model, validator }) {
    const err = !!(errors && errors[name]);
    const numberValue = toNumber(model && model[name]);
    const defaultValue = !isNaN(numberValue) ? numberValue : null;
    useEffect(() => {
        if (validator) {
            register({ name }, validator);
        } else {
            register({ name });
        }
        setValue(name, defaultValue);
        return () => unregister(name);
    }, [name, register, unregister, validator]);
    return <TextField defaultValue={defaultValue}
        fullWidth label={label} name={name} onChange={v => {
            if (v.target) {
                setValue(name, v.target.value);
            }
        }}
        InputProps={{
            inputComponent: NumberFormatCustom,
            inputProps: numberProps,
        }}
        error={err}
    />
}
