import {connect} from 'react-redux';
import {addCompanyValue, addCompanyContactValue, addPersonalValue, addPersonalContactValue, addBasicValue, addCommonValue} from '../../../store/actions/formValuesActions';
import {fetchPostalCode} from '../../../store/actions/postalCodeAction';

const mapStateToProps = (state) => {
    const companyValues = state.formValues.quote.company;
    const personValues = state.formValues.quote.companyPerson;
    const specialValues = state.formValues.specialInfo;
    const commonData = state.formValues.commonData || {};
    const ownerCompany = commonData.company || {};
    ownerCompany.contact = ownerCompany.contact || {};
    const ownerIsCompany = commonData.ownerIsCompany;
    return {
        label: {
            name: state.translation.label.companyName,
            judicialForm: state.translation.label.companyLegalStatus,
            siren: state.translation.label.companySiren,
            siret: state.translation.label.companySiret,
            naf: state.translation.label.NAF,
            address: state.translation.label.companyAddress,
            addressInfo: state.translation.label.companyAddressInfo,
            postalCode: state.translation.label.companyPostalCode,
            city: state.translation.label.companyCity,
            country: state.translation.label.companyCountry,
            tel: state.translation.label.companyTel,
            mobile: state.translation.label.companyMobile,
            fax: state.translation.label.fax,
            companyLegalStatus: state.translation.label.companyLegalStatus,
            civility: state.translation.label.personalCivility,
            lastName: state.translation.label.personalLastName,
            maidenName: state.translation.label.personalMaidenName,
            firstName: state.translation.label.personalFirstName,
            fullName: state.translation.label.personalFullName,
            contactPerson: state.translation.label.contactPerson,
            email: state.translation.label.email,
            gerant: state.translation.label.gerant,
            isCompanyContactMainDriver: state.translation.label.mainDriver,
            houseOwner: state.translation.label.houseOwner
        },
        options: {
            companyLegalStatus: state.translation.options.companyLegalStatus,
            civility: state.translation.options.personalCivility,
        },
        values: {
            country: state.translation.values.country,
            insuranceCategory: state.formValues.quote.insuranceCategory,
            isCompanyContactMainDriver: state.formValues.quote.isCompanyContactMainDriver,
            company: companyValues || {},
            companyContact: companyValues ? (companyValues.contact || {}) : {},
            companyPerson: personValues || {},
            companyPersonContact: personValues ? (personValues.contact || {}) : {},
            insuranceType: state.formValues.quote.insuranceType,
            allCompanyDrivers: specialValues && specialValues.allCompanyDrivers ? specialValues.allCompanyDrivers : null,
            ownerCompany: ownerCompany,
            ownerIsCompany: ownerIsCompany
        },
        postalCode: state.postalCode,
        city: state.city,
    };
};

const mapDispatchToProps = (dispatch) => {
    return { 
        addValue: (key, value) => { dispatch(addCompanyValue(key, value)) },
        addContactValue: (key, value) => { dispatch(addCompanyContactValue(key, value)) },
        addCompanyPersonValue: (key, value) => { dispatch(addPersonalValue('companyPerson', key, value)) },
        addCompanyPersonContactValue: (key, value) => { dispatch(addPersonalContactValue('companyPerson', key, value)) },
        addPersonValue: (key, value) => { dispatch(addPersonalValue('person', key, value)) },
        addPersonContactValue: (key, value) => { dispatch(addPersonalContactValue('person', key, value)) },
        getPostalCode: (value) => { dispatch(fetchPostalCode(value)) },
        addBasicValue: (key, value) => { dispatch(addBasicValue(key, value)) },
        addCommonValue: (key, value) => { dispatch(addCommonValue(key, value)) },
    };
};

const ComponyInfoController =
    (CompoanyInfoComponent) => {return connect(mapStateToProps, mapDispatchToProps)(CompoanyInfoComponent)};

export default ComponyInfoController;
