import React from 'react';
import { render } from 'react-dom';
import './custom.css';
import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducer from './app/store/reducer/fo-app';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHome, faWarehouse, faUserShield, faDollyFlatbed, faHandHoldingUsd, faCar, faUserCheck } from '@fortawesome/free-solid-svg-icons'
import App from './app/app';
import './i18n';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
library.add(faHome, faWarehouse, faUserShield, faDollyFlatbed, faHandHoldingUsd, faCar, faUserCheck);
render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);
