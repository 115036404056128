import React, { Fragment } from 'react';
import Text from '../../../form/FieldTemplate/TextComponent';
import CustomDate from '../../../form/FieldTemplate/DateDropdownListComponent';
import AutoComplete from '../../../form/FieldTemplate/AutoCompleteComponent';
import generateController from '../../controller/SpecialFields/BorrowController';
import Amount from '../../../form/FieldTemplate/AmountComponent';
import { Col, Row, Divider } from 'antd';
import { colXL6, colXL8 } from '../../../../constant/DevisContant';
import Block from '../../../form/FieldTemplate/BlockComponent';
import Select from '../../../form/FieldTemplate/SelectComponent';
import CustomSwitch from '../../../form/FieldTemplate/SwitchComponent';
class Borrow extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        if (this.props.banks === null) {
            this.props.getBanks();
        }
    }
    render() {
        return (
            <Fragment>
                <Row>
                    <Col {...colXL8}>
                        <Select
                            form={this.props.form}
                            name="loanType"
                            label={this.props.label.insuranceType}
                            options={this.props.options.loanType}
                            value={this.props.values.loanType}
                            onChange={(value) => this.props.addValue('loanType', value)}
                            left={10}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    {this.props.values.loanType === 'other' && <Col {...colXL8}>
                        <Text
                            form={this.props.form}
                            name="loanTypeOther"
                            label={this.props.label.insuranceType}
                            value={this.props.label.loanTypeOther}
                            onBlur={(value) => this.props.addValue('loanTypeOther', value)}
                            left={10}
                            disabled={this.props.disabled}
                            colon={false}
                        />
                    </Col>}
                    {this.props.values.loanType === 'realEstate' && <Col {...colXL8}>
                        <Select
                            form={this.props.form}
                            name="realEstateLoanType"
                            label={this.props.label.use}
                            options={this.props.options.realEstateLoanType}
                            value={this.props.values.realEstateLoanType}
                            onChange={(value) => this.props.addValue('realEstateLoanType', value)}
                            left={10}
                            disabled={this.props.disabled}
                        />
                    </Col>}
                    <Col {...colXL8}>
                        <Amount
                            form={this.props.form}
                            name="specialLoanAmount"
                            label={this.props.label.loanAmount}
                            value={this.props.values.loanAmount}
                            onBlur={(value) => this.props.addValue('loanAmount', value)}
                            required
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL8}>
                        <Text
                            form={this.props.form}
                            name="specialLoanInterest"
                            label={this.props.label.loanInterest}
                            value={this.props.values.loanInterest}
                            onBlur={(value) => this.props.addValue('loanInterest', value)}
                            number
                            percent
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL8}>
                        <CustomDate
                            form={this.props.form}
                            name="specialLoanDate"
                            label={this.props.label.loanDate}
                            value={this.props.values.loanDate}
                            onChange={(value) => this.props.addValue('loanDate', value)}
                            disabled={this.props.disabled}
                            maxYear={new Date().getFullYear() + 10}
                        />
                    </Col>
                    <Col {...colXL8}>
                        <Text
                            form={this.props.form}
                            name="specialLoanDuration"
                            label={this.props.label.duration}
                            value={this.props.values.loanDuration}
                            onChange={(value) => this.props.addValue('loanDuration', value)}
                            suffix={this.props.label.month}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL8}>
                        <AutoComplete
                            form={this.props.form}
                            name="specialBank"
                            dataSource={this.props.banks}
                            label={this.props.label.bank}
                            value={this.props.values.bank}
                            onChange={(value) => this.props.addValue('bank', value)}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col span={24}>
                        <CustomSwitch
                            form={this.props.form}
                            name="creditRedemption"
                            label={this.props.label.creditRedemption}
                            value={this.props.values.creditRedemption}
                            onChange={(value) => {
                                this.props.addValue('creditRedemption', value);
                            }}
                            className="xl-panding-left-60"
                            disabled={this.props.disabled}
                        />
                    </Col>
                </Row>
                {this.props.values.creditRedemption && <Row>
                    <Divider></Divider>
                    <Col {...colXL8}>
                        <Amount
                            form={this.props.form}
                            name="loanAmountBeforeRedemption"
                            label={this.props.label.loanAmountBeforeRedemption}
                            value={this.props.values.loanAmountBeforeRedemption}
                            onBlur={(value) => this.props.addValue('loanAmountBeforeRedemption', value)}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL8}>
                        <Text
                            form={this.props.form}
                            name="loanDurationBeforeRedemption"
                            label={this.props.label.loanDurationBeforeRedemption}
                            value={this.props.values.loanDurationBeforeRedemption}
                            onChange={(value) => this.props.addValue('loanDurationBeforeRedemption', value)}
                            suffix={this.props.label.month}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL8}>
                        <Amount
                            form={this.props.form}
                            name="newLoanAmount"
                            label={this.props.label.redemptionAmount}
                            value={this.props.values.newLoanAmount}
                            onBlur={(value) => this.props.addValue('newLoanAmount', value)}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL8}>
                        <Text
                            form={this.props.form}
                            name="newLoanInterest"
                            label={this.props.label.loanInterest}
                            value={this.props.values.newLoanInterest}
                            onBlur={(value) => this.props.addValue('newLoanInterest', value)}
                            number
                            percent
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL8}>
                        <CustomDate
                            form={this.props.form}
                            name="newLoanDate"
                            label={this.props.label.loanDate}
                            value={this.props.values.newLoanDate}
                            onChange={(value) => this.props.addValue('newLoanDate', value)}
                            disabled={this.props.disabled}
                            maxYear={new Date().getFullYear() + 10}
                        />
                    </Col>
                    <Col {...colXL8}>
                        <Text
                            form={this.props.form}
                            name="newLoanDuration"
                            label={this.props.label.newDuration}
                            value={this.props.values.newLoanDuration}
                            onChange={(value) => this.props.addValue('newLoanDuration', value)}
                            suffix={this.props.label.month}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL8}>
                        <AutoComplete
                            form={this.props.form}
                            name="newBank"
                            dataSource={this.props.banks}
                            label={this.props.label.bank}
                            value={this.props.values.newBank}
                            onChange={(value) => this.props.addValue('newBank', value)}
                            disabled={this.props.disabled}
                        />
                    </Col>
                    <Col {...colXL8}>
                        <AutoComplete
                            form={this.props.form}
                            name="insuranceCompany"
                            dataSource={this.props.companies}
                            label={this.props.label.currentInsuranceCompany}
                            value={this.props.values.insuranceCompany}
                            onChange={(value) => this.props.addValue('insuranceCompany', value)}
                            disabled={this.props.disabled}
                        />
                    </Col>
                </Row>}

            </Fragment>
        );
    }
}

const BorrowController = generateController(Borrow);
export default BorrowController;
