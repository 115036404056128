import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import isFunction from 'lodash-es/isFunction';
import isObject from 'lodash-es/isObject';
import mapValue from 'lodash-es/mapValues';
export function useFormGroup(fields, defaultValues = {}) {
    defaultValues = defaultValues || {};
    const { register, handleSubmit, errors, setValue, unregister, watch, control, getValues } = useForm();
    const [fs, setFs] = useState(fields.map(i => {
        const field = {...i, register, errors, setValue, unregister, control, model: defaultValues};
        if (isObject(field.validator)) {
            if (isObject(field.name)) {
                field.validator = mapValue(field.validator, v => {
                    if (isFunction(v.validate)) {
                        v.validate = v.validate(getValues);
                    }
                    return v;
                });
            } else if (isFunction(field.validator.validate)) {
                field.validator.validate = field.validator.validate(getValues);
            }
        }
        return field;
    }));
    fs.forEach(i => i.errors = errors);
    // const formGroup = useMemo(() => {
    //     return {fields: fields.map(i => {
    //         const field = {...i, register, errors, setValue, unregister, control, model: defaultValues};
    //         if (isObject(field.validator)) {
    //             if (isObject(field.name)) {
    //                 field.validator = mapValue(field.validator, v => {
    //                     if (isFunction(v.validate)) {
    //                         v.validate = v.validate(getValues);
    //                     }
    //                     return v;
    //                 });
    //             } else if (isFunction(field.validator.validate)) {
    //                 field.validator.validate = field.validator.validate(getValues);
    //             }
    //         }
    //         return field;
    //     }), handleSubmit, watch, getValues}
    // }, []);
    return {fields: fs, handleSubmit, watch, getValues};
}
