import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import generateController from './controller/HomeController';

const HomeComponennt = (props) => (
    <Fragment>
        <div className="home-txt">
            <p>
                <b>{props.content.name}</b> {props.content.intro} 
            </p>
            {props.content.list.length > 0 &&
                <ul>
                    {props.content.list.map((item, index) => {
                        return <li key={index}>{item}</li>;
                    })}
                </ul>
            }
            {props.content.text.map((item, index) => {
                return <p key={index}>{item}</p>;
            })}
            <p>
                {props.content.textMail} <a href="mailto:delinassurances@gmail.com">{props.content.mail}</a>
            </p>
        </div>

        <div className="txt-center">
            <Link 
                className="btn quotation-btn"
                to="/qr"
                onClick={() => {
                    props.setFormValues({});
                    props.setQuote(null);
                }}
            >
                {props.button}
            </Link>
        </div>
    </Fragment>
);

export default generateController(HomeComponennt);
