import React from "react";
import generateController from '../controller/StepsController';
import CommentComponent from './CommentComponent';
import CompanyInfoComponent from './CompanyInfoComponent';
import InsuranceSpecialFieldsComponent from './InsuranceSpecialFieldsComponent';
import InsuranceTypeComponent from './InsuranceTypeComponent';
import PersonalInfoComponent from './PersonalInfoComponent';
import UploadComponent from './UploadComponent';

class StepsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {current: 0, showPer: false, showPro: false};
        if (!props.formValues || !props.formValues.quote) {
            props.setFormValues({});
        }
    }
    prev() {
        let current = this.state.current - 1;
        if (current === 1 && !this.state.showPro) {
            current -= 1;
        }
        if (current === 2 && !this.state.showPer) {
            current -= 1;
        }
        this.setState({ current });
    }
    handleSubmit() {
        let {showPer, showPro} = this.state;
        if (this.state.current === 0) {
            const { formValues } = this.props;
            const { insuranceType, insuranceCategory} = formValues.quote;
            if (insuranceCategory === 'pro') {
                showPro = true;
            } else {
                showPro = false;
            }
            if (insuranceCategory === 'per' || (insuranceCategory === 'pro' && insuranceType === 'vehicle' && !formValues.specialInfo.allCompanyDrivers) ) {
                showPer = true;
            } else {
                showPer = false;
            }
        }
        let current = this.state.current + 1;
        if (current === 1 && !showPro) {
            current += 1;
        }
        if (current === 2 && !showPer) {
            current += 1;
        }
        this.setState({ current, showPer, showPro });
    }
    firstStep() {
        this.setState({ current: 0 });
    }
    generateStep() {
        let stepContent = (<p>Test</p>);
        switch (this.state.current) {
            case 0:
                stepContent = (
                    <InsuranceTypeComponent
                        onSubmit={(values) => this.handleSubmit()}
                    />
                );
                break;
            case 1:
                stepContent = (
                    <CompanyInfoComponent
                        onSubmit={(values) => this.handleSubmit()}
                        onClick={()=>this.prev()}
                    />
                );
                break;
            case 2:
                stepContent = (
                    <PersonalInfoComponent
                        onSubmit={(values) => this.handleSubmit()}
                        onClick={()=>this.prev()}
                    />
                );
                break;
            case 3:
                stepContent = (
                    <InsuranceSpecialFieldsComponent
                        onSubmit={(values) => this.handleSubmit()}
                        onClick={()=>this.prev()}
                    />
                );
                break;
            case 4:
                stepContent = (
                    <UploadComponent
                        onSubmit={(values) => this.handleSubmit()}
                        onClick={()=>this.prev()}
                    />
                );
                break;
            case 5:
                stepContent = (
                    <CommentComponent
                        onSubmit={(values) => this.handleSubmit()}
                        onClick={()=>this.prev()}
                        onCreateNew={() => this.firstStep()}
                    />
                );
                break;
            default:
                break;
        }

        return stepContent;
    }

    render () {
        return (
            <div className="quotation-request-section">
                {/* <Steps progressDot current={this.state.current}>
                    {steps.map(item => <Step key={item.title} />)}
                </Steps> */}
                <div className="steps-content">
                    {this.generateStep()}
                </div>
            </div>
        );
    }
}

const StepsController = generateController(StepsComponent);
export default StepsController;
