import React from 'react';
import {getFormItemStyle} from '../../../constant/DevisContant';
import {Form} from 'antd';
import {connect} from 'react-redux';
import Cleave from 'cleave.js/react';

const FormItem = Form.Item;

const Block = (props) => {
    const {getFieldDecorator} = props.form;
    const formItemLayout = getFormItemStyle(props);
    let rules = [];
    if (props.required) {
        rules.push({required: true, message: props.emptyMessage});
    }
    const label = props.siret 
        ? (<a target="blank" href="https://www.societe.com/">{props.label}</a>) 
        : props.label;
    let options = {};
    if (props.blocks) {
        options.blocks = props.blocks;
    }
    if (props.uppercase) {
        options.uppercase = true;
    }
    if (props.number) {
        options.numericOnly = true;
    }

    return (
        <FormItem 
            {...formItemLayout}
            label={label}
        >
            {getFieldDecorator(props.name, {
                initialValue: props.value ? props.value : '',
                rules: rules,
            })(
                <Cleave
                    className="ant-input"
                    options={options}
                    onChange={(e) => {
                        if (props.onChange) {
                            props.onChange(e.target.value);
                        }
                    }}
                    onBlur={(e) => {
                        if (props.onBlur) {
                            props.onBlur(e.target.value);
                        }
                    }}
                    disabled={props.disabled}
                />
            )}
        </FormItem>
    );
}

const mapStateToProps = (state) => {
    return {
        emptyMessage: state.translation.messageError.empty,
    };
};

const TextController = connect(mapStateToProps)(Block);
export default TextController;
