import React, { Fragment } from 'react';
import { Form, Tabs, Button, Alert } from 'antd';
import generateController from '../controller/ModifyController';
import InsuranceType from './Content/InsuranceTypeForm';
import CompanyInfo from './Content/CompanyInfo';
import PersonalInfo from './Content/PersonalInfo';
import SpecialInfo from './Content/InsuranceSpecialInfo';
import Upload from './Content/Upload';
import Comment from './Content/Comment';

const TabPane = Tabs.TabPane;

class ModifyComponent extends React.Component {
    componentDidMount() {
        let id = this.props.isReadonly ? this.props.quotationId : this.props.match.params.name;
        this.props.getFormValues(id, this.props.isReadonly);
    }
    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.setLoading(true);
                this.props.putFormValues();
            }
        });
    }
    generateSuccessMessage() {
        return (
            <Alert
                style={{ textAlign: 'left' }}
                message={this.props.success.message}
                description={this.props.success.description}
                type="success"
            />
        );
    }
    generateForm(isReadonly) {
        if (Object.keys(this.props.values).length > 0 ) {
            return (
                <Fragment>
                    {this.props.quote && this.props.quote.error &&
                        <Fragment>
                            <Alert
                                style={{ textAlign: 'left' }}
                                message={this.props.error.message}
                                description={this.props.error.description}
                                type="error"
                                closable
                            />
                            <br />
                        </Fragment>
                    }
                    <Form onSubmit={(e) => this.handleSubmit(e)} className={isReadonly ? 'readonly' : ''}>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab={this.props.title.specialInfo} key="1">
                                <InsuranceType
                                    form={this.props.form}
                                    center
                                    disabled={isReadonly}
                                />
                            </TabPane>
                            {(this.props.insuranceCategory === 'pro') &&
                                <TabPane tab={this.props.title.company} key="2" className="label-left">
                                    <CompanyInfo
                                        form={this.props.form}
                                        disabled={isReadonly}
                                    />
                                </TabPane>
                            }
                            {
                                (this.props.insuranceCategory === 'per' || (this.props.insuranceType === 'vehicle' && !this.props.allCompanyDrivers)) && <TabPane tab={this.props.title.personal} key="3" className="label-left">
                                    <PersonalInfo
                                        form={this.props.form}
                                        disabled={isReadonly}
                                    />
                                </TabPane>
                            }

                            <TabPane tab={this.props.title.detail} key="4" className="label-left">
                                <SpecialInfo
                                    form={this.props.form}
                                    disabled={isReadonly}
                                />
                            </TabPane>
                            <TabPane tab={this.props.title.upload} key="5" className="label-left">
                            {
                                isReadonly ? null : (<Upload
                                form={this.props.form}
                                disabled={isReadonly}
                                />)
                            }                              
                            </TabPane>
                            <TabPane tab={this.props.title.comment} key="6" className="label-left">
                                <Comment
                                    form={this.props.form}
                                    disabled={isReadonly}
                                />
                            </TabPane>
                        </Tabs>
                        {!isReadonly && <div className="form-action">
                            <Button
                                className="btn-valid"
                                type="primary"
                                htmlType="submit"
                                loading={this.props.loading}
                            >
                                {this.props.nextText}
                            </Button>
                        </div>}
                    </Form>
                </Fragment>
            );
        }
    }
    render() {
        const success = this.props.quote && this.props.quote.success;
        const quoteResponse = !!this.props.values.quote.code;
        return (
            <section className="steps-content">
                {
                    success && this.generateSuccessMessage()
                }
                {
                    !success && quoteResponse && this.generateForm(this.props.isReadonly)
                }
            </section>
        );
    }
}

const ModifyForm = Form.create()(generateController(ModifyComponent));
export default ModifyForm;
