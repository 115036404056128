import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { serviceLoginRoute } from '../../constant/route-constant';
import { useStore } from '../../core/store';
const PrivateServiceRoute = ({ component: Component, ...rest }) => {
    const {serviceUser} = useStore();
    return <Route
        {...rest}
        render={props =>
            !!serviceUser ? (
                <Component {...props} />
            ) : (
                    <Redirect
                        to={{
                            pathname: serviceLoginRoute,
                            state: { from: window.location.pathname }
                        }}
                    />
                )
        }
    />
};
export default PrivateServiceRoute;