import {connect} from 'react-redux';
import {setValues} from '../../store/actions/formValuesActions';
import {setQuote} from '../../store/actions/quoteAction';

const mapStateToProps = (state) => {
    return {
        content: state.translation.home.content,
        button: state.translation.home.button,
    };
};

const mapDispatchToProps = (dispatch) => {
    return { 
        setFormValues: (value) => { dispatch(setValues(value)) },
        setQuote: (value) => { dispatch(setQuote(value)) }
    };
};

const HomeController =
    (HomeComponenet) => {return connect(mapStateToProps, mapDispatchToProps)(HomeComponenet)};

export default HomeController;
