import React, { Fragment } from 'react';
import Text from '../../../form/FieldTemplate/TextComponent';
import Telephone from '../../../form/FieldTemplate/TelephoneComponent';
import CustomDate from '../../../form/FieldTemplate/DateDropdownListComponent';
import { requiredSexInsuranceType, colXL8, colXL16, colXL24, colXL20, requiredBirthDateInsuranceType, colXL12, colXL10, colXL6 } from '../../../../constant/DevisContant';
import generateController from '../../controller/Content/PersonalInfoController';
import Select from '../../../form/FieldTemplate/SelectComponent';
import { Row, Col, Divider } from 'antd';
import CityAutoComplete from '../../../form/FieldTemplate/CityAutoCompleteComponent';
import PostalCodeText from '../../../form/FieldTemplate/PostalCodeComponent';
import CustomSwitch from '../../../form/FieldTemplate/SwitchComponent';
import Radio from '../../../form/FieldTemplate/RadioComponent';
import Block from '../../../form/FieldTemplate/BlockComponent';
const PersonalInfo = (props) => {
    const requiredSex = requiredSexInsuranceType.indexOf(props.values.insuranceType) !== -1 ? { required: true } : {};
    const requiredBirthDate = requiredBirthDateInsuranceType.indexOf(props.values.insuranceType) !== -1 ? { required: true } : {};
    const { person: p, personContact: pc, person2: p2, person2Contact: p2c, insuranceType, insuranceCategory, ownerCompany } = props.values;
    const isBorrow = insuranceType === 'borrow';
    const isLife = insuranceType === 'life';
    const isCar = insuranceType === 'vehicle';
    const isPro = insuranceCategory === 'pro';
    const isHabitation = insuranceType === 'habitation';
    const nameLabel = <span style={{ fontSize: 14 }}>{props.label.fullName}</span>;
    const dateLabel = <span style={{ fontSize: 14 }}>{props.label.birth}</span>;
    return (
        <Fragment>
            {isCar && <Divider ><h4>{props.label.mainDriver}</h4></Divider>}
            <Row>
                <Col {...colXL6}>
                    <Select
                        form={props.form}
                        name="personalCivility"
                        label={props.label.civility}
                        options={props.options.civility}
                        value={p.civility}
                        onChange={(value) => props.addPersonValue('civility', value)}
                        disabled={props.disabled}
                        zeroValue="none"
                        left={10}
                    />
                </Col>
                <Col {...colXL6}>
                    <Text
                        form={props.form}
                        name="personalLastName"
                        label={props.label.lastName}
                        value={p.lastName}
                        onBlur={(e) => {
                            props.addPersonValue('lastName', e);
                        }}
                        required
                        left={10}
                        disabled={props.disabled}
                        upperCase
                    />
                </Col>
                <Col {...colXL6}>
                    <Text
                        form={props.form}
                        name="personalFirstName"
                        label={props.label.firstName}
                        value={p.firstName}
                        onBlur={(e) => {
                            props.addPersonValue('firstName', e);
                        }}
                        required
                        left={10}
                        disabled={props.disabled}
                        capitalize
                    />
                </Col>
                {p.civility === 'ms' && <Col {...colXL6}>
                    <Text
                        form={props.form}
                        name="personalMaidenName"
                        label={props.label.maidenName}
                        value={p.maidenName}
                        onBlur={(e) => {
                            props.addPersonValue('maidenName', e);
                        }}
                        disabled={props.disabled}
                        upperCase
                        left={10}
                    />
                </Col>}
                <Col {...colXL6}>
                    <Text
                        form={props.form}
                        name="personalFullName"
                        label={nameLabel}
                        value={p.fullName}
                        onBlur={(value) => props.addPersonValue('fullName', value)}
                        left={10}
                        disabled={props.disabled}
                    />
                </Col>

                <Col {...colXL6}>
                    <Text
                        form={props.form}
                        name="personalEmail"
                        label={props.label.email}
                        value={pc.email}
                        onBlur={(value) => {
                            props.addPersonContactValue('email', value);
                            if (!isCar || (isCar && !isPro)) {
                                props.addBasicValue('email', value);
                            }
                        }}
                        mail
                        required
                        left={10}
                        disabled={props.disabled}
                        lowerCase
                    />
                </Col>
                <Col {...colXL6}>
                    <Telephone
                        form={props.form}
                        name="personalMobile"
                        label={props.label.mobile}
                        value={{ prefix: pc.mobilePrefix, value: pc.mobile }}
                        onChange={(isPrefix, value) => props.addPersonContactValue(isPrefix ? 'mobilePrefix' : 'mobile', value)}
                        required
                        left={10}
                        disabled={props.disabled}
                    />
                </Col>
                <Col {...colXL6}>
                    <Telephone
                        form={props.form}
                        name="personalTel"
                        label={props.label.tel}
                        value={{ prefix: pc.telPrefix, value: pc.tel }}
                        onChange={(isPrefix, value) => props.addPersonContactValue(isPrefix ? 'telPrefix' : 'tel', value)}
                        left={10}
                        disabled={props.disabled}
                    />
                </Col>
                <Col {...colXL6}>
                    <Radio
                        form={props.form}
                        name="personalSex"
                        label={props.label.sex}
                        options={props.options.sex}
                        value={p.sex}
                        onChange={(value) => props.addPersonValue('sex', value)}
                        left={10}
                        {...requiredSex}
                    />
                </Col>
                <Col {...colXL8}>
                    <CustomDate
                        form={props.form}
                        name="personalBirth"
                        label={dateLabel}
                        value={p.birthDate}
                        onChange={(value) => props.addPersonValue('birthDate', value)}
                        left={10}
                        {...requiredBirthDate}
                        disabled={props.disabled}
                    />
                </Col>
                <Col {...colXL6}>
                    <Select
                        form={props.form}
                        name="personalFamilySituation"
                        label={props.label.familySituation}
                        options={props.options.familySituation}
                        value={p.familySituation}
                        onChange={(value) => props.addPersonValue('familySituation', value)}
                        disabled={props.disabled}
                        required={isBorrow}
                    />
                </Col>
                {(p.familySituation === 'married') && (isBorrow || isLife) && <Col {...colXL16}>
                    <Select
                        form={props.form}
                        name="personalMatrimonialRegime"
                        label={props.label.specialMatrimonialRegime}
                        options={props.options.specialMatrimonialRegime}
                        value={p.matrimonialRegime}
                        onChange={(value) => props.addPersonValue('matrimonialRegime', value)}
                        disabled={props.disabled}
                        left={8}
                    />
                </Col>}
            </Row>
            <Row>
                <Col {...colXL12}>
                    <Text
                        form={props.form}
                        name="personAddress1"
                        label={props.label.address}
                        value={pc.address1}
                        onBlur={(value) => props.addPersonContactValue('address1', value)}
                        left={5}
                        disabled={props.disabled}
                        maxLength={100}
                        required
                    />
                </Col>
                <Col {...colXL12}>
                    <Text
                        form={props.form}
                        name="personAddress2"
                        label={props.label.address + '2'}
                        value={pc.address2}
                        onBlur={(value) => props.addPersonContactValue('address2', value)}
                        left={5}
                        disabled={props.disabled}
                        colon={false}
                        maxLength={100}
                    />
                </Col>
                <Col {...colXL20}>
                    <Text
                        form={props.form}
                        name="personAddressInfo"
                        label={props.label.addressInfo}
                        value={pc.address3}
                        onBlur={(value) => props.addPersonContactValue('address3', value)}
                        left={3}
                        disabled={props.disabled}
                        maxLength={100}
                    />
                </Col>
                <Col {...colXL6}>
                    <PostalCodeText
                        form={props.form}
                        name="personPostalCode"
                        label={props.label.postalCode}
                        value={pc.postalCode}
                        updateCity={(value) => {
                            props.addPersonContactValue('city', value);
                        }}
                        updateCP={(value) => props.addPersonContactValue('postalCode', value)}
                        disabled={props.disabled}
                        required
                        left={10}
                    />
                </Col>
                <Col {...colXL6}>
                    <CityAutoComplete
                        form={props.form}
                        name="personCity"
                        label={props.label.city}
                        value={pc.city}
                        updateCity={(value) => {
                            props.addPersonContactValue('city', value);
                        }}
                        updateCP={(value) => props.addPersonContactValue('postalCode', value)}
                        left={10}
                        disabled={props.disabled}
                        required
                    />
                </Col>
                <Col {...colXL6}>
                    <Text
                        form={props.form}
                        name="personCountry"
                        label={props.label.country}
                        value={props.values.country}
                        disabled
                        left={10}
                    />
                </Col>
                {isCar && <Fragment>
                    <Col {...colXL12}>
                        <CustomDate
                            form={props.form}
                            name="specialDrivingLicenseDate"
                            label={props.label.drivingLicenseDate}
                            value={props.values.driver.drivingLicenseDate}
                            onChange={(value) => {
                                let driver = props.values.driver;
                                driver.drivingLicenseDate = value;
                                props.addSpecialValue('driver', driver);
                            }}
                            left={10}
                            right={10}
                            required
                            disabled={props.disabled}
                        />
                    </Col>
                    <Col {...colXL12}>
                        <CustomDate
                            form={props.form}
                            name="specialDrivingLicenseValidDate"
                            label={props.label.drivingLicenseValidDate}
                            value={props.values.driver.drivingLicenseValidDate}
                            onChange={(value) => {
                                let driver = props.values.driver;
                                driver.drivingLicenseValidDate = value;
                                props.addSpecialValue('driver', driver);
                            }}
                            left={10}
                            right={10}
                            required
                            disabled={props.disabled}
                            maxYear={new Date().getFullYear() + 10}
                        />
                    </Col>
                    <Col {...colXL12}>
                        <Text
                            form={props.form}
                            name="specialBMPoint"
                            label={props.label.bmPoint}
                            value={props.values.driver.bmPoint}
                            onBlur={(value) => {
                                let driver = props.values.driver;
                                driver.bmPoint = value;
                                props.addSpecialValue('driver', driver);
                            }}
                            number
                            left={10}
                            right={10}
                            disabled={props.disabled}
                            step={0.01}
                        />
                    </Col>
                    <Col {...colXL16}>
                        <Select
                            form={props.form}
                            name="lossNumIn3Years"
                            label={props.label.lossNumIn3Years}
                            options={props.options.lossNumIn3Years}
                            value={props.values.driver.lossNumIn3Years}
                            onChange={(value) => {
                                let driver = props.values.driver;
                                driver.lossNumIn3Years = value;
                                props.addSpecialValue('driver', driver);
                            }}
                            left={12}
                            right={6}
                            disabled={props.disabled}
                        />
                    </Col>
                    <Col {...colXL16}>
                        <CustomSwitch
                            form={props.form}
                            name="driverLicenceCancelled"
                            label={props.label.driverLicenceCancelled}
                            value={props.values.driver.driverLicenceCancelled}
                            onChange={(value) => {
                                let driver = props.values.driver;
                                driver.driverLicenceCancelled = value;
                                props.addSpecialValue('driver', driver);
                            }}
                            left={18}
                            right={6}
                            disabled={props.disabled}
                            className="xl-panding-left-60"
                        />

                    </Col>
                    {
                        props.values.driver.driverLicenceCancelled && <Fragment>
                            <Col {...colXL10}>
                                <CustomDate
                                    form={props.form}
                                    name="driverLicenceCancelledDate"
                                    label={props.label.date}
                                    value={props.values.driver.driverLicenceCancelledDate}
                                    onChange={(value) => {
                                        let driver = props.values.driver;
                                        driver.driverLicenceCancelledDate = value;
                                        props.addSpecialValue('driver', driver);
                                    }}
                                    disabled={props.disabled}
                                />
                            </Col>
                            <Col {...colXL10}>
                                <Text
                                    form={props.form}
                                    name="driverLicenceCancelledDateDuration"
                                    label={props.label.duration}
                                    value={props.values.driver.driverLicenceCancelledDuration}
                                    onBlur={(value) => {
                                        let driver = props.values.driver;
                                        driver.driverLicenceCancelledDuration = value;
                                        props.addSpecialValue('driver', driver);
                                    }}
                                    suffix={props.label.month}
                                    disabled={props.disabled}
                                />
                            </Col>
                        </Fragment>
                    }
                </Fragment>}
                {
                    (isCar) &&
                    <Col {...colXL24}>
                        <CustomSwitch
                            form={props.form}
                            name="addPerson2InContract"
                            label={props.label.secondDriverInsurance}
                            value={props.values.addPerson2InContract}
                            onChange={(value) => {
                                props.addBasicValue('addPerson2InContract', value);
                                props.addSpecialValue('hasSecondDriver', value);
                                props.addSpecialValue('allCompanyDrivers', false);
                            }}
                            required
                            disabled={props.disabled}
                            className="xl-panding-left-60"
                        />
                    </Col>

                }
                {
                    (isBorrow) && <Col span={24}>
                        <Row>
                            <Col {...colXL6}>
                                <CustomSwitch
                                    form={props.form}
                                    name="borrower1Smoker"
                                    label={props.label.smoker}
                                    value={props.values.borrower1Smoker}
                                    onChange={(value) => {
                                        props.addSpecialValue('borrower1Smoker', value);
                                    }}
                                    disabled={props.disabled}
                                    className="xl-panding-left-60"
                                /></Col>
                            {props.values.addPerson2InContract && <Col {...colXL6}><Text
                                form={props.form}
                                name="borrower1Proportion"
                                label={props.label.insuranceProportion}
                                value={props.values.borrower1Proportion}
                                onBlur={(value) => props.addSpecialValue('borrower1Proportion', value)}
                                number
                                percent
                                disabled={props.disabled}
                            /> </Col>}
                        </Row></Col>
                }

                {(props.values.addPerson2InContract) &&
                    <Fragment>
                        <Divider ><h4>{(isBorrow ? props.label.coBorrower : (isLife ? props.label.coSubscriber : props.label.secondDriver))}</h4></Divider>
                        <Col {...colXL6}>
                            <Select
                                form={props.form}
                                name="personalCivility2"
                                label={props.label.civility}
                                options={props.options.civility}
                                value={p2.civility}
                                onChange={(value) => props.addPerson2Value('civility', value)}
                                disabled={props.disabled}
                                zeroValue="none"
                                left={10}
                            />
                        </Col>
                        <Col {...colXL6}>
                            <Text
                                form={props.form}
                                name="personalLastName2"
                                label={props.label.lastName}
                                value={p2.lastName}
                                onBlur={(e) => {
                                    props.addPerson2Value('lastName', e);
                                }}
                                required
                                left={10}
                                disabled={props.disabled}
                                uppperCase
                            />
                        </Col>
                        <Col {...colXL6}>
                            <Text
                                form={props.form}
                                name="personalFirstName2"
                                label={props.label.firstName}
                                value={p2.firstName}
                                onBlur={(value) => props.addPerson2Value('firstName', value)}
                                required
                                left={10}
                                disabled={props.disabled}
                                capitalize
                            />
                        </Col>
                        {p2.civility === 'ms' && <Col {...colXL6}>
                            <Text
                                form={props.form}
                                name="personalMaidenName2"
                                label={props.label.maidenName}
                                value={p2.maidenName}
                                onBlur={(value) => props.addPerson2Value('maidenName', value)}
                                disabled={props.disabled}
                                upperCase
                                left={10}
                            />
                        </Col>}
                        <Col {...colXL6}>
                            <Text
                                form={props.form}
                                name="personalFullName2"
                                label={nameLabel}
                                value={p2.fullName}
                                onBlur={(value) => props.addPerson2Value('fullName', value)}
                                left={10}
                                disabled={props.disabled}
                            />
                        </Col>

                        <Col {...colXL6}>
                            <Text
                                form={props.form}
                                name="personalEmail2"
                                label={props.label.email}
                                value={p2c.email}
                                onBlur={(value) => {
                                    props.addPerson2ContactValue('email', value);
                                }}
                                mail
                                required
                                left={10}
                                disabled={props.disabled}
                                lowerCase
                            />
                        </Col>
                        <Col {...colXL6}>
                            <Telephone
                                form={props.form}
                                name="personalMobile2"
                                label={props.label.mobile}
                                value={{ prefix: p2c.mobilePrefix, value: p2c.mobile }}
                                onChange={(isPrefix, value) => props.addPerson2ContactValue(isPrefix ? 'mobilePrefix' : 'mobile', value)}
                                left={10}
                                disabled={props.disabled}
                            />
                        </Col>
                        <Col {...colXL6}>
                            <Radio
                                form={props.form}
                                name="personalSex2"
                                label={props.label.sex}
                                options={props.options.sex}
                                value={p2.sex}
                                onChange={(value) => props.addPerson2Value('sex', value)}
                                left={10}
                                {...requiredSex}
                            />
                        </Col>
                        <Col {...colXL8}>
                            <CustomDate
                                form={props.form}
                                name="personalBirth2"
                                label={dateLabel}
                                value={p2.birthDate}
                                onChange={(value) => props.addPerson2Value('birthDate', value)}
                                left={10}
                                {...requiredBirthDate}
                                disabled={props.disabled}
                            />
                        </Col>
                        <Col {...colXL6}>
                            <Select
                                form={props.form}
                                name="personalFamilySituation2"
                                label={props.label.familySituation}
                                options={props.options.familySituation}
                                value={p2.familySituation}
                                onChange={(value) => props.addPerson2Value('familySituation', value)}
                                required={isBorrow}
                                disabled={props.disabled}
                            />
                        </Col>
                        {(p2.familySituation === 'married') && (isBorrow || isLife) && <Col {...colXL16}>
                            <Select
                                form={props.form}
                                name="personalMatrimonialRegime2"
                                label={props.label.specialMatrimonialRegime}
                                options={props.options.specialMatrimonialRegime}
                                value={p2.matrimonialRegime}
                                onChange={(value) => props.addPerson2Value('matrimonialRegime', value)}
                                disabled={props.disabled}
                                left={8}
                            />
                        </Col>}
                        {isCar && <Fragment>
                            <Col {...colXL12}>
                                <CustomDate
                                    form={props.form}
                                    name="specialDrivingLicenseDate2"
                                    label={props.label.drivingLicenseDate}
                                    value={props.values.secondDriver.drivingLicenseDate}
                                    onChange={(value) => {
                                        let driver = props.values.secondDriver;
                                        driver.drivingLicenseDate = value;
                                        props.addSpecialValue('secondDriver', driver);
                                    }}
                                    left={10}
                                    right={10}
                                    required
                                    disabled={props.disabled}
                                />
                            </Col>
                            <Col {...colXL12}>
                                <CustomDate
                                    form={props.form}
                                    name="specialDrivingLicenseValidDate2"
                                    label={props.label.drivingLicenseValidDate}
                                    value={props.values.secondDriver.drivingLicenseValidDate}
                                    onChange={(value) => {
                                        let driver = props.values.secondDriver;
                                        driver.drivingLicenseValidDate = value;
                                        props.addSpecialValue('secondDriver', driver);
                                    }}
                                    left={10}
                                    right={10}
                                    required
                                    disabled={props.disabled}
                                    maxYear={new Date().getFullYear() + 10}
                                />
                            </Col>
                            <Col {...colXL12}>
                                <Text
                                    form={props.form}
                                    name="specialBMPoint2"
                                    label={props.label.bmPoint}
                                    value={props.values.secondDriver.bmPoint}
                                    onBlur={(value) => {
                                        let driver = props.values.secondDriver;
                                        driver.bmPoint = value;
                                        props.addSpecialValue('secondDriver', driver);
                                    }}
                                    number
                                    left={10}
                                    right={10}
                                    disabled={props.disabled}
                                    step={0.01}
                                />
                            </Col>
                            <Col {...colXL16}>
                                <Select
                                    form={props.form}
                                    name="lossNumIn3Years2"
                                    label={props.label.lossNumIn3Years}
                                    options={props.options.lossNumIn3Years}
                                    value={props.values.secondDriver.lossNumIn3Years}
                                    onChange={(value) => {
                                        let driver = props.values.secondDriver;
                                        driver.lossNumIn3Years = value;
                                        props.addSpecialValue('secondDriver', driver);
                                    }}
                                    left={12}
                                    right={6}
                                    disabled={props.disabled}
                                />
                            </Col>
                            <Col {...colXL16}>
                                <CustomSwitch
                                    form={props.form}
                                    name="driverLicenceCancelled2"
                                    label={props.label.driverLicenceCancelled}
                                    value={props.values.secondDriver.driverLicenceCancelled}
                                    onChange={(value) => {
                                        let driver = props.values.secondDriver;
                                        driver.driverLicenceCancelled = value;
                                        props.addSpecialValue('secondDriver', driver);
                                    }}
                                    disabled={props.disabled}
                                    className="xl-panding-left-60"
                                />

                            </Col>
                            {
                                props.values.secondDriver.driverLicenceCancelled && <Fragment>
                                    <Col {...colXL10}>
                                        <CustomDate
                                            form={props.form}
                                            name="secondDriverDriverLicenceCancelledDate"
                                            label={props.label.date}
                                            value={props.values.secondDriver.driverLicenceCancelledDate}
                                            onChange={(value) => {
                                                let driver = props.values.secondDriver;
                                                driver.driverLicenceCancelledDate = value;
                                                props.addSpecialValue('secondDriver', driver);
                                            }}
                                            disabled={props.disabled}
                                        />
                                    </Col>
                                    <Col {...colXL10}>
                                        <Text
                                            form={props.form}
                                            name="secondDriverDriverLicenceCancelledDateDuration"
                                            label={props.label.duration}
                                            value={props.values.secondDriver.driverLicenceCancelledDuration}
                                            onBlur={(value) => {
                                                let driver = props.values.secondDriver;
                                                driver.driverLicenceCancelledDuration = value;
                                                props.addSpecialValue('secondDriver', driver);
                                            }}
                                            suffix={props.label.month}
                                            disabled={props.disabled}
                                        />
                                    </Col>
                                </Fragment>
                            }
                        </Fragment>}
                        {
                            (isBorrow) && <Col span={24}>
                                <Row>
                                    <Col {...colXL6}>
                                        <CustomSwitch
                                            form={props.form}
                                            name="borrower2Smoker"
                                            label={props.label.smoker}
                                            value={props.values.borrower2Smoker}
                                            onChange={(value) => {
                                                props.addSpecialValue('borrower2Smoker', value);
                                            }}
                                            disabled={props.disabled}
                                            className="xl-panding-left-60"
                                        /></Col>
                                    <Col {...colXL6}><Text
                                        form={props.form}
                                        name="borrower2Proportion"
                                        label={props.label.insuranceProportion}
                                        value={props.values.borrower2Proportion}
                                        onBlur={(value) => props.addSpecialValue('borrower2Proportion', value)}
                                        number
                                        percent
                                        disabled={props.disabled}
                                    /> </Col>
                                </Row></Col>
                        }
                    </Fragment>
                }
                {props.values.ownerIsCompany && (isBorrow || isHabitation) &&
                    <Fragment>
                        <Divider ><h4>{props.label.houseOwner}</h4></Divider>
                        <Col {...colXL12}>
                            <Text
                                form={props.form}
                                name="ownerCompanyName"
                                label={props.label.name}
                                value={ownerCompany.name}
                                required
                                onBlur={(e) => {
                                    props.addOwnerCompanyValue('name', e);
                                }}
                                left={5}
                                disabled={props.disabled}
                                upperCase
                            />
                        </Col>
                        <Col {...colXL6}>
                            <Select
                                form={props.form}
                                name="ownerCompanyLegalStatus"
                                label={props.label.companyLegalStatus}
                                options={props.options.companyLegalStatus}
                                value={ownerCompany.legalStatus}
                                onChange={(value) => props.addOwnerCompanyValue('legalStatus', value)}
                                left={10}
                                disabled={props.disabled}
                            />
                        </Col>
                        {ownerCompany.legalStatus === 'other' && <Col {...colXL6}>
                            <Text
                                form={props.form}
                                name="ownerCompanyLegalStatusOther"
                                label={props.label.companyLegalStatus}
                                value={ownerCompany.legalStatusOther}
                                onBlur={(value) => props.addOwnerCompanyValue('legalStatusOther', value)}
                                left={10}
                                disabled={props.disabled}
                                colon={false}
                            />
                        </Col>}
                        <Col {...colXL6}>
                            <Block
                                form={props.form}
                                name="ownerCompanySiret"
                                label={props.label.siret}
                                value={ownerCompany.siren}
                                blocks={[3, 3, 3, 5]}
                                onBlur={(value) => props.addOwnerCompanyValue('siren', value)}
                                siret
                                number
                                left={10}
                                disabled={props.disabled}
                            />
                        </Col>
                        <Col {...colXL12}>
                            <Text
                                form={props.form}
                                name="ownerCompanyAddress1"
                                label={props.label.address}
                                value={ownerCompany.contact.address1}
                                onBlur={(value) => props.addOwnerCompanyValue('address1', value, true)}
                                required={props.values.insuranceCategory === 'pro'}
                                left={5}
                                disabled={props.disabled}
                                maxLength={100}
                            />
                        </Col>
                        <Col {...colXL12}>
                            <Text
                                form={props.form}
                                name="ownerCompanyAddress2"
                                label={props.label.address + '2'}
                                value={ownerCompany.contact.address2}
                                onBlur={(value) => props.addOwnerCompanyValue('address2', value, true)}
                                left={5}
                                disabled={props.disabled}
                                colon={false}
                                maxLength={100}
                            />
                        </Col>
                        <Col {...colXL20}>
                            <Text
                                form={props.form}
                                name="ownerCompanyAddressInfo"
                                label={props.label.addressInfo}
                                value={ownerCompany.contact.address3}
                                onBlur={(value) => props.addOwnerCompanyValue('address3', value, true)}
                                left={3}
                                disabled={props.disabled}
                                maxLength={100}
                            />
                        </Col>
                        <Col {...colXL6}>
                            <PostalCodeText
                                form={props.form}
                                name="ownerCompanyPostalCode"
                                label={props.label.postalCode}
                                value={ownerCompany.contact.postalCode}
                                updateCity={(value) => {
                                    props.addOwnerCompanyValue('city', value, true);
                                }}
                                updateCP={(value) => props.addOwnerCompanyValue('postalCode', value, true)}
                                required={props.values.insuranceCategory === 'pro'}
                                disabled={props.disabled}
                                left={10}
                            />
                        </Col>
                        <Col {...colXL6}>
                            <CityAutoComplete
                                form={props.form}
                                name="ownerCompanyCity"
                                label={props.label.city}
                                value={ownerCompany.contact.city}
                                updateCity={(value) => {
                                    props.addOwnerCompanyValue('city', value, true);
                                }}
                                updateCP={(value) => props.addOwnerCompanyValue('postalCode', value, true)}
                                required={props.values.insuranceCategory === 'pro'}
                                left={10}
                                disabled={props.disabled}
                            />
                        </Col>
                        <Col {...colXL6}>
                            <Text
                                form={props.form}
                                name="ownerCompanyCountry"
                                label={props.label.country}
                                value={props.values.country}
                                required={props.values.insuranceCategory === 'pro'}
                                left={10}
                                disabled={props.disabled}
                            />
                        </Col>
                    </Fragment>
                }
            </Row>
        </Fragment>
    );
};

export default generateController(PersonalInfo);
